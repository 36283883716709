import React, { useState } from 'react';
import { Dialog, Button, Grid, Typography, Autocomplete, TextField, InputAdornment, IconButton, Box, Popper } from '@mui/material';
import DownloadIcon from "../../../../../../assets/images/CandidateAssessment/downloadIcon.svg";
import RoleCloseIcon from "../../../../../../assets/images/successionPlan/roleClose.svg";
import FieldCloseIcon from "../../../../../../assets/images/CandidateAssessment/fieldCloseIcon.svg";
import { IoMdSearch } from "react-icons/io";


const AssigningInterviewersForModal = ({ showAssigningInterviewersForModal, handleAssigningInterviewersForModalNo }) => {

    const roleDataList = [
        { roleType: "Customer Obsession" },
        { roleType: "Ownership" },
        { roleType: "Invent And Simplify" },
        { roleType: "Invent And Simplify" },
        { roleType: "Hire and Develop the Best" },
        { roleType: "Ownership" },
    ];

    // Example options for the dropdown
    const interviewers = [
        { id: 1, label: 'Manisha Goal' },
        { id: 2, label: 'Isha' },
        { id: 3, label: 'Jagbir Singh' },
        { id: 4, label: 'Angie Mary J.' },
        { id: 5, label: 'Gaurav Malviya' },
        { id: 6, label: 'Devesh Sharma' },
        { id: 7, label: 'Monika Jain' },
    ];

    const CustomInterviewerPopper = (props) => {
        return (
            <Popper
                {...props}
                style={{
                    ...props.style,
                    width: '320px', // Dropdown ki width set karein
                }}
                placement="bottom-start" // Placement control karein (optional)
            />
        );
    };

    // Example options for the dropdown
    const assignCompetencies = [
        { id: 1, label: 'Customer Obsession' },
        { id: 2, label: 'Ownership' },
        { id: 3, label: 'Invent And Simplify' },
        { id: 4, label: 'Are  Right A Lot' },
        { id: 5, label: 'Learn and Be Curious' },
        { id: 6, label: 'Hire and Develop the Best' },
        { id: 7, label: 'Insist on the Highest Standards' },
    ];

    const CustomCompetenciesPopper = (props) => {
        return (
            <Popper
                {...props}
                style={{
                    ...props.style,
                    width: '320px', // Dropdown ki width set karein
                }}
                placement="bottom-start" // Placement control karein (optional)
            />
        );
    };

    return (
        <Dialog
            open={showAssigningInterviewersForModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                    width: "800px",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="assigning-interviewers-for-modal">
                {/*----- Top Modal Section -----*/}
                <Grid item xs={12} md={12} sm={12} className="top-section-card">
                    <Grid>
                        <Typography className="assigning-interviewers">{"Assigning Interviewers for"}</Typography>
                        <Typography className="interviewers-name">{"Govind H "}</Typography>
                    </Grid>

                    <Grid className="right-section">
                        <Typography className="select-interviewer">{"1 Interviewer(s) Selected"}</Typography>
                        <Typography className="vertical-border">{"|"}</Typography>
                        <Typography className="resume">{"Resume"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                        <Typography className="vertical-border">{"|"}</Typography>
                        <Typography className="resume">{"Job Description"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                    </Grid>
                </Grid>

                <Grid className="critical-roles-container">
                    <Grid className="mt-20">
                        <Grid className="dotIcon" />
                        <Box className="vertical-border" />
                    </Grid>
                    <Grid className="mt-20">
                        {/*----- Select Interviewer -----*/}
                        <Typography className="field-label">{"Select Interviewer"}</Typography>
                        <Autocomplete
                            options={interviewers}
                            getOptionLabel={(option) => option.label}
                            PopperComponent={CustomInterviewerPopper}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="selectInterviewer"
                                    placeholder="Select Interviewer"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: "#F4F4F4",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: '8px',
                                        "& fieldset": {
                                            borderRadius: '8px',
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            display: "none",
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: "0px" }}>
                                                    <IoMdSearch size={20} color="#979797" paddingLeft={10} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {/*----- Assign competencies -----*/}
                        <Typography className="field-label mt-20">{"Assign competencies"}</Typography>
                        <Autocomplete
                            options={assignCompetencies}
                            getOptionLabel={(option) => option.label}
                            PopperComponent={CustomCompetenciesPopper}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="selectAssignCompetencies"
                                    placeholder="Select Assign Competencies"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: "#F7FBFD",
                                        border: "1px solid #00425A",
                                        borderRadius: '8px',
                                        "& fieldset": {
                                            borderRadius: '8px',
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            display: "none",
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: "0px" }}>
                                                    <IoMdSearch size={20} color="#979797" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <div className='role-container'>
                            {roleDataList.map((item, index) => (
                                <div className='role-border-div'>
                                    <div className='d-flex ai-center'>
                                        <Typography className='role-type'>{item.roleType}</Typography>
                                        <img
                                            src={RoleCloseIcon}
                                            alt="RoleCloseIcon"
                                            height={12}
                                            width={12}
                                            className='role-close-Icon'
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/*----- Horizontal Border -----*/}
                        <Grid className="horizontal-border mt-20"></Grid>
                    </Grid>
                    <Grid className="field-close-grid">
                        <img
                            src={FieldCloseIcon}
                            alt="FieldCloseIcon"
                            height={35}
                            width={35}
                            className='field-close-icon'
                        />
                    </Grid>
                </Grid>

                <Grid className="critical-roles-container">
                    <Grid className="mt-20">
                        <Grid className="dotIcon" />
                        <Box className="vertical-border" />
                    </Grid>
                    <Grid className="mt-20">
                        {/*----- Select Interviewer -----*/}
                        <Typography className="field-label">{"Select Interviewer"}</Typography>
                        <Autocomplete
                            options={interviewers}
                            getOptionLabel={(option) => option.label}
                            PopperComponent={CustomInterviewerPopper}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="selectInterviewer"
                                    placeholder="Select Interviewer"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: '8px',
                                        "& fieldset": {
                                            borderRadius: '8px',
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            display: "none",
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: "0px" }}>
                                                    <IoMdSearch size={20} color="#979797" paddingLeft={10} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {/*----- Assign competencies -----*/}
                        <Typography className="field-label mt-20">{"Assign competencies"}</Typography>
                        <Autocomplete
                            options={assignCompetencies}
                            getOptionLabel={(option) => option.label}
                            PopperComponent={CustomCompetenciesPopper}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="selectAssignCompetencies"
                                    placeholder="Select Assign Competencies"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #00425A",
                                        borderRadius: '8px',
                                        "& fieldset": {
                                            borderRadius: '8px',
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            display: "none",
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: "0px" }}>
                                                    <IoMdSearch size={20} color="#979797" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {/* <div className='role-container'>
                            {roleDataList.map((item, index) => (
                                <div className='role-border-div'>
                                    <div className='d-flex ai-center'>
                                        <Typography className='role-type'>{item.roleType}</Typography>
                                        <img
                                            src={RoleCloseIcon}
                                            alt="RoleCloseIcon"
                                            height={12}
                                            width={12}
                                            className='role-close-Icon'
                                        />
                                    </div>
                                </div>
                            ))}
                        </div> */}

                        {/*----- Horizontal Border -----*/}
                        <Grid className="horizontal-border mt-20"></Grid>
                    </Grid>
                    <Grid className="field-close-grid">
                        <img
                            src={FieldCloseIcon}
                            alt="FieldCloseIcon"
                            height={35}
                            width={35}
                            className='field-close-icon'
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} sm={12} className="add-interviewers-grid">
                    <Button className="add-interviewers">{"+ Add Interviewers"}</Button>
                </Grid>

                {/*----- Action Button -----*/}
                <Grid item xs={12} md={12} sm={12} className="action-grid">
                    <Button className="cancel-btn" onClick={handleAssigningInterviewersForModalNo}>{"Cancel"}</Button>
                    <Button className="done-btn">{"Done"}</Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AssigningInterviewersForModal;