import { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import filterIcon from "../../../../../../assets/images/filter.svg";
import { Button, Checkbox, FormHelperText, InputBase, Stack, alpha, styled } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { isEmptyNullUndefined } from "../../../../../utils/utils";
import FilterSelect from "../../../../../common/filterSelect";
import { useSelector } from "react-redux";
import APIList from "../../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import FilterSelect2 from "../../../../../common/filterSelect2";
import TableSkel from "../../../../../common/tableSkeleton";
import Avatar from "react-avatar";
import PaginationMedi from "../../../../../common/pagination";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";
import FilterSelectForMyTeam from "../../../../../common/filterSelectForMyTeam";
import DynamicFilterSelect2 from "../../../../../common/dynamicFilterSelect2";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.4rem !important",
    width: '100%',
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 

const Cohort = ({handleChangeSessionInfo, sessionPayload, sessionPayloadErr}) => {
    
    // const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [cohortSearch, setCohortSearch] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [currentPage, setCurrentPage] = useState(null);
    
    const [searchedList, setsearchedList] = useState(null);
    const [totalSearchPages, setTotalSearchPages] = useState(0);
    const [searchLoader, setSearchLoader] = useState(false);
    const [isFilterOn, setIsFilterOn] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownLoader, setDropdownLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("All");
    
    const [removingItem, setremovingItem] = useState(null);
    // console.log(searchedList)
    const getData = (filters, search, page, action) => {
        setSearchLoader(true);
        // if(search?.length > 2) {
            APIList.getFilteredSearchPeers({
                payload: {
                    employeeEligibilityDTO: filters,
                    keyword: search,
                    employeeId: employeeDetails?.id,
                    // managerId: employeeDetails?.manager?.id, // 07-11-24 comment because, need own manager in search 
                    companyId: employeeDetails?.company?.id,
                    page: "session"
                },
                page: page,
                size: 3000
            })

        
                .then((res) => {
                    setsearchedList(res?.data?.data);
                    setTotalSearchPages(res?.data?.totalPages);
                    setSearchLoader(false);
                    (action === "filter") && setShowFilters(false); 
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                          &nbsp;&nbsp;{err?.title} 
                        </div>
                    );
                    setSearchLoader(false);
                }
                )
            // }
    }

    const getDropdownData = () => {
        // console.log("getPageData");
        setDropdownLoader(true);
        APIList.getDropdownsfForCohort({
            range: "My Org",                                                                // My Team Or My Org
            companyId: employeeDetails?.company?.id,
        })
        .then((res) => {
            setDropdownData(res?.data);
            setDropdownLoader(false);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setDropdownLoader(false);
        })
    }

    useEffect(() => {
        getDropdownData();
    }, [])
    

    useEffect(() => {
        setActiveTab("All");

        if((cohortSearch !== null) && (cohortSearch?.length > 2)) {
           setSearchLoader(true);
        }

        const getSearch = setTimeout(() => {
            if(currentPage !== 0 && currentPage !== null) {
                setCurrentPage(0);
            } else {
                if((cohortSearch !== null) && (cohortSearch?.length > 2)) {
                    getData(filterPayload, cohortSearch, 0, "search");
                } else {
                    setSearchLoader(false);
                }                 
            }
            
            // getData(filterPayload, cohortSearch, 0, "search");
        }, 500);    

        return () => clearTimeout(getSearch)
      }, [cohortSearch])

    useEffect(() => {
        if(currentPage !== null) {
            getData(filterPayload, cohortSearch, currentPage, "search");
        }
      }, [currentPage])

    const autoSearchTextCohort = (e) => {
        setCohortSearch(e.target.value);
      }

      const handleSubmitFilter = () => {        
        setActiveTab("All");
        if(Object.keys(filterPayload).length === 0){
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;Please select some filters 
                </div>
            );
        } else {
            setIsFilterOn(true);
            if(currentPage !== 0) {
                setCurrentPage(0);
            } else {
                getData(filterPayload, cohortSearch, 0, "search");
            }
                // getData(filterPayload, cohortSearch, 0, "filter");             
        }      
    }  

    const handleResetFilters = () => {
        setActiveTab("All");
        setFilterPayload({});
        setIsFilterOn(false);
        if(currentPage !== 0) {
            setCurrentPage(0);
        } else {
            getData({}, cohortSearch, 0, "search");
        }
        // getData({}, cohortSearch, 0, "filter")
    }

    const removeCohort = (emp) => {
        setremovingItem(emp?.id); 
        setTimeout(() => {
            setremovingItem(null);
            handleChangeSessionInfo(emp, "removeCohort");
        }, 250);  
    }

    const isAllAdded = () => {
        const allIds = searchedList?.map( x => x.employeeId);
        const AllSelectedIds = sessionPayload?.coHortEmployees?.map( x => x.id);

        const allEntriesPresent = allIds.every(item => AllSelectedIds.includes(item));

        return allEntriesPresent;
    }

    const handleSelectAll = () => {
        if(isAllAdded()) {
            handleChangeSessionInfo(searchedList, "removeAllCohort");
        } else {
            handleChangeSessionInfo(searchedList, "addAllCohort")
        }
        
    }
    
    const handleRemoveAll = () => {
            handleChangeSessionInfo(sessionPayload?.coHortEmployees, "removeAllSelectedCohort");
    }
    
    return (
        <div className="emp-cohort">
            <div className="search-wrapper">
                <Search>
                    <SearchIconWrapper>
                        <IoMdSearch />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search by username or email"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={autoSearchTextCohort}
                        value={cohortSearch}
                    />
                    {
                        !isEmptyNullUndefined(cohortSearch) &&
                        <IoCloseSharp onClick={() => setCohortSearch("")} style={{ position: "absolute", right: "1rem", top: "34%", color: "gray", cursor: "pointer" }} />
                    }
                </Search>
                {
                    cohortSearch?.length > 0 && cohortSearch?.length < 3 && 
                    <div className="helper">Please insert at least 3 characters</div>
                }
                <div className={`filter-tab ${showFilters ? "active" : ""}`} onClick={() => setShowFilters(!showFilters)}>
                    <img src={filterIcon} alt="" />
                    Filter
                    <BsChevronDown
                        style={{ transform: !showFilters ? "rotate(0deg)" : "rotate(180deg)" }}
                    />
                </div>
                {/* <div className="page-i-btn"
                // onClick={() => setIsOpenDrawerForIbtn(true)}
                >
                    i
                </div> */}
            </div>

            {sessionPayloadErr?.coHortEmployees?.isError && (
            <FormHelperText sx={{ color: "#d32f2f" }}>
            {sessionPayloadErr?.coHortEmployees?.errMsg}
            </FormHelperText>
            )}

            {
                showFilters && 
                <div className="filters-block">
                    <div className="filters">
                        {
                            !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                return (
                                    // <FilterSelect2 title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                    <DynamicFilterSelect2 title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} dropdownData={dropdownData} />
                                )
                            })
                        }
                        </div>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={(Object.keys(filterPayload).length === 0) || searchLoader}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={searchLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                        
                </div>
            }

                <div className="tabs-outer">
                    <div className="tabs">
                        <div className={`tab ${activeTab === "All" ? "active" : ""}`} onClick={() => setActiveTab("All")}>All</div>
                        <div className={`tab ${activeTab === "Selected" ? "active" : ""}`} onClick={() => setActiveTab("Selected")}>Selected <span className="selected-number">{sessionPayload?.coHortEmployees?.length}</span></div>
                    </div>
                </div>

{
                !isEmptyNullUndefined(sessionPayload?.coHortEmployees) && (activeTab === "Selected") ?
                 
                <div className="tablee">
                    <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list-send"> 
                <th className="emp-name" onClick={handleRemoveAll}> 
                
                    <MdOutlineCheckBox />
                    Employee Name
                </th> 
                <th className="last-col">Email</th> 
                </tr> 

                {
                sessionPayload?.coHortEmployees?.map((emp,index) => {
                        return(                               
                                <tr className={`${emp?.id === removingItem ? "selected" : ""}`}> 
                                <td>
                                    <div className="empDetaila" onClick={() => removeCohort(emp)}>
                                    <MdOutlineCheckBox />
                                        <div className="empPic">
                                        {emp?.profilePhotoPath ? 
                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                            :
                                            <Avatar 
                                                name={emp?.name} 
                                                size="45" 
                                                className="userImg"
                                                color={"#00425A"}    
                                            />
                                        }
                                        </div>
                                        <div className="userInfo">
                                            <p className="name">{emp?.name}</p>
                                            <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                        </div>
                                    </div>
                                </td> 

                                <td>
                                <div className= "btns">
                                {emp?.emailId}
                                </div>
                                </td> 
                               
                            </tr>        
                                    
                            
                        )
                    }
                )
                }

                </table>
                </div>
                :
                (activeTab === "Selected") &&
                <div className="empty-selected">No employees to show</div>
            }

            {
                ((!isEmptyNullUndefined(cohortSearch) || ((Object.keys(filterPayload)?.length > 0)) && isFilterOn) && (activeTab === "All") &&
                !isEmptyNullUndefined(searchedList)) ?

                <div className="tablee">
                    <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list-send"> 
                <th className="emp-name" onClick={handleSelectAll}> 
                {
                    isAllAdded() ?
                    <MdOutlineCheckBox />
                    :
                    <MdOutlineCheckBoxOutlineBlank />
                }
                    Employee Name
                </th> 
                <th className="mid-col">Manager</th> 
                <th className="mid-col-le">Level</th> 
                <th className="last-col">Email</th> 
                </tr> 

                {
                     searchLoader ? 
                     <TableSkel />
                     :
                searchedList?.map((emp,index) => {
                        return(                               
                                <tr> 
                                <td>
                                    <div className="empDetaila" onClick={() => handleChangeSessionInfo(emp, "addCohort")}>
                                        {
                                            sessionPayload?.coHortEmployees?.filter((x) => x?.id === emp?.employeeId).length > 0 ?
                                            <MdOutlineCheckBox />
                                            :
                                            <MdOutlineCheckBoxOutlineBlank />
                                        }
                                        <div className="empPic">
                                        {emp?.profilePhotoPath ? 
                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                            :
                                            <Avatar 
                                                name={emp?.employeeName} 
                                                size="45" 
                                                className="userImg"
                                                color={"#00425A"}    
                                            />
                                        }
                                        </div>
                                        <div className="userInfo">
                                            <p className="name">{emp?.employeeName}</p>
                                            <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                        </div>
                                    </div>
                                </td> 

                                <td>
                                    {
                                        !isEmptyNullUndefined(emp?.manager) ? 
                                        <div className="empDetaila">
                                        <div className="empPic">
                                        {emp?.manager?.profilePhotoPath ? 
                                            <img src={emp?.manager?.profilePhotoPath} className="userImg" alt="User Image" />
                                            :
                                            <Avatar 
                                                name={emp?.manager?.employeeName} 
                                                size="45" 
                                                className="userImg"
                                                color={"#00425A"}    
                                            />
                                        }
                                        </div>
                                        <div className="userInfo">
                                            <p className="name">{emp?.manager?.employeeName}</p>
                                            <p className="designation">{emp?.manager?.employeeDesignation ? emp?.manager?.employeeDesignation : "Designation"}</p>
                                        </div>
                                    </div>
                                    :
                                    "NA"
                                    }
                                    
                                </td> 

                                <td className="levels">
                                    {emp?.employeeLevel}
                                </td>

                                <td>
                                <div className= "btns">
                                {emp?.emailId}
                                </div>
                                </td> 
                               
                            </tr>        
                                    
                            
                        )
                    }
                )
                }

                </table>
                {
                    !isEmptyNullUndefined(searchedList) && (totalSearchPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalSearchPages} onPageChange={setCurrentPage} />
                }
                </div>
                :
                
                    ((!isEmptyNullUndefined(cohortSearch) || ((Object.keys(filterPayload)?.length > 0))) && isFilterOn && isEmptyNullUndefined(searchedList)) && 
                    <div className="no-result">
                        {
                        searchLoader ? 
                        <div className="tablee">
                            <table class="feedback-emp-list-table"> 
                                <TableSkel />
                            </table>
                        </div>
                        :
                        "No result found"
                        }
                    </div>
                
                
            }

        </div>
    )
}

export default Cohort;