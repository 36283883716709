import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import MyInterviewsTable from './components/MyInterviewsTable';
import ProcessBg from "../../../../assets/images/CandidateAssessment/processBg.svg";
import CountBg from "../../../../assets/images/CandidateAssessment/countBg.svg";
import { HiOutlineChevronDown } from "react-icons/hi2";


const HRMyInterviews = () => {

  return (
    <div className="hr-my-interviews">
      <Grid container>
        {/*----- Heading Title -----*/}
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Candidate Assessment"}</Typography>
        </Grid>

        {/*----- Candidate Assessment Table -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <MyInterviewsTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default HRMyInterviews;
