import React, { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FaCamera } from "react-icons/fa";
import CircleImg from "../../../../assets/images/MyCommunity/nominated1.svg";
import UpArrow from "../../../../assets/images/MyCommunity/upArrow.svg";
import RoleCloseIcon from "../../../../assets/images/successionPlan/roleClose.svg";
import GreetingCard from './components/GreetingCard';

const KudosForModal = ({ openKudosForModal, handleKudosForModalClose }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 480px)");

    const [anniversaryView, setAnniversaryView] = useState(true);
    const [birthdayView, setBirthdayView] = useState(false);
    const [babyBirthView, setBabyBirthView] = useState(false);
    const [appreciationView, setAppreciationView] = useState(false);

    const handleAnniversary = () => {
        setAnniversaryView(true);
        setBirthdayView(false);
        setBabyBirthView(false);
        setAppreciationView(false);
    };

    const handleBirthday = () => {
        setAnniversaryView(false);
        setBirthdayView(true);
        setBabyBirthView(false);
        setAppreciationView(false);
    };

    const handleBabyBirth = () => {
        setAnniversaryView(false);
        setBirthdayView(false);
        setBabyBirthView(true);
        setAppreciationView(false);
    };

    const handleAppreciation = () => {
        setAnniversaryView(false);
        setBirthdayView(false);
        setBabyBirthView(false);
        setAppreciationView(true);
    };

    const [search, setSearch] = useState("");
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
    };

    // State to store uploaded files
    const [files, setFiles] = useState([]);
    const [status, setStatus] = useState("INACTIVE"); // Example status
    const [openGreetingCard, setOpenGreetingCard] = useState(false);
    // Handle file selection
    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const newFiles = [...files, selectedFile]; // Add new file to state
            setFiles(newFiles); // Update state with new files
        }
    };

    // Open document handler (optional)
    const openDoc = (index) => {
        // Function to open the document (e.g., in a new tab or modal)
        const file = files[index];
        // Logic to open the file...
    };

    // Delete document handler
    const handleDeleteDoc = (index) => {
        const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
        setFiles(updatedFiles); // Remove the file from state
    };

    const handleOpenGreetingCard = () => {
        if (!openGreetingCard) {
            setOpenGreetingCard(true);
        } else {
            setOpenGreetingCard(false);
        }

    }

    return (
        <div>
            <Dialog
                open={openKudosForModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F9F9F9",
                        borderRadius: "20px",
                        // width: "52%",
                        width: isMobile ? "100%" : "52%",
                    },
                }}
            >
                <DialogContent>
                    <Grid container spacing={4} className="kudos-for-modal">
                        <Grid item xs={12} md={12} sm={12}>
                            {/*----- Heading -----*/}
                            <Typography className="model-title">{"Kudos For"}</Typography>

                            {/*----- Top Image Card Section -----*/}
                            <div className="image-section">
                                <div className="main-b">
                                    <div className="org-update-row">
                                        <img src={CircleImg} className="loginBg"></img>
                                        <div className="update-info">
                                            <Typography className="head">{"Positive Pat-on-the back"}</Typography>
                                            <Typography className="description">{"Awards Definition"}{" "}<img src={UpArrow} className="up-arrow"></img></Typography>
                                            <Typography className="description">
                                                In publishing and graphic design, Lorem ipsum is a
                                                placeholder text commonly used to demonstrate the visual
                                                form of a document. In publishing and graphic design,
                                                Lorem ipsum is a placeholder text.
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*----- Search Section -----*/}
                            <div className="searchSection">
                                <form onSubmit={handleSearchSubmit} className="search-bar">
                                    <input
                                        type="text"
                                        // placeholder="Search by username or email"
                                        value={search}
                                        onChange={handleSearchChange}
                                        aria-label="Search"
                                        className="search-input"
                                    />
                                    <button type="submit" className="search-btn">
                                        <IoMdSearch size={20} />{" "}
                                    </button>
                                </form>
                                <div className="role-container">
                                    <div className="role-border-div">
                                        <div className="d-flex ai-center">
                                            <Typography className="role-type">
                                                Savannah Nguyen
                                            </Typography>
                                            <img
                                                src={RoleCloseIcon}
                                                alt="RoleCloseIcon"
                                                height={12}
                                                width={12}
                                                className="role-close-Icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="greeting-card">
                                {/*----- Attach Greeting Card -----*/}
                                <div className="greeting-heading">
                                    <Typography className="attchmentLine" onClick={handleOpenGreetingCard} sx={{ cursor: "pointer" }}>{"Attach Greeting Card"}</Typography>
                                </div>

                                {openGreetingCard &&
                                    <Grid>
                                        {/*----- Anniversary & Birthday & Baby Birth & Appreciation Tabbar -----*/}
                                        <Grid class="greeting-tab-btn-List">
                                            <a onClick={handleAnniversary} class={anniversaryView === true ? "active-btn" : "inActive-btn"}>{"Anniversary"}</a>
                                            <a onClick={handleBirthday} class={birthdayView === true ? "active-btn" : "inActive-btn"}>{"Birthday"}</a>
                                            <a onClick={handleBabyBirth} class={babyBirthView === true ? "active-btn" : "inActive-btn"}>{"Baby Birth"}</a>
                                            <a onClick={handleAppreciation} class={appreciationView === true ? "active-btn" : "inActive-btn"}>{"Appreciation"}</a>
                                        </Grid>

                                        {anniversaryView === true &&
                                            <GreetingCard />
                                        }

                                        {birthdayView === true &&
                                            <GreetingCard />
                                        }

                                        {babyBirthView === true &&
                                            <GreetingCard />
                                        }

                                        {appreciationView === true &&
                                            <GreetingCard />
                                        }
                                    </Grid>
                                }
                            </div>

                            {/*----- Reason for nomination -----*/}
                            {/* <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">
                                    Reason for nomination
                                </label>
                                <div className="whiteBoxbluetext">
                                    I would like him to Thing Big and Strategically as a VP
                                    (showcase this in areas outside of the standard input focus)
                                </div>
                            </div> */}

                            {/*----- Reason for Rejection -----*/}
                            {/* <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">
                                    Reason for Rejection
                                </label>
                                <div className="whiteBoxbluetext">
                                    I would like him to Thing Big and Strategically as a VP
                                    (showcase this in areas outside of the standard input focus)
                                </div>
                            </div> */}

                            {/*----- Your Message -----*/}
                            <div className="textAreaSection">
                                <label htmlFor="outlined-multiline-static">{"Your Message "}</label>
                                <TextField
                                    name="demonstrated"
                                    id="outlined-multiline-static"
                                    variant="outlined"
                                    placeholder="Please provide Overall feedback"
                                    multiline
                                    rows={3}
                                    value="I would like him to Thing Big and Strategically as a VP (showcase this in areas outside of the standard input focus)"
                                    style={{ width: "100%" }}
                                    InputProps={{
                                        style: {
                                            color: "#7E7E7E",
                                            fontSize: "16px",
                                            fontWeight: "lighter",
                                            border: "1px solid #D9D9D9",
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: "8px",
                                        },
                                    }}
                                />
                            </div>

                            {/*----- Cancel & Done Button -----*/}
                            <div className="botttombtn">
                                <button
                                    className="cancel-btn"
                                    onClick={handleKudosForModalClose}
                                    color="transparent"
                                    variant="outlined"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="update-btn"
                                    // onClick={() => {}}
                                    type="submit"
                                    variant="outlined"
                                >
                                    Done
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default KudosForModal;
