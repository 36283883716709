import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, MenuItem, Select, Stack, CircularProgress, Skeleton } from "@mui/material";
import { BsChevronDown, BsFillCheckCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { accordianSVGstyle, getFormattedDate, isEmptyNullUndefined } from "../../../../utils/utils";
import GoalSummaryIcon from "../../../../../assets/images/goalView/goalSummary.svg";
import MilestoneSummaryIcon from "../../../../../assets/images/goalView/milestoneSummary.svg";
import APIList from "../../../../../api";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import AccDetails from "../accDetail";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import AccSkeleton from "../../../../common/akkSkeleton";
import Comments from "../../../../common/comments";

const EmployeeGoalView = () => {

    const history = useHistory();
    const location = useLocation();
    const {id} = useParams()

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
    const goalcycleData = useSelector((state) => state?.goalCycleReducer?.goalCycle);
    const activeGoalCycle = useSelector((state) => state?.goalCycleReducer?.activeGoalCycle);

    const [selectedYearDDReport, setSelectedYearDDReport] = useState('Q1 2024');
    const [openExpandedCard, setOpenExpandedCard] = useState("");
    const [checked, setChecked] = useState([]);
    const [formData, setFormData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [goalSubmitLoader, setGoalSubmitLoader] = useState(false);

    const [activeGoalListTab, setActiveGoalListTab] = useState("APPROVED");
    
    const [selectedGoalCycle, setSelectedGoalCycle] = useState(goalcycleData[0] || {});

    const [countsLoader, setCountsLoader] = useState(false);
    const [counts, setCounts] = useState({});

    const [commentLoader, setCommentLoader] = useState(false);
    const [allComments, setAllComments] = useState([]);
    const [addCommentLoader, setAddCommentLoader] = useState(false);
    const [comment, setComment] = useState("");
    const [getCommentPayload, setGetCommentPayload] = useState({
            employeeIds : [],
            fromDate : null,
            toDate : null,
            sortByDate : "asc"
    });

    const [showList, setShowList] = useState("All");

    const [acknowledgeLoader, setAcknowledgeLoader] = useState(false);

    const handleYearDDChange = (event) => {
        setSelectedGoalCycle(event.target.value);
    };

    useEffect(() => {
      if(location?.state?.action === "submit"){
        setActiveGoalListTab("SUBMIT")
      }
      if(location?.state?.action === "draft"){
        setActiveGoalListTab("DRAFT")
      }
    }, [])

    useEffect(() => {
        if(!isEmptyNullUndefined(activeGoalCycle?.id)){
            let temp = structuredClone(goalcycleData);
            let active = temp?.filter(x => x?.id === activeGoalCycle?.id)[0];
            setSelectedGoalCycle(active);
        }
    }, [activeGoalCycle])
    

    useEffect(() => {  
        
        if(!isEmptyNullUndefined(openExpandedCard)){
            getAllComments();
        }  
        
    }, [openExpandedCard, getCommentPayload])
    

    useEffect(() => {
        if(!isEmptyNullUndefined(employeeDetails?.id) && !isEmptyNullUndefined(selectedGoalCycle?.id)){
            getCount();
        }      
    }, [employeeDetails, selectedGoalCycle, activeGoalListTab])    

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isEmptyNullUndefined(employeeDetails?.company?.id)) {
                    await getFormData();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData(); // Call the async function
        
    }, [employeeDetails, activeGoalListTab, selectedGoalCycle]);

    const getCount = () => {
        
        setCountsLoader(true);
        APIList.myGoalsCounts({
                companyId: employeeDetails?.company?.id,
                employeeId: employeeDetails?.id, 
                goalCycleId: selectedGoalCycle?.id
        })
        .then((res) => {
                setCounts(res?.data);
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setCountsLoader(false);
        })
    
    }

    const getAllComments = () => {

        let temp = structuredClone(allComments);
        let presentGals = temp?.length > 0 ? temp?.map(x => x?.id) : [];
        
        // if(!presentGals?.includes(openExpandedCard)) {
            setCommentLoader(true);
            APIList.goalComments({
                payload: getCommentPayload,
                id: openExpandedCard
              }
            )
            .then((res) => {
    
                if(temp?.some(x => x?.id === openExpandedCard)){
                    temp = temp?.map(x => x?.id === openExpandedCard ? {...x, comments: res?.data[0]?.entityList} : x);
                } else {
                    temp.push({
                        id: openExpandedCard,
                        comments: res?.data[0]?.entityList
                    })
                }
    
                setAllComments(temp);
    
            }) 
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.message};
                    </div>
                )
            })
            .finally(() => {
                setCommentLoader(false);
            })
        // }
        
    }

    const getAllCommentsAfterSave = () => {
        setCommentLoader(true);
        APIList.goalComments(
              {
                payload: getCommentPayload,
                id: openExpandedCard
              }
        )
        .then((res) => {

            let temp = structuredClone(allComments);

            let currentGoal = temp?.filter(x => x?.id === openExpandedCard)[0];
            currentGoal["comments"] = res?.data[0]?.entityList;

            setAllComments(temp);

        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setCommentLoader(false);
        })
    }

    const addComment = (comment) => {
        setAddCommentLoader(true);
        APIList.addCommentOnGoal({
            goalEmployeeId: openExpandedCard,
            comment:comment,
            commentByEmployeeId: employeeDetails?.id
        })
        .then((res) => {
            getAllCommentsAfterSave();
            setComment("");
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setAddCommentLoader(false);
        })
    }

    const submitGoals = () => {
        setGoalSubmitLoader(true);
        APIList.submitGoals({
            companyId : employeeDetails?.company?.id,
            payload : {id: checked}
        })
        .then((res) => {
            getFormData();
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setGoalSubmitLoader(false);
        })
    }

    const getFormData = async () => {
        setIsLoading(true);
        let payload = {

            companyId: employeeDetails.company?.id,
            // formType:"BASE",
            // isContainer:false,
            employeeId: employeeDetails?.id,    
            // id: "ade40cf4-c9c8-4c7d-9c92-28035a43f160",
            status: activeGoalListTab,
            goalCycleId: selectedGoalCycle?.id

        }
        
        try {
            // /api/goal-employee/all/{companyId}/{employeeId} GET  --- get all list
          const response = await APIList.goalEmployee_all_companyId_employeeId(payload);
          if (response.status === 200) {
            setFormData(response?.data);
          } else {
          }
        } catch (error) {
          
        }
        setIsLoading(false);
    };

    const handleOpenExpandedCard = (e, id) => {

        if (!e.target.closest('.MuiCheckbox-root')) { // Check if the click is not on the checkbox
            if(openExpandedCard === id) {
                setOpenExpandedCard("");
            } else {
                setOpenExpandedCard(id);
                   // Move the expanded card to the first index
                const updatedFormData = structuredClone(formData);
                const cardIndex = updatedFormData.findIndex(card => card.id === id);
                if (cardIndex > -1) {
                    const [expandedCard] = updatedFormData.splice(cardIndex, 1); // Remove the card from its current position
                    updatedFormData.unshift(expandedCard); // Add it to the start of the array
                    setFormData(updatedFormData); // Update the state with the new order
                }
            }
        }        
        
    }

    const handleCheckboxChange = (e, card) => {
        e.stopPropagation();
        
        let temp = structuredClone(checked);

        if(temp?.includes(card?.id)){
            temp = temp?.filter(x => x !== card?.id)
        } else {
            temp.push(card?.id);
        }        

        setChecked(temp);
    };

    const handleTabClick = (tab) => {
        if (!isLoading) {
            setActiveGoalListTab(tab);
            setOpenExpandedCard("");
        }
    };

    const goalYearList = [
        { id: '1', goalYearValue: 'Q1 2024' },
        { id: '2', goalYearValue: 'Q2 2024' },
        { id: '3', goalYearValue: 'Q3 2024' }
    ];

    const getNoDataMsg = () => {
        if(activeGoalListTab === "APPROVED"){
            return <div className="not-found">You have no Approved Goals.</div>
        }
        if(activeGoalListTab === "SUBMIT"){
            return <div className="not-found">You have no Goals under Review.</div>
        }
        if(activeGoalListTab === "DRAFT"){
            return <div className="not-found">You have no Goals Under Draft.</div>
        }
        if(activeGoalListTab === "REJECT"){
            return <div className="not-found">You have no Rejected Goals.</div>
        }
    }

    const handleAcknowledge = (card) => {
        setAcknowledgeLoader(true);
        APIList.goalAcknowledge({
            goalId: card?.id
        })
        .then((res) => {
            toast.success(
                <div className="flex flex-row">
                  <BsFillCheckCircleFill
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  &nbsp;&nbsp;Password
                  {`Acknowledged`}
                </div>
              );
            getAllCommentsAfterSave();
            getFormData();
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.message};
                </div>
            )
        })
        .finally(() => {
            setAcknowledgeLoader(false);
        })
    }

    return (
        <div className="emp-goals-page">
            {/*----- Year Drop Down -----*/}
            <div className="year-dropDown-div">
                <FormControl className="year-dropDown">
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedGoalCycle}
                        label="Q2 2024"
                        onChange={handleYearDDChange}
                        renderValue={(selected) => (
                            <span>{selected?.name}</span>
                        )}
                    >
                        {!isEmptyNullUndefined(goalcycleData) && goalcycleData?.map((goalYear) => {
                                return (
                                    <MenuItem value={goalYear}>{goalYear?.name}</MenuItem>
                                )
                            })}
                    </Select>
                </FormControl>
            </div>

            {/*----- Chart layout -----*/}
            <div className="outer-chart-layout">
                {/*----- Goal Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Goal Summary</p>

                    <div className="chart-column">
                        <img
                            src={GoalSummaryIcon}
                            alt="GoalSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">30 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">1 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*----- Vertical Border -----*/}
                <div className="verticalBorder"></div>

                {/*----- Milestone Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Milestone Summary</p>

                    <div className="chart-column">
                        <img
                            src={MilestoneSummaryIcon}
                            alt="MilestoneSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">8 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">6 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="goals-tab-btn-div">
                {/*----- Approved goals & Goals under review & Goals Under Draft Tab -----*/}
                <div className="goals-tab">
                    <div>
                        <div className="tab-d-flex">
                            <div className={`tab ${activeGoalListTab === "APPROVED" && "active"}`} onClick={() => handleTabClick("APPROVED")}>Approved Goals {countsLoader ? <CircularProgress size={19} /> : <span>{counts?.approvedCount < 10 ? `0${counts?.approvedCount}` : counts?.approvedCount}</span>}</div>
                            <div className={`tab ${activeGoalListTab === "SUBMIT" && "active"}`} onClick={() => handleTabClick("SUBMIT")}>Goals Under Review {countsLoader ? <CircularProgress size={19} /> : <span>{counts?.underReviewCount < 10 ? `0${counts?.underReviewCount}` : counts?.underReviewCount}</span>}</div>
                            <div className={`tab ${activeGoalListTab === "DRAFT" && "active"}`} onClick={() => handleTabClick("DRAFT")}>Goals Under Draft {countsLoader ? <CircularProgress size={19} /> : <span>{counts?.draftCount < 10 ? `0${counts?.draftCount}` : counts?.draftCount}</span>}</div>
                            <div className={`tab ${activeGoalListTab === "REJECT" && "active"}`} onClick={() => handleTabClick("REJECT")}>Goals Rejected {countsLoader ? <CircularProgress size={19} /> : <span>{counts?.rejectCount < 10 ? `0${counts?.rejectCount}` : counts?.rejectCount}</span>}</div>
                        </div>

                        {/*----- All and Selected Count Btn -----*/}
                        {/* {
                            activeGoalListTab === "DRAFT" && 
                            <div className="all-selected-count-border">
                                <Button className={`all-select-btn ${showList === "All" && "active-btn"}`} onClick={() => setShowList("All")}>
                                    All
                                </Button>
                                <Button className={`all-select-btn ${showList === "Selected" && "active-btn"}`} onClick={() => setShowList("Selected")}>
                                    Selected ({checked?.length})
                                </Button>
                            </div>
                        } */}
                    </div>
                    {/* <div className="qu-icon-div">
                        <img
                            src={QuIcon}
                            alt="QuIcon"
                            height={17}
                            width={17}
                        />
                    </div> */}
                </div>

                {
                activeGoalListTab === "APPROVED" || activeGoalListTab === "DRAFT" ?
                    <div className="submit-Goals-div">
                        <Button variant="outlined" className="submit-Goals-btn" onClick={() => history.push(`employee-goals-form/true`)} disabled={goalSubmitLoader}>
                            {
                                goalSubmitLoader ?
                                 <CircularProgress size={20} style={{color:"#ffffff"}} />
                                 :
                                 "Add A Goal"
                            }
                        </Button>
                    </div>
                    :
                    <></>
                }

                {/* {
                activeGoalListTab === "DRAFT" && 
                    <div className="submit-Goals-div">
                        <Button variant="outlined" className="submit-Goals-btn" onClick={() => submitGoals()} disabled={goalSubmitLoader}>
                            {
                                goalSubmitLoader ?
                                 <CircularProgress size={20} style={{color:"#ffffff"}} />
                                 :
                                 "Submit Goals"
                            }
                        </Button>
                    </div>
                } */}
                
            </div>

            {/*----- Edit & Comment Layout -----*/}
            <div className="outer-edit-comments-layout">
                {/*----- Edit Layout -----*/}
                <div className="edit-card-layout">
        
                    {/*----- 3. Capex / POS / RnR -----*/}
                    {
                        isLoading ? 
                            <AccSkeleton /> 
                            :
                            <Stack sx={{
                                "& .MuiAccordion-rounded": {
                                    borderRadius: "10px !important",
                                    boxShadow: "none",
                                    backgroundColor: "#FFFFFF",
                                    marginTop: "16px",
                                }
                            }}>
                                {/* Form List */}
                                {!isEmptyNullUndefined(formData) && (formData?.length > 0) && (
                                    
                                    formData?.map((card,i) => {
                                        if(activeGoalListTab === "DRAFT" && showList === "Selected" && !checked?.includes(card?.id)){
                                           return null;
                                        }
                                        return (
                                            <Accordion expanded={openExpandedCard === card?.id} onChange={(e) => handleOpenExpandedCard(e, card?.id)} data-value={"stats"}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                                    sx={{
                                                        width: "100%",
                                                        fontSize: "1.25rem",
                                                        fontWeight: "600",
                                                        "& .MuiAccordionSummary-content": {
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }
                                                    }}
                                                >
                                                    <div>
                                                        <div className="d-flex">
                                                            {/* <Checkbox
                                                                checked={checked?.includes(card?.id)}
                                                                onChange={(e) => handleCheckboxChange(e, card)}
                                                                color="primary"
                                                                sx={{
                                                                    '&.MuiButtonBase-root': {
                                                                        padding: 0,
                                                                    },
                                                                }}
                                                            /> */}
                                                            <p className="accordian-heading">{card?.title}</p>
                                                            <p className="slider-status status-green-color">On Track</p>
                                                            <div className="slider-div">
                                                                <p className="accordian-slider-persent">50%</p>
                                                                <div className="slider" />
                                                            </div>
                                                        </div>
        
                                                        
                                                        <p className="accordian-end-date">End date : {!isEmptyNullUndefined(card?.endDate) ? getFormattedDate(card?.endDate, dateFormat) : ""}</p>
                                                    </div>                                                    
        
                                                    
                                                         <div className="d-flex">

                                                                <>
                                                                   {
                                                                    (activeGoalListTab === "APPROVED" || activeGoalListTab === "SUBMIT")  && card?.isAcknowledge &&
                                                                    <button onClick={(e) => {
                                                                            handleAcknowledge(card);
                                                                        }} variant="contained" className="replica-btn"
                                                                        disabled={acknowledgeLoader}
                                                                        >
                                                                            {
                                                                                acknowledgeLoader ? <CircularProgress size={20} style={{color:"#ffffff"}} /> : "Acknowledge"
                                                                            }
                                                                            </button>
                                                                        }
                                                                   {
                                                                    activeGoalListTab === "APPROVED"  &&
                                                                    <button onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            history.push({pathname: `/employee-goals-form/true`, state:{id: card?.id}});
                                                                        }} variant="contained" className="replica-btn"
                                                                        >
                                                                            Replicate
                                                                            </button>
                                                                        }
                                                                        {
                                                                        activeGoalListTab !== "SUBMIT" && selectedGoalCycle?.id === activeGoalCycle?.id &&
                                                                        <button onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                history.push({pathname: `/employee-goals-form/false`, state: {isManagerEdit: false, managerId: null, id:card?.id}});
                                                                            }} variant="contained" className="edit-btn"
                                                                            >
                                                                            Edit
                                                                        </button>
                                                                        }
                                                                </>   
            
                                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: openExpandedCard === card?.id ? "rotate(180deg)" : "rotate(0deg)" }} />
                                                        </div>                                    

                                                </AccordionSummary>
        
                                                
        
                                                <AccordionDetails sx={{ fontSize: "1rem" }}>
                                                    <div>
                                                        <AccDetails formId={card?.id} />
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                ) }
                                {/* <Accordion expanded={openExpandedCard} onChange={() => handleOpenExpandedCard()} data-value={"stats"}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                        sx={{
                                            width: "100%",
                                            fontSize: "1.25rem",
                                            fontWeight: "600",
                                            "& .MuiAccordionSummary-content": {
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }
                                        }}
                                    >
                                        <div>
                                            <div className="d-flex">
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={handleCheckboxChange}
                                                    color="primary"
                                                    sx={{
                                                        '&.MuiButtonBase-root': {
                                                            padding: 0,
                                                        },
                                                    }}
                                                />
                                                <p className="accordian-heading">3. Capex / POS / RnR</p>
                                                <p className="slider-status status-green-color">On Track</p>
                                                <div className="slider-div">
                                                    <p className="accordian-slider-persent">50%</p>
                                                    <div className="slider" />
                                                </div>
                                            </div>
        
                                            
                                            <p className="accordian-end-date">End date : 30 September 2024</p>
                                        </div>
        
                                        
        
                                        <div className="d-flex">
                                            <Button variant="contained" className="edit-btn">
                                                Edit
                                            </Button>
        
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>
                                    </AccordionSummary>
        
                                    
        
                                    <AccordionDetails sx={{ fontSize: "1rem" }}>
                                        <div>
                                            <p>Capex / POS / RnR Details</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion> */}
                                {/* ------ */}
                            </Stack>
                    }

                    {
                        !isLoading && isEmptyNullUndefined(formData) && getNoDataMsg()                       
                    }
 

                    {/* <StatusHistory /> */}
                </div>
                 
                 {
                    
                    // activeGoalListTab !== "DRAFT" && !isEmptyNullUndefined(openExpandedCard) && commentLoader ? 
                    // <div className="comments-card-layout">
                    //     <div className="d-flex">
                    //         <p className="comments-label">Comments</p>
                    //         <p className="comment-count">{allComments?.length}</p>
                    //     </div>
                    //     <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    //     <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    //     <Skeleton animation="wave" variant="rectangular" style={{height:"100px", marginBottom:"1rem"}} />
                    // </div>
                    // :
                    !isEmptyNullUndefined(openExpandedCard) && activeGoalListTab !== "DRAFT" &&
                    <Comments data={allComments?.filter(x => x?.id === openExpandedCard)[0]?.comments || []} addComment={addComment} id={openExpandedCard} addCommentLoader={addCommentLoader} comment={comment} setComment={setComment} setGetCommentPayload={setGetCommentPayload} />
                 }                 
               
            </div>
        </div>
    )
}

export default EmployeeGoalView;