import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import AllLeave from './components/AllLeave';
import Leaves from './components/Leaves';
import AdditionalLeaves from './components/AdditionalLeaves';
import LeaveAtAGlanceModal from './components/Modal/LeaveAtAGlanceModal';
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { HiOutlineChevronDown } from "react-icons/hi2";


const MyLeave = () => {
  const [allLeaveView, setAllLeaveView] = useState(true);
  const [leavesView, setLeavesView] = useState(false);
  const [additionalLeavesView, setAdditionalLeavesView] = useState(false);
  const [showLeaveAtAGlanceModal, setShowLeaveAtAGlanceModal] = useState(false);

  const handleAllLeaveView = () => {
    setAllLeaveView(true);
    setLeavesView(false);
    setAdditionalLeavesView(false);
  };

  const handleLeavesView = () => {
    setAllLeaveView(false);
    setLeavesView(true);
    setAdditionalLeavesView(false);
  };

  const handleAdditionalLeavesView = () => {
    setAllLeaveView(false);
    setLeavesView(false);
    setAdditionalLeavesView(true);
  };

  const handleLeaveAtAGlanceModal = () => {
    setShowLeaveAtAGlanceModal(true);
  };

  const handleLeaveAtAGlanceModalNo = () => {
    setShowLeaveAtAGlanceModal(false);
  };

  return (
    <div className="my-leave">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"My Leaves Overview"}</Typography>

          <Grid className="right-top-grid">
            <Button className="leave-glance-btn" onClick={handleLeaveAtAGlanceModal}>{"Leave at a glance"}</Button>

            <Button className="my-team-btn">
              {"2024-25"}
              <HiOutlineChevronDown className="down-arrow" />
            </Button>
          </Grid>
        </Grid>

        {/*----- Feedback Comparison -----*/}
        <Grid item xs={12} sm={12} md={12}>
          {/*----- Top Button Section -----*/}
          <Grid class="top-btn-List">
            <a onClick={handleAllLeaveView} class={allLeaveView === true ? "active-btn" : "inActive-btn"}>{"All"}</a>
            <a
              // onClick={handleLeavesView} 
              class={leavesView === true ? "active-btn" : "inActive-btn"}>{"Leaves"}</a>
            <a
              // onClick={handleAdditionalLeavesView} 
              class={additionalLeavesView === true ? "active-btn" : "inActive-btn"}>{"Additional Leaves"}</a>
          </Grid>

          {allLeaveView === true &&
            <AllLeave />
          }
          {leavesView === true &&
            <Leaves />
          }
          {additionalLeavesView === true &&
            <AdditionalLeaves />
          }
        </Grid>
      </Grid>

      {/*----- Apply Modal -----*/}
      {showLeaveAtAGlanceModal && (
        <LeaveAtAGlanceModal
          showLeaveAtAGlanceModal={showLeaveAtAGlanceModal}
          handleLeaveAtAGlanceModalNo={handleLeaveAtAGlanceModalNo}
        />
      )}
    </div>
  );
};

export default MyLeave;
