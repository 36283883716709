import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../../../../../api/apiServices";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { isEmptyNullUndefined } from "../../../../../../utils/utils";
import letternotfound from "../../../../../../../assets/images/compensation/letterNotFound.jpg";

const ViewPDF = ({ fileId }) => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [pdfDownloadLoader, setPdfDownloadLoader] = useState(false);
    const [getPDFLoader, setGetPDFLoader] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => { 

        if(!isEmptyNullUndefined(fileId)) {
            getPDFData();
        }       
        
    }, [])   

    const handleGeneratePdf = async (fileId) => {
        setPdfDownloadLoader(true);
        axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/letter/compensation-pdf-by-file-id/${fileId}`,
            
            {headers: {
                'Authorization': `Bearer ${userToken?.id_token}`,
                'Content-Type': 'application/json',
              },  'responseType': 'arraybuffer'      
            })
            .then((response) => 
            {
              if(response?.data){
               // Create a Blob from the byte array
                const blob = new Blob([response?.data], { type: 'application/pdf' });
  
                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);
  
                // Create an anchor element and trigger a download
                const link = document.createElement('a');
                link.href = url;
                link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
                document.body.appendChild(link);
                link.click();
  
                // Clean up by removing the anchor element and revoking the object URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
              }
  
              setPdfDownloadLoader(false);
                
            })
            .catch((err) => {
              
                     toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.message}
                    </div>
                );
                setPdfDownloadLoader(false);
            });    
  
      };  


    const getPDFData = () => {
        setGetPDFLoader(true);

        axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/letter/compensation-pdf-by-file-id/${fileId}`,
            
            {headers: {
                'Authorization': `Bearer ${userToken?.id_token}`,
                'Content-Type': 'application/json',
              },  'responseType': 'arraybuffer'      
            })
            .then((response) => 
            {

               // Create a Blob from the byte array
                const blob = new Blob([response?.data], { type: 'application/pdf' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);

                // // Create an anchor element and trigger a download
                // const link = document.createElement('a');
                // link.href = url;
                // link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
                // document.body.appendChild(link);
                // link.click();

                // // Clean up by removing the anchor element and revoking the object URL
                // document.body.removeChild(link);
                // URL.revokeObjectURL(url);

            // setPDFData(response?.data);
              setGetPDFLoader(false);
                
            })
            .catch((err) => {
                     toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setGetPDFLoader(false);
            });    
    }

    const handleDownloadPdf = (e, fileId) => {
        e.stopPropagation();
        handleGeneratePdf(fileId);
      }

 
    return (
        <div className="performance-rate">

            {
            getPDFLoader ? 
            (
                <CircularProgress />
            )
            :
            pdfUrl ?
            (
                 
                <div className="pdf-viewer">
                    <div className="btn-outer">
                        <button className="download-btn"onClick={(e) => handleDownloadPdf(e, fileId)} disabled={pdfDownloadLoader}>
                        {
                        pdfDownloadLoader ? 
                            <CircularProgress size={15} />
                            :
                            "Download"
                        }
                        </button>
                    </div>
                    <iframe
                        src={pdfUrl}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                    />
                </div>
                
            )
            :
            <div className="not-fount">
                <div className="text">Letter Not Found</div>
                <img src={letternotfound} alt="not found" />
                </div>
            }       

        </div>
    )
}

export default ViewPDF;