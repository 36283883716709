import rightArrow from "../../../assets/images/EmployeeProfile/rightArrow.svg";
import searchIcon from "../../../assets/images/EmployeeProfile/searchIcon.svg";
import mail from "../../../assets/images/EmployeeProfile/mail.svg";
import phone from "../../../assets/images/EmployeeProfile/phone.svg";
import close from "../../../assets/images/EmployeeProfile/close.svg";
import minusClose from "../../../assets/images/EmployeeProfile/minusClose.svg";
import pdfDownload from "../../../assets/images/EmployeeProfile/pdfDownload.svg";
import selectDropDown from "../../../assets/images/EmployeeProfile/selectDropdown.svg";
import roundIcon from "../../../assets/images/EmployeeProfile/roundIcon.svg";
import linkIcon from "../../../assets/images/EmployeeProfile/link.svg";
import plusOpen from "../../../assets/images/EmployeeProfile/plusOpen.svg";

import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import Movement from "./Movement";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Skeleton,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { isEmptyNullUndefined } from "../../utils/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { LuPlusCircle } from "react-icons/lu";
import { LuMinusCircle } from "react-icons/lu";
import { EmpProfileData, EmployeeInfoDetails } from "./data";

const EmployeeProfile = () => {
  const history = useHistory();
  let id = "profile";
  const employeeDetails = EmployeeInfoDetails;

  const [showPopup, setShowPopup] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [viewMoreType, setViewMoreType] = useState("");
  const [activeTab, setActiveTab] = useState("Experience");
  const [activeTab2, setActiveTab2] = useState("Course");
  const [promisedata, setPromisedata] = useState([]);
  const [searchvalue, setSearchValue] = useState("");
  const [profileinfodata, setProfileinfodata] = useState({});
  const [empId, setEmpId] = useState("5032ffad-520c-452b-bcb3-7c9b8185ec55");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true,
  });

  useEffect(() => {
    if (isEmptyNullUndefined(id) || id === "profile") {
      // if id is not present that means user opening his own profile
      setEmpId(() => employeeDetails.id);
    } else {
      setEmpId(() => id);
    }
  }, [id, employeeDetails]);
  useEffect(() => {
    if (!isEmptyNullUndefined(searchvalue)) {
      const countdownInterval = setTimeout(() => {
        searchMyTeamData(searchvalue);
      }, 2000);
      return () => clearTimeout(countdownInterval);
    }
  }, [searchvalue]);
  useEffect(() => {
    if (empId) {
      setPromisedata(EmpProfileData);

      clickEmp(empId, employeeDetails.company.id);
    }
  }, [empId, employeeDetails]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchMyTeamData(searchvalue);
    }
  };
  const searchMyTeamData = (keyword) => {
    setSearchLoading(() => true);

    // APIList.searchMyTeam({
    //   payload: {
    //     keyword: keyword,
    //     companyId: "ba02f418-e44d-467c-9d5d-421a2d966460",
    //   },
    // })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setMyTeamDataSearch(res?.data);
    //     }
    //     // setSearchValue(() => '')
    //     setSearchLoading(() => false);
    //   })
    //   .catch((err) => {
    //     toast.error(
    //       <div style={{ display: "flex", flexDirection: "row" }}>
    //         <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
    //         &nbsp;&nbsp;{err?.title}
    //       </div>
    //     );
    //     setSearchLoading(() => false);
    //   });
  };

  const handleViewMore = (competency, type) => {
    setSelectedCompetency(competency);
    setViewMoreType(type);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedCompetency(null);
    setViewMoreType("");
  };

  const uniqueAndSort = (data, key) => {
    if (!data || !data.length) return [];

    // Combine data by the key (e.g., reviewYear)
    const combinedData = data.reduce((acc, curr) => {
      if (!acc[curr[key]]) {
        acc[curr[key]] = { [key]: curr[key], items: [] };
      }
      acc[curr[key]].items.push(curr);
      return acc;
    }, {});

    // Convert the combined object to an array and sort by the key in descending order
    return Object.values(combinedData).sort((a, b) => b[key] - a[key]);
  };

  const competencyFeedback = promisedata?.competencyFeedback;

  // Use uniqueAndSort function for competencyFeedback
  const combinedCompetencyYear = uniqueAndSort(
    competencyFeedback,
    "reviewYear"
  );
  const combinedCompetencyName = uniqueAndSort(
    competencyFeedback,
    "competencyName"
  );
  const ratingHeader = uniqueAndSort(promisedata?.ratingHistory, "ratingName");
  const ratingHistory = promisedata?.ratingHistory;

  // console.log("ratingHistory", ratingHistory);
  // Check if ratingHistory is defined and has items
  const combinedRatings = ratingHistory?.length
    ? ratingHistory.reduce((acc, curr) => {
        if (!acc[curr.reviewYear]) {
          acc[curr.reviewYear] = {
            reviewYear: curr.reviewYear,
            OutputRating: "",
            InputRating: "",
            OverallRating: "",
          };
        }
        acc[curr.reviewYear][curr.ratingName] = curr.trendingRating;
        return acc;
      }, {})
    : {};

  // Convert the combined object to an array and sort by reviewYear in descending order
  const combinedRatingsArray = Object.values(combinedRatings).sort(
    (a, b) => b.reviewYear - a.reviewYear
  );
  const handleChangeSearch = (event, newInputValue) => {
    setSearchValue(newInputValue);
  };

  const clickEmp = (empId, compId) => {
    setProfileinfodata(EmployeeInfoDetails);
  };

  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded,
    }));
  };

  const togglePanel = (panel) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  return (
    <div className="emp-profile-main">
      <p className="header-title">Employee Profile</p>
      <div className="breadcumb">
        <p className="mainPage" onClick={() => history.push(`/team-view`)}>
          Team View
        </p>
        <img src={rightArrow} alt="" />
        <p className="subPage">Member</p>
      </div>
      <div className="profile">
        <div className="searchBar">
          <p className="search-title">Search for Team Members</p>
          <Autocomplete
            inputValue={searchvalue || ""}
            disabled={isLoadingData}
            id="searchemp"
            disableClearable
            options={[]}
            fullWidth
            freeSolo
            disableCloseOnSelect
            onInputChange={handleChangeSearch}
            onKeyDown={handleKeyDown}
            getOptionLabel={(option) => option?.employeeName || ""}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  display: "flex",
                  padding: 10,
                  borderBottom: "0.6px solid #F3F3F3",
                  width: "100%",
                }}
                onClick={() => clickEmp(option?.employeeId, option?.company)}
              >
                <div className={"searchOptions"} style={{ display: "flex" }}>
                  <div>
                    <Avatar
                      name={option?.employeeName || "Unknown User"}
                      size="40"
                      round={true}
                    />
                  </div>
                  <div style={{ margin: "0rem 1.2rem" }}>
                    {option?.employeeName}
                    <br />
                    {option?.employeeLevel}
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                // inputValue={searchvalue}
                placeholder="Search using Name"
                fullWidth
                variant="outlined"
                style={{
                  width: "50%",
                  margin: "0",
                  padding: "0",
                  height: "2.2rem",
                  float: "right",
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    height: "2.2rem",
                    padding: "0 8px",
                    lineHeight: "25px",
                  }, // Adjust internal padding and height
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchLoading ? (
                        <div className="d-flex">
                          <CircularProgress
                            style={{ width: "20px", height: "20px" }}
                          />
                        </div>
                      ) : (
                        <img
                          src={searchIcon}
                          className="searchIcon"
                          alt="Search Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      
        {isLoadingData ? (
          <Skeleton height={400} />
        ) : profileinfodata ? (
          <div className="profile-details">
            {profileinfodata?.profilePhotoPath ? (
              <img
                src={profileinfodata.profilePhotoPath}
                className="userImage"
                alt="User Profile"
              />
            ) : (
              <Avatar
                name={profileinfodata?.name || "Unknown User"}
                size="40"
                round={true}
              />
            )}
            <div className="details">
              <div className="detailsOne">
                <div className="detailsInner">
                  <p className="userName">{profileinfodata?.employeeName}</p>
                  <p className="dept">
                    Head - Business Finance
                  </p>
                  <p className="empId">
                    Employee ID: {profileinfodata?.employeeNo}
                  </p>
                  <div className="cardOuter">
                    <p className="card">BU: Corporate</p>
                    <p className="card">Dept: HR Ops & Technology</p>
                  </div>
                </div>
                <div className="contact">
                  <div className="mailOuter">
                    <img src={mail} alt=""/>
                    <p className="mail">{profileinfodata?.emailId}</p>
                  </div>
                  <div className="mobNoOuter">
                    <img src={phone} alt=""/>
                    <p className="mobNo">(+91) 1234567890</p>
                  </div>
                </div>
              </div>
              <div className="detailsTwo">
                <div className="detailsTwoInner">
                  <p className="title">Grade</p>
                  <p className="subTitle">{profileinfodata?.employeeLevel}</p>
                </div>
                <div className="detailsTwoInner">
                  <p className="title">PhonePe Tenure</p>
                  <p className="subTitle">{profileinfodata?.tenure}</p>
                </div>
                <div className="detailsTwoInner">
                  <p className="title">Time Since Last Promotion</p>
                  <p className="subTitle">
                    {profileinfodata?.timeSinceLastPromotion}
                  </p>
                </div>
                <div className="detailsTwoInner">
                  <p className="title">EEP Completion %</p>
                  <p className="subTitle">
                    {profileinfodata?.eepCompletioneepCompletion}
                  </p>
                </div>
              </div>
              {/* temporary hide about me section --2-july */}
              {/* <div className="about"> 
                            <img src={hand} />
                            <div className="aboutInner">
                                <p className="title">About me</p>
                                <p className="desc">My name is [{profileinfodata?.employeeName}], and I'm passionate about [Interest], [Interest] and [Interest]. I'm currently working as a [Your current job title] where I practise [Relevant skill] and [Relevant skill] every day.</p>
                            </div>
                        </div> */}
            </div>
          </div>
        ) : (
          ""
        )}
        {isLoadingData ? (
          <Skeleton height={400} />
        ) : (
          <div className="tabs">
            <div className="tabs-block">
              <div
                className={`tab ${activeTab === "Experience" ? "active" : ""}`}
                onClick={() => setActiveTab("Experience")}
              >
                Experience
              </div>
              <div
                className={`tab ${activeTab === "Education" ? "active" : ""}`}
                onClick={() => setActiveTab("Education")}
              >
                Education & Certification
              </div>
              <div
                className={`tab ${activeTab === "Performance" ? "active" : ""}`}
                // className="active"
                onClick={() => setActiveTab("Performance")}
              >
                Performance History
              </div>
              <div
                className={`tab ${activeTab === "Interests" ? "active" : ""}`}
                onClick={() => setActiveTab("Interests")}
              >
                Interests & Aspiration
              </div>
              <div
                className={`tab ${activeTab === "Learning" ? "active" : ""}`}
                onClick={() => setActiveTab("Learning")}
              >
                Learning & Development
              </div>
            </div>
            {activeTab === "Performance" && (
              <div className="performanceHistory">
                <p className="title">Performance History</p>
                <div className="ratingOuter">
                  {(() => {
                    return (
                      <div className="rating">
                        <div>
                          {(() => {
                            if (expanded.panel1 === true) {
                              return (
                                <LuMinusCircle
                                  style={{
                                    cursor: "pointer",
                                    width: "25px",
                                    color: "#7F56D9",
                                    height: "25px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    togglePanel("panel1");
                                  }}
                                />
                              );
                            } else {
                              return (
                                <LuPlusCircle
                                  style={{
                                    cursor: "pointer",
                                    width: "25px",
                                    color: "#7F56D9",
                                    height: "25px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    togglePanel("panel1");
                                  }}
                                />
                              );
                            }
                          })()}
                        </div>
                        <div className="ratingInner">
                          <Accordion
                            sx={{
                              boxShadow: "none !important",
                              "& .MuiAccordionSummary-root": {
                                paddingLeft: 0,
                                boxShadow: "none !important",

                                "& .MuiAccordionSummary-content": {
                                  marginTop: 0,
                                  paddingTop: 0,
                                },
                              },
                            }}
                            expanded={expanded.panel1}
                            onChange={handleChangeAccordian("panel1")}
                          >
                            <AccordionSummary
                              sx={{
                                pointerEvents: "none",
                              }}
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <p className="ratingTitle">
                                <span className="name">Ratings</span>
                              </p>
                            </AccordionSummary>
                            <AccordionDetails>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Annual Review</th>
                                    {ratingHeader.length > 0 &&
                                      ratingHeader.map((rhh) => (
                                        <th>{rhh.ratingName}</th>
                                      ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {combinedRatingsArray.length > 0 ? (
                                    combinedRatingsArray.map((rh, index) => (
                                      <tr key={index}>
                                        <td className="bg">
                                          {Number(rh.reviewYear) - 1}-
                                          {rh.reviewYear}
                                        </td>
                                        {ratingHeader.length > 0 &&
                                          ratingHeader.map((rhh) => (
                                            <td>{rh[rhh.ratingName]}</td>
                                          ))}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="4">No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        {/* <img src={minusClose} alt="Close Icon" />
                                    <div className="ratingInner">
                                        <p className="ratingTitle">
                                            <span className="name">Ratings</span>
                                        </p>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Annual Review</th>
                                                    {
                                                        ratingHeader.length > 0 && ratingHeader.map((rhh) =>
                                                            <th>{rhh.ratingName}</th>

                                                        )
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    combinedRatingsArray.length > 0 ? combinedRatingsArray.map((rh, index) =>
                                                        <tr key={index}>
                                                            <td className="bg">{Number(rh.reviewYear) - 1}-{rh.reviewYear}</td>
                                                            {ratingHeader.length > 0 && ratingHeader.map((rhh) =>
                                                                <td>{rh[rhh.ratingName]}</td>

                                                            )}


                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">No data available</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div> */}
                      </div>
                    );
                  })()}

                  <div className="rating">
                    <div>
                      {(() => {
                        if (expanded.panel2 === true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel2");
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel2");
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",

                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                            },
                          },
                        }}
                        expanded={expanded.panel2}
                        onChange={handleChangeAccordian("panel2")}
                      >
                        <AccordionSummary
                          sx={{ pointerEvents: "none" }}
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <p className="ratingTitle">
                            <span className="name">Performance Forms</span>
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="performanceCard">
                            {promisedata?.performanceForms?.length > 0 &&
                              promisedata?.performanceForms?.map((pf) => (
                                <div className="card">
                                  <div className="top">
                                    <p className="year">{pf?.year}</p>
                                    <a
                                      href={pf.path}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img src={pdfDownload} alt="" />
                                    </a>
                                  </div>
                                  <p className="fileName">{pf?.fileName}</p>
                                </div>
                              ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <hr className="dividerLine"></hr>
                </div>
                <p className="title">Competency Profile</p>
                <div className="ratingOuter">
                  <div className="rating">
                    <div>
                      {(() => {
                        if (expanded.panel3 === true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel3");
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel3");
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <div className="d-flex justify-content-between">
                        <p className="ratingTitle">
                          1.
                          <span className="name">
                            Identified Strength / Development Areas
                          </span>
                        </p>
                        <div className="formOuter">
                          <div className="form">
                            <label>Select Role</label>
                            <div className="selectOption">
                              <select value={searchvalue}>
                                {promisedata?.roles?.length > 0 &&
                                  promisedata?.roles?.map((role) => (
                                    <option value={role.roleId}>
                                      {role?.roleName}
                                    </option>
                                  ))}
                              </select>
                              <img
                                src={selectDropDown}
                                className="selectDD"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th className="right">Input / Competency Name</th>
                            {combinedCompetencyYear.length > 0 &&
                              combinedCompetencyYear.map((yr) => (
                                <th>{yr.reviewYear}</th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {combinedCompetencyName.length > 0 ? (
                            combinedCompetencyName.map((cfGroup, index) => (
                              <tr key={`${index}-4`}>
                                <td className="bg right">
                                  {cfGroup.competencyName}
                                </td>
                                {cfGroup.items.map(
                                  (cf) =>
                                    combinedCompetencyYear.length > 0 &&
                                    combinedCompetencyYear.map(
                                      (yr) =>
                                        yr.reviewYear === cf.reviewYear && (
                                          <td>{cf.ratingDescription}</td>
                                        )
                                    )
                                )}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="rating">
                    <div>
                      {(() => {
                        if (expanded.panel4 === true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel4");
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel4");
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",

                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                            },
                          },
                        }}
                        expanded={expanded.panel4}
                        onChange={handleChangeAccordian("panel4")}
                      >
                        <AccordionSummary
                          sx={{
                            pointerEvents: "none",
                          }}
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <p className="ratingTitle">
                            {/* 2.  */}
                            <span className="name">
                              Movement in the Journey
                            </span>
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="movementJourney">
                            <div className="movementJourneyInner">
                              {promisedata?.movementInTheJourney ? (
                                <Movement
                                  className="movementJourney"
                                  data={promisedata?.movementInTheJourney}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="rating">
                    <div>
                      {(() => {
                        if (expanded.panel5 === true) {
                          return (
                            <LuMinusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel5");
                              }}
                            />
                          );
                        } else {
                          return (
                            <LuPlusCircle
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                color: "#7F56D9",
                                height: "25px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                togglePanel("panel5");
                              }}
                            />
                          );
                        }
                      })()}
                    </div>
                    <div className="ratingInner">
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            paddingLeft: 0,
                            boxShadow: "none !important",

                            "& .MuiAccordionSummary-content": {
                              marginTop: 0,
                              paddingTop: 0,
                            },
                          },
                        }}
                        expanded={expanded.panel5}
                        onChange={handleChangeAccordian("panel5")}
                      >
                        <AccordionSummary
                          sx={{ pointerEvents: "none" }}
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <p className="ratingTitle">
                            {/* 3.  */}
                            <span className="name">
                              Input / Competency: Qualitative Comments
                            </span>
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <table>
                            <thead>
                              <tr>
                                <th>Competency Name</th>
                                <th>Strength Area by Manager</th>
                                <th>Strength Area by Peers</th>
                                <th>Development Area by Manager</th>
                                <th>Development Area by Peers</th>
                              </tr>
                            </thead>
                            <tbody>
                              {promisedata?.qualitativeComments?.length > 0 &&
                                promisedata?.qualitativeComments.map(
                                  (qc, index) => (
                                    <tr key={index}>
                                      <td className="bg">
                                        {qc?.annualCycleName}
                                      </td>
                                      <td className="left">
                                        <p className="">
                                          {qc?.strengthAreaManager.length > 70
                                            ? qc?.strengthAreaManager.substring(
                                                0,
                                                63
                                              ) + "..."
                                            : qc?.strengthAreaManager}
                                          {qc?.strengthAreaManager.length >
                                            70 && (
                                            <span
                                              style={{
                                                color: "#7F56D9",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleViewMore(
                                                  qc,
                                                  "strengthAreaManager"
                                                )
                                              }
                                            >
                                              View More
                                            </span>
                                          )}
                                        </p>
                                      </td>
                                      <td className="left">
                                        <p className="">
                                          {qc?.strengthAreaPeers.length > 70
                                            ? qc?.strengthAreaPeers.substring(
                                                0,
                                                63
                                              ) + "..."
                                            : qc?.strengthAreaPeers}
                                          {qc?.strengthAreaPeers.length >
                                            70 && (
                                            <span
                                              style={{
                                                color: "#7F56D9",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleViewMore(
                                                  qc,
                                                  "strengthAreaPeers"
                                                )
                                              }
                                            >
                                              View More
                                            </span>
                                          )}
                                        </p>
                                      </td>
                                      <td className="left">
                                        <p className="">
                                          {qc?.developmentAreaManager.length >
                                          70
                                            ? qc?.developmentAreaManager.substring(
                                                0,
                                                63
                                              ) + "..."
                                            : qc?.developmentAreaManager}
                                          {qc?.developmentAreaManager.length >
                                            70 && (
                                            <span
                                              style={{
                                                color: "#7F56D9",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleViewMore(
                                                  qc,
                                                  "developmentAreaManager"
                                                )
                                              }
                                            >
                                              View More
                                            </span>
                                          )}
                                        </p>
                                      </td>
                                      <td className="left">
                                        <p className="">
                                          {qc?.developmentAreaPeers.length > 70
                                            ? qc?.developmentAreaPeers.substring(
                                                0,
                                                63
                                              ) + "..."
                                            : qc?.developmentAreaPeers}
                                          {qc?.developmentAreaPeers.length >
                                            70 && (
                                            <span
                                              style={{
                                                color: "#7F56D9",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleViewMore(
                                                  qc,
                                                  "developmentAreaPeers"
                                                )
                                              }
                                            >
                                              View More
                                            </span>
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {selectedCompetency && (
                      <Dialog
                        open={showPopup}
                        onClose={handleClosePopup}
                        aria-labelledby="popup-title"
                      >
                        {/* <DialogTitle id="popup-title">{selectedCompetency.annualCycleName}</DialogTitle> */}
                        <DialogContent>
                          <DialogActions>
                            <img
                              src={close}
                              onClick={handleClosePopup}
                              alt=""
                            ></img>
                            {/* <Button onClick={handleClosePopup} color="primary">Close</Button> */}
                          </DialogActions>
                          {viewMoreType === "strengthAreaManager" && (
                            <DialogContentText>
                              <strong>Strength Area by Manager:</strong>{" "}
                              {selectedCompetency.strengthAreaManager}
                            </DialogContentText>
                          )}
                          {viewMoreType === "strengthAreaPeers" && (
                            <DialogContentText>
                              <strong>Strength Area by Peers:</strong>{" "}
                              {selectedCompetency.strengthAreaPeers}
                            </DialogContentText>
                          )}
                          {viewMoreType === "developmentAreaManager" && (
                            <DialogContentText>
                              <strong>Development Area by Manager:</strong>{" "}
                              {selectedCompetency.developmentAreaManager}
                            </DialogContentText>
                          )}
                          {viewMoreType === "developmentAreaPeers" && (
                            <DialogContentText>
                              <strong>Development Area by Peers:</strong>{" "}
                              {selectedCompetency.developmentAreaPeers}
                            </DialogContentText>
                          )}
                        </DialogContent>
                      </Dialog>
                    )}
                  </div>
                  {employeeDetails.id !== empId && (
                    <div className="rating">
                      <div>
                        {(() => {
                          if (expanded.panel6 === true) {
                            return (
                              <LuMinusCircle
                                style={{
                                  cursor: "pointer",
                                  width: "25px",
                                  color: "#7F56D9",
                                  height: "25px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  togglePanel("panel6");
                                }}
                              />
                            );
                          } else {
                            return (
                              <LuPlusCircle
                                style={{
                                  cursor: "pointer",
                                  width: "25px",
                                  color: "#7F56D9",
                                  height: "25px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  togglePanel("panel6");
                                }}
                              />
                            );
                          }
                        })()}
                      </div>
                      <div className="ratingInner">
                        <Accordion
                          sx={{
                            boxShadow: "none !important",
                            "& .MuiAccordionSummary-root": {
                              paddingLeft: 0,
                              boxShadow: "none !important",

                              "& .MuiAccordionSummary-content": {
                                marginTop: 0,
                                paddingTop: 0,
                              },
                            },
                          }}
                          expanded={expanded.panel6}
                          onChange={handleChangeAccordian("panel6")}
                        >
                          <AccordionSummary
                            sx={{ pointerEvents: "none" }}
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <p className="ratingTitle">
                              {/* 4.  */}
                              <span className="name">
                                360 Feedback Report : For Managers / HRBP
                              </span>
                            </p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="performanceCard">
                              <div className="card">
                                {promisedata?.feedbackReports?.length > 0 &&
                                  promisedata?.feedbackReports?.map((fr) => (
                                    <div className="card">
                                      <div className="top">
                                        <p className="year">{fr?.year}</p>
                                        <a
                                          href={fr.path}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <img src={pdfDownload} alt="" />
                                        </a>
                                      </div>
                                      <p className="fileName">{fr?.fileName}</p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeTab === "Experience" && (
              <div className="experience">
                <p className="title">PhonePe</p>
                <div className="ratingOuter">
                  <div className="rating">
                    <img src={minusClose} className="openCloseImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        1. <span className="name">Head - Business Finance</span>
                        <span className="subName">(Current Role)</span>
                      </p>
                      <div className="detailsTwo">
                        <div className="detailsTwoInner">
                          <p className="title">Time in Role</p>
                          <p className="subTitle">
                            2 Years (Apr 2022 to Present)
                          </p>
                        </div>
                        <div className="detailsTwoInner">
                          <p className="title">Business Unit</p>
                          <p className="subTitle">Corporate</p>
                        </div>
                        <div className="detailsTwoInner">
                          <p className="title">Department</p>
                          <p className="subTitle">HR Ops & Technology</p>
                        </div>
                        <div className="detailsTwoInner">
                          <p className="title">Location</p>
                          <p className="subTitle">Bengaluru Urban, Karnataka</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="subRating">
                      <p className="ratingSubTitle">Responsibilities</p>
                      <div className="listOuter">
                        <p className="listTitle">
                          Financial Planning & Analysis (FP&A)
                        </p>
                        <ul className="list">
                          <li>
                            Create financial forecasts and budgets for the
                            business unit.
                          </li>
                          <li>
                            Analyze financial data to identify trends and risks.
                          </li>
                          <li>
                            Develop long-term financial plans to support
                            business growth.
                          </li>
                          <li>
                            Partner with other departments (e.g., Sales,
                            Operations) to ensure financial alignment with
                            business goals.
                          </li>
                        </ul>
                      </div>
                      <div className="listOuter">
                        <p className="listTitle">Financial Operations</p>
                        <ul className="list">
                          <li>
                            Create financial forecasts and budgets for the
                            business unit.
                          </li>
                          <li>
                            Oversee financial reporting and ensure compliance
                            with accounting standards.
                          </li>
                          <li>
                            Develop and implement internal controls to safeguard
                            financial assets.
                          </li>
                          <li>
                            Manage a team of finance professionals (depending on
                            the company size)
                          </li>
                        </ul>
                      </div>
                      <div className="listOuter">
                        <p className="listTitle">Strategic Support</p>
                        <ul className="list">
                          <li>
                            Provide financial insights and analysis to support
                            business decisions.
                          </li>
                          <li>
                            Evaluate investment opportunities and recommend
                            courses of action.
                          </li>
                          <li>
                            Manage relationships with banks and other financial
                            institutions.
                          </li>
                          <li>
                            Identify cost-saving opportunities and implement
                            process improvements.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="subRating">
                      <p className="ratingSubTitle">Projects / Assignments</p>
                      <div className="projectDetails">
                        <div className="goal">
                          <p className="goalTitle">Goal</p>
                          <p className="goalDesc">
                            Buliding HR Datamart as a long-term solution to
                            cater to present & future analytcs needs of boh HR &
                            Business
                          </p>
                        </div>
                        <div className="desc">
                          <p className="descTitle">Description</p>
                          <p className="content">
                            This is to mark a shift, <br></br>
                            From Excel sheets being currently stored on a SQL
                            layer to feed Analytics dashboards, <br></br>
                            TO API Based real -time integration b/w Source
                            &gt;&gt; through an Intermediate Layer &gt;&gt; to
                            the BI-layer
                          </p>
                          <p className="content">It involves,</p>
                          <p className="content">
                            1. SAP HANA vs Lucy: Finalizing the platform for HR
                            datamart.
                          </p>
                          <p className="content">
                            2. SAP HANA vs Lucy: Finalizing the platform for HR
                            datamart.
                          </p>
                          <p className="content">
                            3. SAP HANA vs Lucy: Finalizing the platform for HR
                            datamart.
                          </p>
                          <p className="content">
                            4. SAP HANA vs Lucy: Finalizing the platform for HR
                            datamart.
                          </p>
                          <p className="content">
                            5. SAP HANA vs Lucy: Finalizing the platform for HR
                            datamart.
                          </p>
                        </div>
                        <div className="due">
                          <div className="dueDate">
                            <p className="title">Start Date</p>
                            <p className="date">24 Apr 2024</p>
                          </div>
                          <div className="dueDate">
                            <p className="title">End Date</p>
                            <p className="date">24 Apr 2024</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ratingOuter">
                  <div className="rating">
                    <img src={plusOpen} className="openCloseImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        2.{" "}
                        <span className="name">
                          Associate Head - Business Finance
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ratingOuter">
                  <div className="rating">
                    <img src={plusOpen} className="openCloseImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        3.{" "}
                        <span className="name">
                          Associate Head - Business Finance
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="dividerLine"></hr>
              </div>
            )}

            {activeTab === "Education" && (
              <div className="educationCertifications">
                <p className="title">Education</p>
                <div className="ratingOuter">
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        1. <span className="name">Head - Business Finance</span>
                        <span className="subName">(Current Role)</span>
                      </p>
                      <div className="educationDetails">
                        <p className="title">
                          Specialization:{" "}
                          <span className="subTitle">Marketing</span>
                        </p>
                        <p className="subDetails">
                          Department of Management Studies, IIT Delhi
                        </p>
                        <p className="dateRange">Aug 2017 - Dec 2020</p>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        2.{" "}
                        <span className="name">
                          Bachelor of Business Administration
                        </span>
                      </p>
                      <div className="educationDetails">
                        <p className="title">
                          Specialization:{" "}
                          <span className="subTitle">Marketing</span>
                        </p>
                        <p className="subDetails">
                          Department of Management Studies, IIT Delhi
                        </p>
                        <p className="dateRange">Aug 2017 - Dec 2020</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="dividerLine"></hr>
                <p className="title">Certification</p>
                <div className="ratingOuter">
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        1.{" "}
                        <span className="name">
                          PMP (Project Management Professional)
                        </span>
                        <span className="subName">(Current Role)</span>
                      </p>
                      <div className="educationDetails">
                        <p className="title">
                          Issue Date:{" "}
                          <span className="subTitle">01 Mar 2024</span>
                          <span className="dot">&nbsp;</span>
                          Expiration:{" "}
                          <span className="subTitle">01 Mar 2025</span>
                        </p>
                        <p className="subDetails">
                          Department of Management Studies, IIT Delhi
                        </p>
                        <div className="link">
                          <img src={linkIcon} alt="" />
                          <p className="linkText">Certificate URL / Link</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        2.{" "}
                        <span className="name">
                          PG Level Advanced Certification Programme in
                          Computational Data Science
                        </span>
                      </p>
                      <div className="educationDetails">
                        <p className="title">
                          Issue Date:{" "}
                          <span className="subTitle">01 Mar 2024</span>
                          <span className="dot">&nbsp;</span>
                          Expiration:{" "}
                          <span className="subTitle">01 Mar 2025</span>
                        </p>
                        <p className="subDetails">
                          Carson College of Business, Washington DC, USA
                        </p>
                        <div className="link">
                          <img src={linkIcon} alt="" />
                          <p className="linkText">Certificate URL / Link</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={roundIcon} className="roundImg" alt="" />
                    <div className="ratingInner">
                      <p className="ratingTitle">
                        3.{" "}
                        <span className="name">
                          CISA (Certified Information Systems Auditor)
                        </span>
                      </p>
                      <div className="educationDetails">
                        <p className="title">
                          Issue Date:{" "}
                          <span className="subTitle">01 Mar 2024</span>
                          <span className="dot">&nbsp;</span>
                          Expiration:{" "}
                          <span className="subTitle">01 Mar 2025</span>
                        </p>
                        <p className="subDetails">
                          Carson College of Business, Washington DC, USA
                        </p>
                        <div className="link">
                          <img src={linkIcon} alt="" />
                          <p className="linkText">Certificate URL / Link</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "Interests" && (
              <div className="interestAspiration">
                <p className="title">Interests & Aspiration</p>
                <div className="ratingOuter">
                  <div className="rating">
                    <img src={minusClose} className="openCloseImg" alt="" />
                    <div className="ratingInner">
                      <div className="ratingTitleOuter">
                        <p className="ratingTitle">
                          1. <span className="name">Head of Product </span>
                          <span className="subName">(aspiration)</span>
                        </p>
                        <p className="dateOuter">
                          Date: <span className="date">July 01, 2023</span>
                        </p>
                      </div>
                      <div className="educationDetails">
                        <p className="dateRange"># of Development Goals</p>
                        <p className="subDetails">
                          Ongoing (1) ; Completed (2)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={minusClose} className="openCloseImg" alt="" />
                    <div className="ratingInner">
                      <div className="ratingTitleOuter">
                        <p className="ratingTitle">
                          1.{" "}
                          <span className="name">
                            Lead a Complex Data Engineering charter{" "}
                          </span>
                          <span className="subName">(aspiration)</span>
                        </p>
                        <p className="dateOuter">
                          Date: <span className="date">July 01, 2023</span>
                        </p>
                      </div>
                      <div className="educationDetails">
                        <p className="dateRange"># of Development Goals</p>
                        <p className="subDetails">
                          Ongoing (1) ; Completed (2)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="rating">
                    <img src={minusClose} className="openCloseImg" alt="" />
                    <div className="ratingInner">
                      <div className="ratingTitleOuter">
                        <p className="ratingTitle">
                          1. <span className="name">Learn MongoDB </span>
                          <span className="subName">(aspiration)</span>
                        </p>
                        <p className="dateOuter">
                          Date: <span className="date">July 01, 2023</span>
                        </p>
                      </div>
                      <div className="educationDetails">
                        <p className="dateRange"># of Development Goals</p>
                        <p className="subDetails">
                          Ongoing (1) ; Completed (2)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "Learning" && (
              <div className="LearningDevelopment">
                <p className="title">Learning & Development</p>
                <div className="tabs2">
                  <div className="tabs-block">
                    <div
                      className={`tab ${
                        activeTab2 === "Course" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab2("Course")}
                    >
                      Course
                    </div>
                    <div
                      className={`tab ${
                        activeTab2 === "LearningDevelopment" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab2("LearningDevelopment")}
                    >
                      learning & Development
                    </div>
                  </div>
                  {activeTab2 === "Course" && (
                    <div className="course">
                      <div className="ratingOuter">
                        <div className="rating">
                          <img
                            src={minusClose}
                            className="openCloseImg"
                            alt=""
                          />
                          <div className="ratingInner">
                            <p className="ratingTitle">
                              1. <span className="name">Course</span>
                            </p>
                            <div className="projectDetails">
                              <div className="goal">
                                <p className="goalTitle">Course Name</p>
                                <p className="goalDesc">
                                  Project Management Chapter 1
                                </p>
                              </div>
                              <div className="due">
                                <div className="dueDate">
                                  <p className="title">Start Date</p>
                                  <p className="date">July 01, 2023</p>
                                </div>
                                <div className="dueDate">
                                  <p className="title">End Date</p>
                                  <p className="date">May 30, 2024</p>
                                </div>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">
                                  Through Learning Assistance Programs
                                </p>
                                <p className="goalDesc">Yes</p>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">Course Details</p>
                                <p className="goalDesc">
                                  Gain an immersive understanding of the
                                  practices and skills needed to succeed in an
                                  entry-level project management role. Learn how
                                  to create effective project documentation and
                                  artifacts throughout the various phases of a
                                  project. Gain an immersive understanding of
                                  the practices and skills needed to succeed in
                                  an entry-level project management role. Learn
                                  how to create effective project documentation
                                  and artifacts throughout the various phases of
                                  a project.
                                </p>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">
                                  List of Competencies, Skills
                                </p>
                                <div className="skillList">
                                  <p className="skillName">Problem Solving</p>
                                  <p className="skillName">
                                    Customer Centricity
                                  </p>
                                  <p className="skillName">Execution Rigour</p>
                                  <p className="skillName">
                                    Influencing / Building Consensus
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="dividerLine"></hr>
                        <div className="rating">
                          <img
                            src={minusClose}
                            className="openCloseImg"
                            alt=""
                          />
                          <div className="ratingInner">
                            <p className="ratingTitle">
                              2. <span className="name">Course</span>
                            </p>
                            <div className="projectDetails">
                              <div className="goal">
                                <p className="goalTitle">Course Name</p>
                                <p className="goalDesc">
                                  Project Management Chapter 1
                                </p>
                              </div>
                              <div className="due">
                                <div className="dueDate">
                                  <p className="title">Start Date</p>
                                  <p className="date">July 01, 2023</p>
                                </div>
                                <div className="dueDate">
                                  <p className="title">End Date</p>
                                  <p className="date">May 30, 2024</p>
                                </div>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">
                                  Through Learning Assistance Programs
                                </p>
                                <p className="goalDesc">Yes</p>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">Course Details</p>
                                <p className="goalDesc">
                                  Gain an immersive understanding of the
                                  practices and skills needed to succeed in an
                                  entry-level project management role. Learn how
                                  to create effective project documentation and
                                  artifacts throughout the various phases of a
                                  project. Gain an immersive understanding of
                                  the practices and skills needed to succeed in
                                  an entry-level project management role. Learn
                                  how to create effective project documentation
                                  and artifacts throughout the various phases of
                                  a project.
                                </p>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">
                                  List of Competencies, Skills
                                </p>
                                <div className="skillList">
                                  <p className="skillName">Problem Solving</p>
                                  <p className="skillName">
                                    Customer Centricity
                                  </p>
                                  <p className="skillName">Execution Rigour</p>
                                  <p className="skillName">
                                    Influencing / Building Consensus
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="dividerLine"></hr>
                        <div className="rating">
                          <img
                            src={minusClose}
                            className="openCloseImg"
                            alt=""
                          />
                          <div className="ratingInner">
                            <p className="ratingTitle">
                              3. <span className="name">Course</span>
                            </p>
                            <div className="projectDetails">
                              <div className="goal">
                                <p className="goalTitle">Course Name</p>
                                <p className="goalDesc">
                                  Project Management Chapter 1
                                </p>
                              </div>
                              <div className="due">
                                <div className="dueDate">
                                  <p className="title">Start Date</p>
                                  <p className="date">July 01, 2023</p>
                                </div>
                                <div className="dueDate">
                                  <p className="title">End Date</p>
                                  <p className="date">May 30, 2024</p>
                                </div>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">
                                  Through Learning Assistance Programs
                                </p>
                                <p className="goalDesc">Yes</p>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">Course Details</p>
                                <p className="goalDesc">
                                  Gain an immersive understanding of the
                                  practices and skills needed to succeed in an
                                  entry-level project management role. Learn how
                                  to create effective project documentation and
                                  artifacts throughout the various phases of a
                                  project. Gain an immersive understanding of
                                  the practices and skills needed to succeed in
                                  an entry-level project management role. Learn
                                  how to create effective project documentation
                                  and artifacts throughout the various phases of
                                  a project.
                                </p>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">
                                  List of Competencies, Skills
                                </p>
                                <div className="skillList">
                                  <p className="skillName">Problem Solving</p>
                                  <p className="skillName">
                                    Customer Centricity
                                  </p>
                                  <p className="skillName">Execution Rigour</p>
                                  <p className="skillName">
                                    Influencing / Building Consensus
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="dividerLine"></hr>
                      </div>
                    </div>
                  )}
                  {activeTab2 === "LearningDevelopment" && (
                    <div className="projectsMentorship">
                      <div className="ratingOuter">
                        <div className="rating">
                          <img
                            src={minusClose}
                            className="openCloseImg"
                            alt=""
                          />
                          <div className="ratingInner">
                            <p className="ratingTitle">
                              1. <span className="name">Project</span>
                            </p>
                            <div className="projectDetails">
                              <div className="goal">
                                <p className="goalTitle">Course Name</p>
                                <p className="goalDesc">
                                  Project Management Chapter 1
                                </p>
                              </div>
                              <div className="mentorDetails">
                                <p className="label">
                                  Mentor / Supervisor Details
                                </p>
                                <diiv className="mentorDetailsInner">
                                  <div className="detailsTwo">
                                    <div className="detailsTwoInner">
                                      <p className="title">Employee ID</p>
                                      <p className="subTitle">EMP211554</p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Name </p>
                                      <p className="subTitle">
                                        Sayed Adil Hussain
                                      </p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Designation</p>
                                      <p className="subTitle">Sr. HR Manager</p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Department</p>
                                      <p className="subTitle">HR</p>
                                    </div>
                                  </div>
                                  <div className="detailsTwo">
                                    <div className="detailsTwoInner">
                                      <p className="title">Start Date</p>
                                      <p className="subTitle">July 01, 2023</p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Completion Date</p>
                                      <p className="subTitle">May 30, 2024</p>
                                    </div>
                                  </div>
                                </diiv>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">Details</p>
                                <p className="goalDesc">
                                  Gain an immersive understanding of the
                                  practices and skills needed to succeed in an
                                  entry-level project management role. Learn how
                                  to create effective project documentation and
                                  artifacts throughout the various phases of a
                                  project. Gain an immersive understanding of
                                  the practices and skills needed to succeed in
                                  an entry-level project management role. Learn
                                  how to create effective project documentation
                                  and artifacts throughout the various phases of
                                  a project.
                                </p>
                              </div>
                              <div className="documentsAttached">
                                <p className="documentsLabel">
                                  Documents attached
                                </p>
                                <div className="performanceCard">
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="dividerLine"></hr>
                        <div className="rating">
                          <img
                            src={minusClose}
                            className="openCloseImg"
                            alt=""
                          />
                          <div className="ratingInner">
                            <p className="ratingTitle">
                              2. <span className="name">Mentorship</span>
                            </p>
                            <div className="projectDetails">
                              <div className="goal">
                                <p className="goalTitle">Course Name</p>
                                <p className="goalDesc">
                                  Project Management Chapter 1
                                </p>
                              </div>
                              <div className="mentorDetails">
                                <p className="label">
                                  Mentor / Supervisor Details
                                </p>
                                <diiv className="mentorDetailsInner">
                                  <div className="detailsTwo">
                                    <div className="detailsTwoInner">
                                      <p className="title">Employee ID</p>
                                      <p className="subTitle">EMP211554</p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Name </p>
                                      <p className="subTitle">
                                        Sayed Adil Hussain
                                      </p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Designation</p>
                                      <p className="subTitle">Sr. HR Manager</p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Department</p>
                                      <p className="subTitle">HR</p>
                                    </div>
                                  </div>
                                  <div className="detailsTwo">
                                    <div className="detailsTwoInner">
                                      <p className="title">Start Date</p>
                                      <p className="subTitle">July 01, 2023</p>
                                    </div>
                                    <div className="detailsTwoInner">
                                      <p className="title">Completion Date</p>
                                      <p className="subTitle">May 30, 2024</p>
                                    </div>
                                  </div>
                                </diiv>
                              </div>
                              <div className="goal">
                                <p className="goalTitle">Details</p>
                                <p className="goalDesc">
                                  Gain an immersive understanding of the
                                  practices and skills needed to succeed in an
                                  entry-level project management role. Learn how
                                  to create effective project documentation and
                                  artifacts throughout the various phases of a
                                  project. Gain an immersive understanding of
                                  the practices and skills needed to succeed in
                                  an entry-level project management role. Learn
                                  how to create effective project documentation
                                  and artifacts throughout the various phases of
                                  a project.
                                </p>
                              </div>
                              <div className="documentsAttached">
                                <p className="documentsLabel">
                                  Documents attached
                                </p>
                                <div className="performanceCard">
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="top">
                                      <p className="fileName">
                                        Project_doc1.pdf
                                      </p>
                                      <img src={pdfDownload} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <p className="title">Learning & Development</p> */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeProfile;
