import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyNullUndefined } from '../../../utils/utils';
import Avatar from 'react-avatar';

const parse = require('html-react-parser');

const PeerFeedbackStatic = ({data}) => {

    return (
        <div className="peer-feedback-container-in-sidebar">
            {data?.filter(section => section?.visible).map((section) => (
                <div key={section?.id} className="section-main">
                    <div className='section-label' dangerouslySetInnerHTML={{ __html: section?.label }} />
                    {
                    !isEmptyNullUndefined(section?.subSectionReferences) ?
                    section?.subSectionReferences?.filter(subSection => subSection?.visible).map((subSection) => (
                        <div key={subSection?.id} className="sub-section-main">
                            <div className="question-label" dangerouslySetInnerHTML={{ __html: subSection?.label }} />
                            {subSection?.feedbacks?.map((feedback) => (                                
                                <div key={feedback?.employeeId} className="feedback-item">
                                    <div className="icon-size">
                                        {feedback?.profilePhotoPath ? 
                                            <img src={feedback?.profilePhotoPath} className="user" alt="User" />
                                            :
                                            <Avatar 
                                                name={feedback?.employeeName} 
                                                size="45" 
                                                className="user"
                                                color={"#00425A"}  
                                            />
                                        }
                                    </div>
                                    <div className="feedback-header">
                                        <div className="employee-info">
                                            <span className="employee-name">{feedback?.employeeName}</span>
                                        </div>
                                        <div className="employee-department">
                                            <span className="employee-designation">{feedback?.employeeDesignation}</span>
                                        </div>
                                    </div>
                                    <div className="feedback-content">
                                    {subSection?.feedbacks && (
                                        <div className='feedback-text'>
                                            {/* { */}
                                                 {/* subSection?.feedbacks?.map((feedback, index) => { */}
                                                     {/* return( */}
                                                        <div key={feedback?.employeeName} className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseString) && 
                                                                parse(feedback?.responseObject?.responseString)
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseBoolean) ? 
                                                                feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                feedback?.responseObject?.responseInteger
                                                                }                                                                
                                                            </div>
                                                            {
                                                            !isEmptyNullUndefined(feedback?.responseObject?.rating?.name) && 
                                                            <div className='ratings'>
                                                                Rating : {feedback?.responseObject?.rating?.name}
                                                            </div>
                                                            }
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        feedback?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                     {/* ) */}
                                                 {/* }) */}
                                             {/* } */}
                                            
                                        </div>
                                    )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                    :
                       
                    // data?.filter(subSection => subSection?.visible).map((subSection) => (
                        <div key={section?.id} className="sub-section-main">
                            {/* <div className="question-label" dangerouslySetInnerHTML={{ __html: section?.label }} /> hide-by-mohit-27-09-dublicate-label */}
                            {section?.feedbacks?.map((feedback) => (                                
                                <div key={feedback?.employeeId} className="feedback-item">
                                    <div className="icon-size">
                                        {feedback?.profilePhotoPath ? 
                                            <img src={feedback?.profilePhotoPath} className="user" alt="User" />
                                            :
                                            <Avatar 
                                                name={feedback?.employeeName} 
                                                size="45" 
                                                className="user"
                                                color={"#00425A"}  
                                            />
                                        }
                                    </div>
                                    <div className="feedback-header">
                                        <div className="employee-info">
                                            <span className="employee-name">{feedback?.employeeName}</span>
                                        </div>
                                        <div className="employee-department">
                                            <span className="employee-designation">{feedback?.employeeDesignation}</span>
                                        </div>
                                    </div>
                                    <div className="feedback-content">
                                    {/* {subSection?.feedbacks && ( */}
                                        <div className='feedback-text'>
                                            {/* { */}
                                                 {/* subSection?.feedbacks?.map((feedback, index) => { */}
                                                     {/* return( */}
                                                        <div key={feedback?.employeeName} className="emp-response">
                                                            {/* {
                                                                <div className="text-responses">
                                                                    {
                                                                    !isEmptyNullUndefined(feedback?.responseObject?.responseString) && 
                                                                    parse(feedback?.responseObject?.responseString)
                                                                    }
                                                                    {
                                                                    !isEmptyNullUndefined(feedback?.responseObject?.responseBoolean) ? 
                                                                    feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                    :
                                                                    ""
                                                                    }
                                                                    {
                                                                    !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                    feedback?.responseObject?.responseInteger
                                                                    }                                                                
                                                                </div>
                                                            } */}
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseString) &&  feedback?.responseObject?.responseString?.length > 0 &&
                                                                <div className="text-responses">
                                                                    {parse(feedback?.responseObject?.responseString) }                                                                  
                                                                </div>
                                                            }
                                                            {
                                                                feedback?.responseObject?.responseBoolean !== null &&
                                                                <div className="text-responses">

                                                                    {                                                                    
                                                                    feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                    }
                                                                    
                                                                </div>
                                                            }
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                <div className="text-responses">
                                                                    {                                                                    
                                                                    feedback?.responseObject?.responseInteger
                                                                    }
                                                                </div>
                                                            }
                                                            
                                                            {
                                                            !isEmptyNullUndefined(feedback?.responseObject?.rating?.name) && 
                                                            <div className='ratings'>
                                                                Rating : {feedback?.responseObject?.rating?.name}
                                                            </div>
                                                            }
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        feedback?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                     {/* ) */}
                                                 {/* }) */}
                                             {/* } */}
                                            
                                        </div>
                                     {/* )} */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    // ))
                   
           }
                    
                </div>
            ))}
        </div>
    );
}

export default PeerFeedbackStatic;