import React, { useState } from 'react';
import { Grid, Typography, Drawer } from '@mui/material';
import AnnualLeaves from "../../../../../../assets/images/Leaves/annualLeaves.svg";
import ApplyModal from '../Modal/ApplyModal';

const RightLeavesDrawer = ({ anchorRightLeaveDrawer, openLeaveRight, onCloseRightLeaveDrawer }) => {
    const [showApplyModal, setShowApplyModal] = useState(false);

    const handleApplyModal = () => {
        setShowApplyModal(true);
    };

    const handleApplyModalNo = () => {
        setShowApplyModal(false);
    };

    return (
        <>
            <Drawer
                anchor={anchorRightLeaveDrawer}
                open={openLeaveRight}
                onClose={onCloseRightLeaveDrawer}
                PaperProps={{
                    sx: {
                        padding: "30px",
                        width: "600px",
                    },
                }}
            >
                <Grid container spacing={2} className="leave-drawer">
                    <Grid item xs={12} sm={12} md={12}>
                        {/*----- Top Leave Apply Btn -----*/}
                        <Grid className="top-drawer">
                            <Grid className="leave-cal-grid">
                                <img src={AnnualLeaves} alt="Slider" className="leave-calendar-icon" />
                                <Grid>
                                    <Typography className="leaves-title">{"Annual Leaves"}</Typography>
                                    <Typography className="taken-out-of">{"3 Taken out of 14"}</Typography>
                                </Grid>
                            </Grid>
                            <button className="apply-btn" onClick={handleApplyModal}>
                                {"Apply"}
                            </button>
                        </Grid>

                        {/*----- Horizontal border -----*/}
                        <Grid className="border-horizontal" />

                        {/*----- Description -----*/}
                        <Typography className="leave-description">{"We provides employees with an annual holiday entitlement of 21 days on prorate basis of the year to promote rest and relaxation away from the workplace and encourages employees to take this entitlement."}</Typography>

                        {/*----- Card -----*/}
                        <Grid className="entitlement-card-border">
                            <Grid className="entitlement-container">
                                <Grid>
                                    <Typography className="card-title left-top-border-radius">{"Entitlement"}</Typography>
                                    <Typography className="card-description">{"21 working days (paid) per year. "}</Typography>
                                </Grid>
                                <Grid className="vertical-border"></Grid>
                                <Grid>
                                    <Typography className="card-title right-top-border-radius">{"Eligibility"}</Typography>
                                    <Typography className="card-description ml-8">{"All Regular Full-time employees"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*----- POLICY -----*/}
                        <Grid>
                            <Typography className="leave-sub-title">{"POLICY"}</Typography>
                            <Typography className="leave-description">{"We provides employees with an annual holiday entitlement of 21 days on prorate basis of the year to promote rest and relaxation away from the workplace and encourages employees to take this entitlement."}</Typography>
                        </Grid>

                        {/*----- New Employees -----*/}
                        <Grid>
                            <Typography className="leave-sub-title"><span className="bullet-points">{"•"}</span>{"New Employees"}</Typography>
                            <Typography className="leave-description ml-25">{"You will start accruing vacation days from the first day of your employment. However you may not be able to take any vacation day(s) until you have  probationary period, subject to extenuating circumstances with the express permission of your manager."}</Typography>
                        </Grid>

                        {/*----- Advance Leave -----*/}
                        <Grid>
                            <Typography className="leave-sub-title"><span className="bullet-points">{"•"}</span>{"Advance Leave"}</Typography>
                            <Typography className="leave-description ml-25">{"Privileged Leave may be granted and used in advance of accrual, however, the advance leave shall not exceed 3.5 days or/2 months vacation accrual."}</Typography>
                        </Grid>

                        {/*----- Carry Forward (Annually) -----*/}
                        <Grid>
                            <Typography className="leave-sub-title"><span className="bullet-points">{"•"}</span>{"Carry Forward (Annually)"}</Typography>
                            <Typography className="leave-description ml-25">{"The Company recognizes that occasional business needs may require holiday to be deferred and for this reason, up to a maximum of 5 days(prorated for mid-year joinees)can be carried forward to the following  year. However the same must be taken by 30th June of the following year or be forfeited. Any leave over and above the accumulation limit permissible will lapse automatically."}</Typography>
                        </Grid>

                        {/*----- New Employees -----*/}
                        <Grid>
                            <Typography className="leave-sub-title"><span className="bullet-points">{"•"}</span>{"New Employees"}</Typography>
                            <Typography className="leave-description ml-25">{"You will start accruing vacation days from the first day of your employment. However you may not be able to take any  day(s) until you have completed probationary period, subject to extenuating circumstances with the express permission of your manager."}</Typography>
                        </Grid>

                        {/*----- Horizontal border -----*/}
                        <Grid className="border-horizontal" />

                        {/*----- Close Button -----*/}
                        <Grid className="close-btn-grid">
                            <button className="close-btn" onClick={onCloseRightLeaveDrawer}>
                                {"Close"}
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>

            {/*----- Apply Modal -----*/}
            {showApplyModal && (
                <ApplyModal
                    showApplyModal={showApplyModal}
                    handleApplyModalNo={handleApplyModalNo}
                />
            )}
        </>
    );
};

export default RightLeavesDrawer;