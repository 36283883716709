import React, { useState } from "react";
import { Grid, Button, Typography, MenuItem, Select } from "@mui/material";
import MyInterviewsViewFeedbackFor from './components/MyInterviewsViewFeedbackFor';
import MyRequisitionsViewFeedbackFor from './components/MyRequisitionsViewFeedbackFor';
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.png";
import DownloadIcon from "../../../../assets/images/CandidateAssessment/downloadIcon.svg";
import StronglyInclinedIcon from "../../../../assets/images/CandidateAssessment/stronglyInclined.svg";
import NotInclinedIcon from "../../../../assets/images/CandidateAssessment/notInclined.svg";

const ViewFeedbackFor = () => {

  const viewFeedbackLists = [
    {
      scheduleName: "Mohit Jain",
      selectDate: "23 Dec 2024",
      assignedCompetencies: [
        { title: "Customer Obsession" },
        { title: "Ownership" },
        { title: "Invent And Simplify" },
        { title: "Invent And Simplify" },
        { title: "Hire and Develop the Best" },
        { title: "Ownership" },
      ],
      feedback: "HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating. HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating.",
      decision: "Strongly inclined to hire",
    },
    {
      scheduleName: "Shreya Agarwal",
      selectDate: "24 Dec 2024",
      assignedCompetencies: [
        { title: "Customer Obsession" },
        { title: "Ownership" },
        { title: "Invent And Simplify" },
        { title: "Ownership" },
      ],
      feedback: "HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating. HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating.",
      decision: "Not inclined to hire",
    },
    {
      scheduleName: "Brijesh Pathak",
      selectDate: "Select",
      assignedCompetencies: [],
      feedback: "",
      decision: "",
    },
  ];

  return (
    <div className="view-feedback-for">
      <Grid container spacing={2}>
        {/*----- Feedback for -----*/}
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Feedback for"}</Typography>
        </Grid>

        {/*----- Schedule Interview View Feedback For -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <Grid container className="user-form-card">
            {/*----- User Details -----*/}
            <Grid item xs={12} sm={12} md={12} className="user-card">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2.5} sm={2.5} className="profile-grid">
                  <img
                    src={EditUserProfile}
                    alt="EditUserProfile"
                    height={50}
                    width={50}
                  />
                  <Grid>
                    <Typography className="profile-name">{"Ashish Reji"}</Typography>
                    <Typography className="profile-id">{"ID: 26k7"}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={9.5} sm={9.5} className="right-profile-field">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2.5} sm={2.5}>
                      <Typography className="profile-field-label">{"Interview Feedback"}</Typography>
                      <Typography className="profile-field-value">{"2/3 Received"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className="profile-field-label">{"Applied Job Code"}</Typography>
                      <Typography className="profile-field-value">{"10614"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className="profile-field-label">{"Job designation"}</Typography>
                      <Typography className="profile-field-value">{"Developer"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className="profile-field-label">{"Job grade"}</Typography>
                      <Typography className="profile-field-value">{"Lorem Ipsum"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3.5} sm={3.5}>
                      <Typography className="profile-field-label">{"Hiring manager"}</Typography>
                      <Typography className="profile-field-value">{"Ravi Shankar"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Typography className="profile-field-label">{"Documents Attached"}</Typography>
                      <Grid className="download-field">
                        <Typography className="profile-field-value">{"Resume"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                        <Typography className="profile-field-value">{"Job Description"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*----- Schedule Time Section -----*/}
            <Grid className="viewfeedback-schedule-grid">
              {viewFeedbackLists.map((item, index) => (
                <Grid className="viewfeedback-schedule-container">
                  <Grid className="dotIcon" />
                  <Grid>
                    <Typography className="user-name">{item.scheduleName}</Typography>

                    {/*----- Assigned Competencies -----*/}
                    {item.assignedCompetencies.length > 0 && (
                      <Grid>
                        <Typography className="sub-title">{"Assigned Competencies"}</Typography>
                        <div className="assigned-competencies-container">
                          {item.assignedCompetencies.map((competency, compIndex) => (
                            <div className="assigned-comp-border-div">
                              <Typography className="assigned-type">{competency.title}</Typography>
                            </div>
                          ))}
                        </div>
                      </Grid>
                    )}

                    {/*----- Feedback -----*/}
                    {item.feedback ? (
                      <Grid>
                        <Typography className="sub-title">{"Feedback"}</Typography>
                        <Typography className="feedback-description">{item.feedback}</Typography>
                      </Grid>
                    ) : (
                      <Typography className="sub-title">{"Feedback Awaiting"}</Typography>
                    )}

                    {/*----- Decision -----*/}
                    {item.decision && (
                      <Grid>
                        <Typography className="sub-title">{"Decision"}</Typography>
                        <Grid className="decision-grid">
                          {item.decision === "Strongly inclined to hire" ? <img src={StronglyInclinedIcon} alt="Decision Icon" className="decision-icon" /> : <img src={NotInclinedIcon} alt="Decision Icon" className="decision-icon" />}
                          <Typography className="decision" style={{ color: item.decision === "Strongly inclined to hire" ? "#00AA5A" : "#DB3535" }}>{item.decision}</Typography>
                        </Grid>
                      </Grid>
                    )}

                    {/*----- Horizontal Border -----*/}
                    <Grid className="horizontal-border mt-20"></Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/*----- HR My Interviews (For Interviewers) View Feedback For -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <MyInterviewsViewFeedbackFor />
        </Grid>

        {/*----- My Requisitions (For Hiring Manager) View Feedback For -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <MyRequisitionsViewFeedbackFor />
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewFeedbackFor;
