import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ProfileIcon from "../../../../assets/images/MyCareer/profileIcon.svg";
import JuniorToSeniorLevel from './components/JuniorToSeniorLevel';


const MyCareer = () => {

  const history = useHistory();

  return (
    <div className="my-career">
      <Grid container spacing={2}>

        {/*----- Profile -----*/}
        <Grid item xs={12} sm={12} md={12} className="user-profile-grid">
          <img src={ProfileIcon} alt="MediBuddy" className='user-profile' />
          <Grid>
            <Typography className="user-name">{"Kitty William"}</Typography>
            <Typography className="user-post">{"Employee"}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <JuniorToSeniorLevel />
        </Grid>
      </Grid>
    </div >
  );
};

export default MyCareer;
