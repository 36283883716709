import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import APIList from "../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import AccSkeleton from "../../../../common/akkSkeleton";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import SideDrawer from "../../../../common/sideDrawer";
import EmpProfile from "../../../../common/employeeProfile";
import { useLocation } from 'react-router-dom';

const NoFeedback = () => {

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const employeeDetails = useSelector((state) => state?.empData?.empData);   

    const [empInfo, setEmpInfo] = useState(null);
    const [empInfoLoader, setEmpInfoLoader] = useState(false);
    const drawerObj = {
        profile: false,
        info: false,
        peers: false,
        data: null,
    }
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [drawerData, setdrawerData] = useState(null);

    useEffect(() => {
        setEmpInfoLoader(true);        
      APIList.getEmployeeById({id: location?.state?.id})
      .then((res) => {
        setEmpInfo(res?.data);
        setEmpInfoLoader(false);
      })
      .catch((err) => {
        toast.error(
            <div style={{display:"flex", flexDirection:"row"}}>
              <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
              &nbsp;&nbsp;{err?.title}
            </div>
        )
        setEmpInfoLoader(false);
      });
    }, []);    
    
    const handleOpenSideDrawer = (data, toShow) => {
        
        if (toShow === "empProfile") {
            let temp = {...drawerObj, profile: true, data: data && data};
            setdrawerData(temp);
        }
        if (toShow === "peer") {
            let temp = {...drawerObj, peers: true, data: data && data};
            setdrawerData(temp);
        }
        if (toShow === "info") {
            let temp = {...drawerObj, info: true, data: data && data};
            setdrawerData(temp);
        }

        setIsOpenDrawer(true);
    };

    return(
        <div className="feedback-main">
            {
                empInfoLoader ? 
                <AccSkeleton />
                :
                <div className="feedbackAssessment m-3">
                        <div className="d-flex justify-content-between align-items-center bor-b">
                            <div className="left d-flex align-items-center gap-3">
                                {empInfo?.profilePhoto ?
                                    <img src={empInfo?.profilePhoto?.path} className="userImg"></img>
                                    :
                                    <Avatar 
                                        name={empInfo?.name} 
                                        size="75" 
                                        className="userImg"
                                        color={Avatar.getRandomColor('sitebase', ['orange', 'violet','maroon','teal','brown'])}    
                                    />
                                }
                                    <div>
                                        <p className="title">{empInfo?.name}</p>
                                        <p className="id">{empInfo?.employeeDesignation}</p>
                                    </div>
                            </div>
                            <div>                                
                            <button className="viewBtn" onClick={() => handleOpenSideDrawer(empInfo, "empProfile")}>View Profile</button>
                            {/* <button className="viewBtn">View Assesment</button> */}

                            </div>
                        </div>
                       
                       <div className="no-feedback-user">
                       No Feedback Present 
                       </div>
                        
                    </div>
            }
             
    
    

        {
            isOpenDrawer && 
            <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} >
                <div>
                    {
                        drawerData?.profile &&
                        <EmpProfile empInfo={drawerData?.data} />
                    }
                </div>
            </SideDrawer>
        }
                    
        </div>
    )
}

export default NoFeedback;