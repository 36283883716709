import React, { useState } from "react";
import { Grid, Button, Typography, Popover } from "@mui/material";
import ApplyingForLeaveModal from './Modal/ApplyingForLeaveModal';
import { FaSort } from "react-icons/fa6";
import MenuIcon from "../../../../assets/images/Leaves/menuIcon.svg";
import LeftArrow from "../../../../assets/images/leftArrow.png";
import RightArrow from "../../../../assets/images/rightArrow.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


const MyTeamLeave = () => {

  const history = useHistory();

  const [showApplyingForLeaveModal, setShowApplyingForLeaveModal] = useState(false);

  const handleApplyingForLeaveModal = () => {
    setShowApplyingForLeaveModal(true);
  };

  const handleApplyingForLeaveModalNo = () => {
    setShowApplyingForLeaveModal(false);
  };

  const [popOver, setPopOver] = useState(null);

  const handlePopOverClick = (event) => {
    setPopOver(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setPopOver(null);
  };

  const open = Boolean(popOver);
  const id = open ? 'simple-popover' : undefined;

  const myTeamLeaveHistory = [
    {
      id: 1,
      members: "Ashish Reji",
      totalLeaves: "14",
      leavesUsed: "12",
      leavesAvailable: "2",
    },
    {
      id: 2,
      members: "Akshay C",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
    {
      id: 3,
      members: "Kathryn Murphy",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
    {
      id: 4,
      members: "Wade Warren",
      totalLeaves: "14",
      leavesUsed: "12",
      leavesAvailable: "2",
    },
    {
      id: 5,
      members: "Esther Howard",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
    {
      id: 6,
      members: "Bessie Cooper",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
    {
      id: 7,
      members: "Marvin McKinney",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
    {
      id: 8,
      members: "Floyd Miles",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
    {
      id: 9,
      members: "Leslie Alexander",
      totalLeaves: "14",
      leavesUsed: "11",
      leavesAvailable: "3",
    },
  ];

  return (
    <div className="my-team-leave">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"My Teams' Leave Status"}</Typography>

          <Grid className="right-top-grid">
            <Button className="team-leaves-btn" onClick={() => history.push("/my-team-leave-history")}>{"Team Leaves"}</Button>
          </Grid>
        </Grid>

        {/*----- Table My Team Leave -----*/}
        <Grid item xs={12} sm={12} md={12} className="team-leave-table-card">
          <div className="team-leave-table">
            <table className="team-leave-list-table">
              <tr className="table-head-team-leave-list">
                <th className="pl-20">Members &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-center">Total Leaves &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-center">Leaves Used &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-center">Leaves Available &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-end pr-20">Action</th>
              </tr>

              {myTeamLeaveHistory.map((item, index) => (
                <tr>
                  <td className="pl-20">
                    <div>
                      <Typography className="leave-status-member-value">{item.members}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className="ta-center">
                      <Typography className="leave-status-no-value">{item.totalLeaves} </Typography>
                    </div>
                  </td>
                  <td>
                    <div className="ta-center">
                      <Typography className="leave-status-no-value">{item.leavesUsed}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className="ta-center">
                      <Typography className="leave-status-no-value">{item.leavesAvailable}</Typography>
                    </div>
                  </td>
                  <td className="ta-end pr-10">
                    <Button className="action-btn" onClick={handlePopOverClick}>
                      <img src={MenuIcon} alt="MenuIcon" className="menu-icon" />
                    </Button>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={popOver}
                      onClose={handlePopOverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                        sx={{
                          "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                              boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                              border:"1px solid  #e9e9e9",
                              // padding:"0.25rem",
                              fontFamily:"poppins",
                              fontSize:"0.75rem",
                              borderRadius: "10px",
                              padding: "5px 0px",
                              cursor:"pointer",
                              marginLeft:"-1rem"                                                        
                          },
                          "& .MuiPopover-paper" : {
                              boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                              border:"1px solid  #e9e9e9",
                              // padding:"0.25rem",
                              fontFamily:"poppins",
                              fontSize:"0.75rem",
                              borderRadius: "10px",
                              padding: "5px 0px",
                              cursor:"pointer",
                              marginLeft:"-1rem"                                                        
                          }
                      }}
                    >
                      <Typography onClick={handleApplyingForLeaveModal} sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, fontFamily: "poppins", cursor: "pointer", p: 2 }}>{"Apply Leave"}</Typography>
                    </Popover>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>

        {/*----- Pagination -----*/}
        <div className="table-pagination">
          <div className="d-flex">
            <img src={LeftArrow} alt="LeftArrow" className="left-right-arrow" />
            <div className="pagination-border"><Typography className="pagination-count">{"1"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"2"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"3"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"4"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"5"}</Typography></div>
            <img src={RightArrow} alt="RightArrow" className="left-right-arrow ml-10" />
          </div>
        </div>
      </Grid>

      {/*----- Apply Modal -----*/}
      {showApplyingForLeaveModal && (
        <ApplyingForLeaveModal
          showApplyingForLeaveModal={showApplyingForLeaveModal}
          handleApplyingForLeaveModalNo={handleApplyingForLeaveModalNo}
        />
      )}
    </div>
  );
};

export default MyTeamLeave;
