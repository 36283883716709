import React, { useState } from 'react';
import { Grid, Typography, Button, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import AnnualLeaves from "../../../../../assets/images/Leaves/annualLeaves.svg";
import BereavementLeave from "../../../../../assets/images/Leaves/bereavementLeave.svg";
import MaternityLeave from "../../../../../assets/images/Leaves/maternityLeave.svg";
import PublicHoliday from "../../../../../assets/images/Leaves/publicHoliday.svg";
import SickLeaves from "../../../../../assets/images/Leaves/sickLeaves.svg";
import BulletSlider from "../../../../../assets/images/Leaves/bulletSlider.svg";
import IButton from "../../../../../assets/images/Leaves/iButton.svg";
import { FaSort } from "react-icons/fa6";
import RightLeavesDrawer from "./RightDrawer/RightLeavesDrawer";
import WithdrawLeaveModal from './Modal/WithdrawLeaveModal';


const AllLeave = ({ }) => {
    const dispatch = useDispatch();

    const [stateLeave, setStateLeave] = useState({ right: false });
    const [showWithdrawLeaveModal, setShowWithdrawLeaveModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    const toggleLeaveDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setStateLeave({ right: open });
    };

    const handleWithdrawLeaveModal = (action) => {
        setModalTitle(action);
        setShowWithdrawLeaveModal(true);
    };

    const handleWithdrawLeaveModalNo = () => {
        setShowWithdrawLeaveModal(false);
    };

    const kudosList = [
        {
            id: 1,
            icon: AnnualLeaves,
            title: 'Annual Leaves',
            availableVal: '11',
            usedVal: '3',
            totalVal: '14',
            takenVal: '',
        },
        {
            id: 2,
            icon: SickLeaves,
            title: 'Sick Leaves',
            availableVal: '11',
            usedVal: '3',
            totalVal: '14',
            takenVal: '',
        },
        {
            id: 3,
            icon: MaternityLeave,
            title: 'Maternity Leave',
            availableVal: '',
            usedVal: '',
            totalVal: '',
            takenVal: '3',
        },
        {
            id: 4,
            icon: BereavementLeave,
            title: 'Bereavement Leave',
            availableVal: '',
            usedVal: '',
            totalVal: '',
            takenVal: '3',
        },
        {
            id: 5,
            icon: PublicHoliday,
            title: 'Public Holiday',
            availableVal: '',
            usedVal: '',
            totalVal: '',
            takenVal: '3',
        },
        {
            id: 6,
            icon: PublicHoliday,
            title: 'Family Care Leave',
            availableVal: '11',
            usedVal: '',
            totalVal: '',
            takenVal: '',
        },
    ];

    const myLeaveHistory = [
        {
            id: 1,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "2",
            leaveType: "Annual Leave",
            manager: "Ashish Reji",
            status: "Auto Approved",
            action: "",
        },
        {
            id: 2,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Sick Leave",
            manager: "Akshay C",
            status: "Auto Approved",
            action: "",
        },
        {
            id: 3,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Family Care Leave",
            manager: "Akshay C",
            status: "Approved",
            action: "Withdraw",
        },
        {
            id: 4,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "2",
            leaveType: "Family Care Leave",
            manager: "Ashish Reji",
            status: "Pending",
            action: "Cancel",
        },
        {
            id: 5,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Family Care Leave",
            manager: "Akshay C",
            status: "Declined",
            action: "",
        },
        {
            id: 6,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Maternity Leave",
            manager: "Akshay C",
            status: "Approved",
            action: "",
        },
        {
            id: 7,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Bereavement Leave",
            manager: "Akshay C",
            status: "Approved",
            action: "",
        },
        {
            id: 8,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Annual Leave",
            manager: "Akshay C",
            status: "Auto Approved",
            action: "",
        },
        {
            id: 9,
            requestDate: "20 Nov. 2024",
            leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
            noOfDays: "3",
            leaveType: "Annual Leave",
            manager: "Akshay C",
            status: "Auto Approved",
            action: "",
        },
    ];

    return (
        <Grid>
            <Grid container className="top-slider-card">
                <Grid item xs={12} sm={12} md={12} className="top-slider-container">
                    <Grid container direction="row" wrap="nowrap" className="top-slider-scroll">
                        {kudosList.map((item, index) => (
                            <div className="top-slider-item-div">
                                <Grid key={index} item className="top-slider-item">
                                    <Grid className="sub-item">
                                        <Grid>
                                            <Typography className="sub-item-title" onClick={toggleLeaveDrawer(true)}>{item.title}</Typography>
                                            {item.availableVal !== '' &&
                                                <Grid className="leaves-grid">
                                                    <Typography className="sub-title">{"Available"}</Typography>
                                                    <Typography className="sub-title">{"-"}</Typography>
                                                    <Typography className="sub-title">{item.availableVal}</Typography>
                                                </Grid>
                                            }

                                            {item.usedVal !== '' &&
                                                <Grid className="leaves-grid">
                                                    <Typography className="sub-title">{"Used"}</Typography>
                                                    <Typography className="sub-title">{"-"}</Typography>
                                                    <Typography className="sub-title">{item.usedVal}</Typography>
                                                </Grid>
                                            }

                                            {item.totalVal !== '' &&
                                                <Grid className="leaves-grid">
                                                    <Typography className="sub-title">{"Total"}</Typography>
                                                    <Typography className="sub-title">{"-"}</Typography>
                                                    <Typography className="sub-title">{item.totalVal}<Tooltip arrow title="10 Current + 4 Carry forwarded *Requires Manager Approval"><img src={IButton} alt="IButton" className="i-button" /></Tooltip></Typography>
                                                </Grid>
                                            }

                                            {item.takenVal !== '' &&
                                                <Grid className="leaves-grid">
                                                    <Typography className="sub-title">{"Taken"}</Typography>
                                                    <Typography className="sub-title">{"-"}</Typography>
                                                    <Typography className="sub-title">{item.takenVal}</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid>
                                            <img src={item.icon} alt="Slider" className="calendar-icon" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </Grid>
                <img src={BulletSlider} alt="Slider" className="bullet-icon" />
            </Grid>

            <Grid container className="my-leave-history">
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className="table-title">{"My Leave History"}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="table-card">
                    <div className="leave-history-table">
                        <table className="history-list-table">
                            <tr className="table-head-history-list">
                                <th className="w-180 pl-20">Request Date &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-500">Leave Duration &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-180 ta-center">No. of Days &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-180">Leave Type &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-180 ta-center">Manager &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="w-180 ta-center">Status &nbsp;<FaSort className="c-pointer" /></th>
                                <th className="ta-end pr-20">Action</th>
                            </tr>

                            {myLeaveHistory.map((item, index) => (
                                <tr>
                                    <td className="pl-20">
                                        <div className="w-180">
                                            <Typography className="leave-history-table-value">{item.requestDate}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <Typography className="leave-history-table-value">{item.leaveDuration} </Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ta-center w-180">
                                            <Typography className="leave-history-table-value">{item.noOfDays}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="w-180">
                                            <Typography className="leave-history-table-value">{item.leaveType}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ta-center w-180">
                                            <Typography className="manager-table-value">{item.manager}</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="ta-center w-180">
                                            <Tooltip 
                                                arrow 
                                                title={
                                                    item.status === 'Declined' ? (
                                                        <div>
                                                            <Typography style={{color: "#FFFFFF80", fontSize: "10px", lineHeight: "15px", fontWeight: 400, fontFamily: "poppins" }}>
                                                                {"Manager Note:"}
                                                            </Typography>
                                                            <Typography style={{color: "#FFFFFF", fontSize: "12px", lineHeight: "18px", fontWeight: 400, fontFamily: "poppins" }}>
                                                                {"Too many leave taken, apply after a month."}
                                                            </Typography>
                                                        </div>
                                                    ) : ''
                                                }
                                                disableHoverListener={item.status !== 'Declined'}>
                                                <Typography
                                                    className="status-table-value"
                                                    style={{
                                                        color: item.status === 'Approved' || item.status === 'Auto Approved'
                                                            ? '#00AA5A'
                                                            : item.status === 'Pending'
                                                                ? '#FF9900'
                                                                : item.status === 'Declined' ? '#DB3535' : 'inherit',
                                                                cursor: item.status === 'Declined' ? 'pointer' : 'inherit',
                                                    }}
                                                >
                                                    {item.status}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td className="ta-end pr-20">
                                        {item.action !== '' &&
                                            <Button className="action-btn" onClick={() => handleWithdrawLeaveModal(item.action)}>
                                                {item.action}
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </Grid>
            </Grid>

            {/*----- Right Leaves Drawer -----*/}
            <RightLeavesDrawer
                anchorRightLeaveDrawer="right"
                openLeaveRight={stateLeave.right}
                onCloseRightLeaveDrawer={toggleLeaveDrawer(false)}
            />

            {/*----- Apply Modal -----*/}
            {showWithdrawLeaveModal && (
                <WithdrawLeaveModal
                    showWithdrawLeaveModal={showWithdrawLeaveModal}
                    handleWithdrawLeaveModalNo={handleWithdrawLeaveModalNo}
                    modalTitle={modalTitle}
                />
            )}
        </Grid>
    );
};

export default React.memo(AllLeave);
