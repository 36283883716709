import React from 'react';
import { Dialog, Button, Grid, Typography } from '@mui/material';


const WithdrawLeaveModal = ({ showWithdrawLeaveModal, handleWithdrawLeaveModalNo, modalTitle }) => {

    return (
        <Dialog
            open={showWithdrawLeaveModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                    width: "450px",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="withdraw-leave-modal">
                {/*----- Modal Title -----*/}
                <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "10px" }}>
                    {modalTitle === "Withdraw" ?
                        <Typography className="modal-heading">{"Are you sure to Withdraw this leave?"}</Typography> : <Typography className="modal-heading">{"Are you sure to Cancel this leave?"}</Typography>
                    }
                </Grid>

                {/*----- Family Care Leave -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Grid className="family-care-leave-grid">
                        <Typography className="family-care-leave">{"Family Care Leave"}</Typography>
                        <Typography className="date-days">{"18 Dec. 2024 - 20 Dec. 2024 (3 Days)"}</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} sm={12} className="action-withdraw-leave-grid">
                    <Button className="cancel-btn" onClick={handleWithdrawLeaveModalNo}>{"Cancel"}</Button>
                    <Button className="yes-btn">{"Yes"}</Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default WithdrawLeaveModal;