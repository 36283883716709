import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import pencile from "../../../../assets/images/pencile.png";
import plusIcon from "../../../../assets/images/plusIcon.png";
import bluedot from "../../../../assets/images/blueDot.png";
import downIcon from "../../../../assets/images/downArrow.png";

const Certifications = ({ }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {/*----- Certifications 3 Certifications -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading">Certifications <span> | 3 Certifications</span></Typography>
          <div className="d-flex">
            <img src={plusIcon} alt="MediBuddy" />
            <img className="padding-left" src={pencile} alt="MediBuddy" />
          </div>
        </div>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">{"Chartered Financial Analyst"}</Typography>
                  <Typography className="certification-description">{"Chartered Financial Analyst program, CFA"}</Typography>
                  <Typography className="certification-date-level">{"Level 3"}</Typography>
                </div>
              </div>

              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">{"Project Management Professional (PMP)"}</Typography>
                  <Typography className="certification-description">{"Project Management Institute (PMI)"}</Typography>
                  <Typography className="certification-date-level">{"Level 1"}</Typography>
                </div>
              </div>

              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">{"FRM (Financial Risk Manager)"}</Typography>
                  <Typography className="certification-description">{"GARP (Global Association of Risk Professionals)"}</Typography>
                  <Typography className="certification-date-level">{"Level 1"}</Typography>
                </div>
              </div>
              {/* <div class="wrap">
                <div class="dropdown readMore">
                  <button class="dropbtn">See More &nbsp;&nbsp;<img src={downIcon} alt="MediBuddy" /></button>
                  <div class="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                    <a href="#">Link 3</a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/*----- Trainings -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading">Trainings <span> | 2 Training</span></Typography>
          <div>
            <img src={plusIcon} alt="MediBuddy" />
            <img className="padding-left" src={pencile} alt="MediBuddy" />
          </div>
        </div>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">{"Product Promotion and operations Management"}</Typography>
                  <Typography className="certification-description">{"at Stanford University"}</Typography>
                  <Typography className="certification-date-level">{"Aug 2023 - Sep 2023"}</Typography>
                </div>
              </div>

              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">{"Project Management Framework and Tools"}</Typography>
                  <Typography className="certification-description">{"at Stanford University"}</Typography>
                  <Typography className="certification-date-level">{"May 2021 - Jun 2021"}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Certifications);
