import React, { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify/dist";
import { BsCircleFill } from "react-icons/bs";
import APIList from "../../../api";
import { actions as NotificationActions } from "../../../redux/notification/actions";
import { isEmptyNullUndefined } from "../../utils/utils";

const Notification = () => {
  const dispatch = useDispatch();

  const employeeDetails = useSelector((state) => state?.empData?.empData);

  const notificationData = useSelector(
    (state) => state?.notificationReducer?.notification
  );
  const count = useSelector((state) => state?.notificationReducer?.count);
  const loader = useSelector((state) => state?.notificationReducer.loader);

  const [allNotification, setAllNotification] = useState([]);
  const [checked, setChecked] = useState(false);
  const [readState, setReadState] = useState(null);

  useEffect(() => {
    if (!loader) {
      if (notificationData.length > 0) {
        const sortNotifications = notificationData.sort(function (a, b) {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        setAllNotification(sortNotifications);
      } else {
        setAllNotification([]);
      }
    }
  }, [loader, notificationData]);

  useEffect(() => {
    const value = allNotification.every((val) => {
      return val.read == true;
    });
    setReadState(value);
  }, [allNotification]);

  const markRead = () => {
    let id = [];

    allNotification.forEach((value) => {
      id.push(value.id);
    });

    updateNotification(id);
  };

  const getNotification = (typeOf) => {
    if(!isEmptyNullUndefined(employeeDetails?.id)){
    let obj = {
      type: typeOf,
      employeeId: employeeDetails?.id,
    };
    dispatch(NotificationActions.NotificationDataRequest(obj));
  }
  };

  useEffect(() => {
    if (!checked) {
      getNotification("ALL");
    } else {
      getNotification("UNREAD");
    }
  }, [checked]);

  const updateNotification = async (idArray) => {
    let mainArray = [...idArray];

    APIList.updateNotifications({
      ids: mainArray,
    })
      .then((res) => {
        getNotification(!checked ? "ALL" : "UNREAD");
      })
      .catch((err) => {
        toast.error(
          <div style={{ display: "flex", flexDirection: "row" }}>
            <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
            &nbsp;&nbsp;{err?.title}
          </div>
        );
      });
  };

  return (
    <div>
      {/* <p className="main-heading">Notifications({allNotification.length})</p> */}
      <p className="main-heading">Notifications({count})</p>
      <div className='filter-div'>
        <div className="only-show-unread">
            <input
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label  style={{ marginLeft: '10px' }}>
            Only show unread
          </label>
        </div>
  
        <div>
          {!checked && !loader && (
            <p
              onClick={markRead}
              // style={{ cursor: "pointer", color: "#1976d2" }}
              className="mark-as-read"
            >
              Mark all as read
            </p>
          )}
        </div>
      </div>

      { allNotification.length > 0 ? allNotification.map((notification) => (
        <div
          key={notification.id}
          // style={{ cursor: "pointer" }}
          className="notification-div"
          onClick={() => {
            if (!notification?.read) {
              updateNotification([notification.id]);
            }
          }}
        >
          <div className="dot">
            {!notification?.read ? (
              <BsCircleFill style={{ color: "#1976d2" }} />
            ) : (
              <BsCircleFill style={{ color: "transparent" }} />
            )}
          </div>
          <div>
            <p className="title">{notification.title}</p>
            <p
              className="message"
              dangerouslySetInnerHTML={{ __html: notification.message }}
            />
            <p className="date">
              {formatDistanceToNow(new Date(notification.createdDate), {
                addSuffix: true,
              })}
            </p>
          </div>
        </div>
      ))
    :
    <p className="empty-msg">That's all your notifications from the last 30 days</p>
    }
    </div>
  );
};

export default Notification;
