import { InputBase, alpha, styled } from "@mui/material";
import moment from "moment";

// export const getPlainText = (htmlString) => {
  
//   const tempDiv = document.createElement("div");
//   tempDiv.innerHTML = htmlString;
  
//   return tempDiv.textContent.trim();
// };
export const getPlainText = (htmlString) => {
 
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  
  return tempDiv.textContent.replace(/\s+/g, '').trim();
};

export const isEmptyNullUndefined = (value) => {
  if (
    value === undefined ||
    value === "" ||
    (value && value.toString().trim() === "") ||
    value === null 
  ) {
    return true;
  } else {
    return false;
  }
};

export function CurrencyFormat(amount,currency) {
  if(amount == null){
    return "";
  }else{
    if (currency === "INR") {
      return `${parseFloat(amount).toLocaleString("en-IN")}`;
    } else {
      return `${parseFloat(amount).toLocaleString("en-US")}`;
    }
  }
  };

export const textAreaStyle = {
  width:"100%",
  // my: 4,
  mt: 2,
  backgroundColor:"#F9F9F9",
  borderRadius:"1rem",
  "& fieldset": {
      border: "1px solid #F9F9F9",
      borderRadius:"1rem",                                    
    },
};

export const ratings = ["Outstanding", "Fantastic", "Average", "Poor"];

export const values = ["value1", "value2", "value3", "value4", "value5", "value6", "value7", "value8", "value9", "value10", "value11", "value12"];

export const valuesFeedback = ["Customer Obsession", "Speed", "End to End Ownership", "Execution Excellence", "Hire & Develop the Best", "Think Big, Think Long Term", "Invent & Simplify to Do More with Less", "Dive Deep, Be Hands On", "Be Bold, Disagree & Commit", "Pride in the Purpose of Our Work","Earn Trust & Be Vocally Self Critical","Make High Quality Decisions"];
export const myAssesmentvalues = ["Customer Obsession", "Speed", "End to End Ownership", "Execution Excellence", "Hire & Develop the Best", "Think Big, Think Long Term", "Invent & Simplify to Do More with Less", "Dive Deep, Be Hands On", "Be Bold, Disagree & Commit", "Pride in the Purpose of Our Work", "Earn Trust & Be Vocally Self Critical", "Make High Quality Decisions"];

export const truncatePath = (url) => {
  // this will replace /manager-feedBack/8278de5d-ac6f-4b89-96bd-f89e05131a51 into --> /manager-feedBack
  const temp = JSON.parse(JSON.stringify(url))

  const lastSlashIndex = temp.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
    // temp.substring(0, lastSlashIndex);
    temp.slice(0, lastSlashIndex);
    if(temp.substring(0, lastSlashIndex)) {
      return temp.substring(0, lastSlashIndex)
    } else {
      return url
    }
  }
};

export const getHeaderTitle = () => {

  const path = window.location.pathname;

  if (path === "/self-assesment/allinone") {
    return "My Assessment";
  } else if (path === "/profile") {
    return "My Profile";
  }else if (path === "/dashboard") {
    return "Dashboard";
  }else if (path === "/my-team") {
    return "My Team";
  }else if (path === "/change-password") {
    return "Change Password";
  }else if (path?.includes("emp-feedback")) {
    return "Feedback";
  } else if (path === "/self-assesment/request-feedback") {
    return "Request Feedback";
  } else if (path === "/give-peer-feedback") {
    return "Give Feedback";
  } else if (path === "/add-session") {
    return "Add Session";
  } else if (truncatePath(path) === "/peer-feedBack") {
    return "Peer FeedBack";
  } else if (truncatePath(path) === "/manager-feedBack") {
    return "Manager FeedBack";
  } else if (path === "/hrb") {
    return "Rating Calibration";
  } else if (path === "/self-assesment/aprove") {
    return "Approve 360";
  } else if (path === "/Compensation-analytics") {
    return "Compensation Analytics";
  } 
  else if (path === "/compensation") {
    return "Compensation";
  } 
    else if (path === "/employee-goals") {
      return "My Goals";
    } 
    else if (path === "/360-report") {
      return "360 Report";
    }
    else if (path === "/my-team-page") {
      return "My Team";
    }
    else if (path === "/hr-succession-plan" || path === "/succession-plan") {
      return "Succession Plan";
    } 
    else if (path === "/compensation-team") {
      return "My Team Compensation";
    } 
    else if (path === "/my-team-promotion") {
      return "Promotions";
    }
    else if (path === "/view-candidate-in-compensation") {
      return "Planning";
    }
    else if (path === "/individual-development-plan") {
      return "My Profile";
    }
    else if (path === "/employee-engagement-program") {
      return "My Profile";
    }
    else if (path === "/about-me/my-career") {
      return "Employee Development";
    }
    else if (path === "/my-leave") {
      return "My Leave";
    }
    else if (path === "/my-team-leave") {
      return "My Team Leave";
    }
    else if (path === "/my-team-leave-history") {
      return "My Team Leave";
    }
  else {
    return "";
  }
 
};

export const getHeaderMidTitle = () => {
  const path = window.location.pathname;
  if (path?.includes("emp-feedback")) {
    return "Mid year MBSR Check-in";
  } else {
    // return "H1 2024 MBSR";
    return "Mid year MBSR Check-in";
  }
}

export const accordianSVGstyle = {
  borderRadius:"1rem", 
  backgroundColor:"#ffffff606060", 
  color:"#606060", 
  fontSize:"1.5rem", 
  padding:"0.1rem", 
  margin:"auto 0px",
  transitionDuration:"700ms"
};

export const accordianSVGstyle1 = {
  borderRadius:"1rem", 
  backgroundColor:"#ffffff606060", 
  color:"rgba(0, 66, 90, 1)", 
  fontSize:"19px", 
  padding:"0.1rem", 
  margin:"auto 0px auto 7px",
  transitionDuration:"700ms",
  transform: "rotate(270deg)",
};

export const getFormattedDate = (date, format) => {
  return moment(date).format(format?.toUpperCase());
};

export const getFormattedTime = (date, format) => {
  return moment(date).format(format);
};

function capitalizeEveryWord(str) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words back into a string
  const result = capitalizedWords.join(' ');

  return result;
};

export function breakCamelCase(str) {
  // Use a regular expression to find all uppercase letters and insert a space before them
  return capitalizeEveryWord(str.replace(/([A-Z])/g, ' $1'));
};

export const getListItemDescription = (selectList, optionList, fieldName) => {
  const discriptionList = [];
  if (selectList.length > 0) {
    selectList?.map((item) => {
      let toCompare;
      if (fieldName == 'planName') {
        toCompare = typeof item === 'object' ? item?.plan?.id : item;
      } else {
        toCompare = typeof item === 'object' ? item?.id : item;
      }

      const option = optionList.find((option) => option.id === toCompare);

      // fieldName=="planName" && console.log("tocompare",toCompare , optionList,item);
      if (option != undefined) {
        discriptionList.push(option["name"]);
      }
    });
  }
  return discriptionList.toString();
};

export const assesmentDummyData = {
  "approvalDate": "2023-11-16",
  "employeeId": 1,
  "goalList": [
    {
      "id": "AP1G1",
      "name": "string",
	  comments: []
    },
    {
      "id": "AP1G2",
      "name": "string",
	  comments: []
    }
  ],
  "id": "AP01",
  "status": "string",
  "submittedDate": "2023-11-16",
  "values": 
    {
      "challenges": "string challenges",
      "demonstrated": "string",
      "demonstratedValues": [
        "string","string2"
      ],
      "keyAchievement": "string keyAchievement",
      "learningSupport": "string learningSupport",
      "notDemonstrated": "string",
      "notDemonstratedValues": [
        "stringN"
      ]
    }
}

export const onlyAcceptWholeNumbers = (number) => {
  return number.replace(/[^0-9]/g, '');
};

export const onlyAcceptOneToNineWithDecimal = (number) => {
  return number.replace(/[^0-9.]|(?<=\..*)\./g, '');
};

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  margin:"1rem 0px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: '0px !important',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex:1 ,
  color:"#979797"
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  // border:"1px solid var(--primary)",
  // boxShadow:"0px 0px 6px 1px #e1e1e1",
  height: "3.7rem !important",
  backgroundColor: "#ffffff",
  borderRadius:"10px",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create('width'),
    width: '15rem',
    // minWidth:"5rem"
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch',
    //   },
    // },
  },
}));  

export const splitContentIntoPages = (content, pageHeight) => {
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style.width = '100%'; // Ensure it matches the width of your PDF
  container.innerHTML = content;
  document.body.appendChild(container);

  const pages = [];
  let currentPageContent = '';
  let currentPageHeight = 0;

  const getElementHeight = (element) => {
    const clone = element.cloneNode(true);
    container.innerHTML = '';
    container.appendChild(clone);
    return clone.scrollHeight;
  };

  const processNode = (node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const nodeHeight = getElementHeight(node);
      if (currentPageHeight + nodeHeight > pageHeight) {
        if (currentPageContent) {
          pages.push(currentPageContent);
          currentPageContent = '';
          currentPageHeight = 0;
        }
      }
      currentPageContent += node.outerHTML;
      currentPageHeight += nodeHeight;
    }
  };

  Array.from(container.childNodes).forEach(processNode);

  if (currentPageContent) {
    pages.push(currentPageContent);
  }

  document.body.removeChild(container);

  // Add padding to the last page if needed
  if (pages.length > 0) {
    const lastPageContent = pages[pages.length - 1];
    const lastPageContainer = document.createElement('div');
    lastPageContainer.style.position = 'absolute';
    lastPageContainer.style.visibility = 'hidden';
    lastPageContainer.style.width = '100%';
    lastPageContainer.innerHTML = lastPageContent;
    document.body.appendChild(lastPageContainer);

    const lastPageHeight = lastPageContainer.scrollHeight;
    const remainingSpace = pageHeight - lastPageHeight;

    if (remainingSpace > 0) {
      pages[
        pages.length - 1
      ] += <div style="height: ${remainingSpace}px;"></div>;
    }

    document.body.removeChild(lastPageContainer);
  }

  return pages;
};

export const getObjectById = (array, id) => {
  if (isEmptyNullUndefined(array) || isEmptyNullUndefined(id)) {
    return;
  } else {
    return array.find((item) => item.id === id);
  }
};