import React, { useState } from 'react';
import { Dialog, Button, Grid, Typography, TextField, InputAdornment, IconButton, Select, MenuItem } from '@mui/material';
import DownArrowIcon from "../../../../../assets/images/Leaves/downArrow.svg";
import DateIcon from "../../../../../assets/images/Leaves/dateIcon.svg";
import UserProfileIcon from "../../../../../assets/images/Leaves/userProfile.png";


const DecliningLeaveForModal = ({ showDecliningLeaveForModal, handleDecliningLeaveForModalNo }) => {

    const [leaveType, setLeaveType] = useState("Annual");

    const handleChange = (event) => {
        setLeaveType(event.target.value);
    };

    const DownArrowIconComponent = () => (
        <img src={DownArrowIcon} alt="Down Arrow" style={{ width: "1em", height: "1em", marginRight: "15px" }} />
    );

    return (
        <Dialog
            open={showDecliningLeaveForModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                    width: "600px",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="declining-leave-for-modal">
                {/*----- Modal Title -----*/}
                <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "10px" }}>
                    <Typography className="modal-heading">{"Declining Leave for"}</Typography>

                    <Grid className="top-profile">
                        <Grid className="profile-grid">
                            <img src={UserProfileIcon} alt="UserProfileIcon" className="user-profile-icon" />
                            <Grid>
                                <Typography className="user-name">{"Ashish Reji"}</Typography>
                                <Typography className="user-id">{"ID: 26k7"}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6}>
                                <Typography className="profile-field-label">{"Leave Date"}</Typography>
                                <Typography className="profile-field-value">{"18 Dec. 2024 - 20 Dec. 2024"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sm={3}>
                                <Typography className="profile-field-label">{"Leave Duration"}</Typography>
                                <Typography className="profile-field-value">{"2 Days"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} sm={3}>
                                <Typography className="profile-field-label">{"Leave Type"}</Typography>
                                <Typography className="profile-field-value">{"Annual Leave"}</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


                <Grid container spacing={2}>
                    {/*----- Reason for leave -----*/}
                    <Grid item xs={12} md={12} sm={12}>
                        <Typography className="field-label">{"Reason for leave"}</Typography>
                        <TextField
                            name="reasonForLeave"
                            placeholder="Going Goa for a vacation"
                            variant="outlined"
                            size="small"
                            sx={{
                                width: '100%',
                                backgroundColor: "#FFFFFF",
                                "& fieldset": {
                                    borderRadius: '8px',
                                },
                            }}
                            multiline
                            minRows={2}
                        />
                    </Grid>

                    {/*----- Enter Reason for Decline -----*/}
                    <Grid item xs={12} md={12} sm={12}>
                        <Typography className="field-label mt-15">{"Enter Reason for Decline"}</Typography>
                        <TextField
                            name="reasonForLeave"
                            placeholder="Message"
                            variant="outlined"
                            size="small"
                            sx={{
                                width: '100%',
                                backgroundColor: "#FFFFFF",
                                "& fieldset": {
                                    borderRadius: '8px',
                                },
                            }}
                            multiline
                            minRows={3}
                        />

                        {/*----- Note -----*/}
                        <Typography className="leave-note">{"Note - Once leave decision is taken, it can not be revoked."}</Typography>
                    </Grid>

                    {/*----- Cancel & Decline Button -----*/}
                    <Grid item xs={12} md={12} sm={12} className="action-grid">
                        <Button className="cancel-btn" onClick={handleDecliningLeaveForModalNo}>{"Cancel"}</Button>
                        <Button className="decline-btn">{"Decline"}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default DecliningLeaveForModal;