import api, { AUTHENTICATE_URL, axiosInstanceCms, axiosInstanceNotification, axiosInstanceWorkFlow, axiosInstanceAcc } from "./apiServices";

/// Learning page medical Insurance api used in basics component
const getLearningPageData = (planId, roleId) => axiosInstanceCms.get(`/plan/applicable-tile?planId=${planId}&roleId=${roleId}`)

const authenticateUser = (payload) => api.post(`/authenticate`, payload);

const logout = (payload) => api.post(`${AUTHENTICATE_URL}logout?token=${payload?.token}`);

const getEmployeeData = () => api.get(`/employees/email`);

const getAccount = () => axiosInstanceAcc.get(`/account`);

const getDropdownData = (payload) => api.get(`/company/dropdowns/${payload?.companyID}`);

const getDynamicSearch = (payload) => api.get(`/employees/search/appraisal-status/by-manager/${payload?.companyID}/${payload?.managerId}/?page=${payload?.page}&size=${payload?.size}&keyword=${payload?.keys}`);

const getFilteredData = (payload) => api.post(`/employees/eligible-employee-by-manager/${payload?.companyId}/${payload?.managerId}?page=${payload?.page}&size=${payload?.size}`, payload?.payload); 

const getEmployeeSelfApraisalData = (payload) => api.get(`/self-appraisals/by-employee/${payload?.id}`);

const createSelfApraisal = (payload) => api.post(`/self-appraisals`, payload);   

const editSelfApraisal = (payload) => api.put(`/self-appraisals`, payload);  

const getApraisalStatusData = (payload) => api.get(`/employees/appraisal-status/by-manager/${payload?.id}`); 

const getApraisalStatusDataPaginated = (payload) => api.get(`/employees/appraisal-status/by-manager/paginated/${payload?.id}?page=${payload?.page}&size=${payload?.size}`); 

const getEmployeeById = (payload) => api.get(`/employees/${payload?.id}`);

const getFeedbackById = (payload) => api.get(`/feedback/employee/${payload?.id}?name=${payload?.type}`); 

const getFeedbackSelfApraisalData = (payload) => api.get(`/feedback/selfAppraisal/${payload?.id}`);

const getMyTeam = (payload) => api.post(`/employees/search?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getMyTeamDropdowns = (payload) => api.post(`/company/myTeam/filters`, payload);

const getMyTeamGrouped = (payload) => api.post(`/employees/grouped-search?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getNewMyTeamGrouped = (payload) => api.post(`/employees/myTeam/filter?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const get_dashboard_graphs = (payload) => api.post(`/dashboard/graphs/`, payload?.payload);

const getEntireOrgEmpSubTeam = (payload) => api.get(`/employees/entierOrg/subEmployees/${payload?.employeeId}/${payload?.companyId}`);

const createFeedback = (payload) => api.post(`/feedback`, payload);  

const editFeedback = (payload) => api.put(`/feedback/${payload?.id}`, payload);  

// const fileUpload = (payload , headers) => api.post(`/file/upload`, payload , {headers});  

const fileUpload = (payload , headers, companyName, folderName ) => api.post(`/file/upload-by-company-and-folder?companyName=${companyName}&folder=${folderName}`, payload , {headers}); 

const changeProfilePhote = (payload , empId) => api.put(`/employees/profile-photo/${empId}`, payload);  

const getQuestions = (payload) => api.get(`/company/get-questions-and-values/${payload?.companyID}`);  

const changePassword = (payload) => api.post(`/account/change-password`, payload);

const forgotPassword = (payload) => api.post("/account/reset-password/init", payload);

const resetPassword = (payload) => api.post("/account/reset-password/finish", payload);

const getAssessmentResponse = (payload) => api.get(`/assessment-response-new/by-formfor-and-employee/${payload.selectedFormFor}/${payload.selectedEmployeeId}`);

const getAssessment = (payload) => api.get(`/assessment-new/by-formfor-and-company/${payload.selectedFormFor}/${payload.selectedCompanyId}?status=ACTIVE`);

const postAssessmentResponse = (payload) => api.post(`/assessment-response-new`, payload);

const postgetFeedbackByRequest = (payload) => api.post(`/assessment-response/get-feedback-by-request`, payload);

const putAssessmentResponse = (payload) => api.put(`/assessment-response-new/${payload.responseFormId}`, payload.data);

const getImmediateTeam = (payload) => api.post(`/employees/immediate-team?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getImmediateTeamSearch = (payload) => api.get(`/employees/search-by-name-or-email/${payload?.empId}?keyword=${payload?.keyword}`);

const sendFeedbackRequest = (payload) => api.post(`/assessment-response/feedback-request`, payload);

const getAllSentRequest = (payload) => api.post(`/assessment-response/feedback-request/sent-request?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortOrder=${payload?.sortOrder}`, payload?.payload);

const recallFeedbackRequest = (payload) => api.put(`/assessment-response/feedback-request/recall/${payload?.requestId}`);

const getFilteredSearchPeers = (payload) => api.post(`/employees/peers-search?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getFilteredSearchPeersfeed = (payload) => api.post(`/employees/peers-search-feedback-request?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getCohostSearchPeers = (payload) => api.post(`/employees/notDataAccessFilter/peers-search?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const getFeedbackRequests = (payload) => api.post(`/assessment-response/feedback-request/recieved-request?page=${payload?.page}&size=${payload?.size}&sortBy=${payload?.sortBy}&sortOrder=${payload?.sortOrder}`, payload?.payload);

const declineFeedbackRequests = (payload) => api.put(`/assessment-response/feedback-request/decline/${payload?.requestId}`);

const getSentRequestCount = (payload) => api.get(`/assessment-response/feedback-requests/counts/submitted-by/${payload?.id}`);

const getReceivedRequestCount = (payload) => api.get(`/assessment-response/feedback-requests/counts/recieved-by/${payload?.id}`);

const getDropdownsfForCohort = (payload) => api.get(`/company/dropdowns/range/${payload?.companyId}/${payload?.range}`, payload);

const getDropdownsfForAudit = (payload) => api.get(`/company/dropdowns/range/${payload?.companyId}/audit`);

const createSession = (payload) => api.post(`/session`, payload);

const editSession = (payload) => api.put(`/session`, payload);

const getAllSession = (payload) => api.get(`/session?organiserId=${payload?.empId}&status=${payload?.status}&sessionStatus=${payload?.sessoinStatus}&page=${payload?.page}&size=${payload?.size}&searchValue=${payload?.search}`);

const getAllsessionByParticipant = (payload) => api.get(`/session/by-participant?participantId=${payload?.empId}&page=${payload?.page}&size=${payload?.size}&companyId=${payload?.companyId}`);

const getDoneSession = (payload) => api.get(`/session/done?organiserId=${payload?.empId}&companyId=${payload?.companyId}&page=${payload?.page}&size=${payload?.size}&searchValue=${payload?.search}`);

const getSessionById = (payload) => api.get(`/session/${payload?.id}`);

const getElligibleForPromotion = (payload) => api.get(`/session/cohort-changes/eligible/${payload?.id}`);

const approvePromotions = (payload) => api.post(`/session/promotion`, payload);

const getCountOnlySessionById = (payload) => api.get(`/session/basic/${payload?.id}`);

const getSessionReportData1 = (payload) => api.post(`/session/cohort-report/${payload?.companyId}/${payload?.sessionId}`,payload);

const getSessionReportData = (payload) => api.post(`/session/cohort-report/filtered/${payload?.sessionId}`,payload);

const saveSessionReportDataForTable = (payload) => api.post(`/session/changes/${payload?.sessionId}?status=${payload?.status}`,payload?.payload);

// const getAuditReportData = (payload) => api.get(`/session/cohort-report/review/${payload?.companyID}/${payload?.sessionId}`);
const getAuditReportData = (payload) => api.post(`/session/cohort-report/review/${payload?.sessionId}`, payload);

const deleteSession = (payload) => api.delete(`/session/${payload?.id}`);

const getAllPeer = (payload) => api.get(`/assessment-response/peer-feedbacks/for-employee/${payload}`);

const getFeedbackRequstById = (payload) => api.get(`/assessment-response/feedback-request/ID/${payload}`);

const getPeerfeedbackByRequest = (payload) => api.get(`/assessment-response-new/get-feedback-by-request/${payload}`);

const getAllCounts = (payload) => api.get(`/assessment-response/feedback-requests/all/counts/employeeId//${payload?.id}`);

const getRatingHistory = (payload) => api.get(`/ratings-history/${payload}`);

const getAllActivepmsCycle = (companyId) => api.get(`/pms-cycle/company/${companyId}?status=ACTIVE`);

// http://dev-admin.benevolve.com/services/benevolvemedibuddy/api/annualReviewCycle/0c6972bc-19ee-4913-9f99-3781d53e8a95/ACTIVE

const getAllActiveannualReviewCycle = (companyId) => api.get(`/annualReviewCycle/${companyId}/ACTIVE`);

const getInfoData = (payload) => api.get(`/infoContents/by-page/${payload}`);

const getInfoDataByPageNameAndCompanyId = (companyId,pageName) => api.get(`/infoContents/${companyId}/${pageName}`);

const getDashboardData = (payload) => api.get(`/dashboard/managerID/${payload?.id}`);

// const getNewDashboardData = (payload) => api.get(`/dashboard/managerID-and-range/${payload?.id}/${payload?.range}`);
const getNewDashboardData = (payload) => api.post(`/dashboard/filter`, payload);

// http://dev-portal.benevolve.com/services/workflow/api/work/flow/definition/employee/5032ffad-520c-452b-bcb3-7c9b8185ec55
const workFlowDefinitionEmployee = (employeeId) => axiosInstanceWorkFlow.get(`/work/flow/definition/employee/${employeeId}`);

// http://localhost:9095/api/work/flow/definition/employee/c76082b5-7769-4588-abd9-6d93492e41ce/1ab2f5c3-568e-4256-92fe-32380bbbf6da/pms_cycle

const workFlowDefinitionEmployeeByPmsCycle = (payload) => axiosInstanceWorkFlow.get(`/work/flow/definition/employee/${payload.employeeId}/${payload.pmsCycle}/pms_cycle`);

const workFlowDefinitionEmployeeBy = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/employee`, payload);

const feedbackRequestInitiateManager = (managerId) => api.get(`/assessment-response/feedback-request/initiateManager/${managerId}`);   

const postWorkFlowDefinitionVerifiedPeerFeedback = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/verified/peer-feedback`, payload);

const postWorkFlowDefinitionrecall = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/recall`, payload);

// {{host}}/api/employees/search?page=0&size=10

const postEmployeesSearch = (payload) => api.post(`/employees/search?page=${payload?.page}&size=${payload?.size}`, payload?.payload);

const saveSessionReportAsDraft = (payload) => api.post(`/session/cohort-report-draft/${payload?.sessionId}`, payload?.payload);

const saveSessionReport = (payload) => api.post(`/session/cohort-report-save/${payload?.companyId}/${payload?.sessionId}`, payload?.payload);

const rescheduleSessionReport = (payload) => api.put(`/session/reschedule`, payload);

const getAllReports = (payload) => api.get(`/feedback-report/company/${payload?.companyId}?status=ACTIVE&reportType=${payload?.reportType}`);

const getReportChanges = (payload) => api.get(`/session/cohort-changes/${payload?.sessionId}`);

const getSessionReportForTable = (payload) => api.post(`/session/cohort-changes/all/${payload?.sessionId}?page=${payload?.page}&size=${payload?.size}`, payload);

const getDraftReportCount = (payload) => api.get(`/session/count/${payload?.empId}/Draft`);
const getDoneReportCount = (payload) => api.get(`/session/count/${payload?.empId}/Done`);

const getAnnualCycle = (payload) => api.get(`/annualReviewCycle/${payload?.companyId}/ACTIVE`);

// const downloadReport = (payload) => api.post
// (`/feedback-report/send-report/download?email=null&cycleId=${payload?.cycleId}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${payload?.companyId}&reportId=${payload?.reportId}&managerId=${payload?.managerId}`);
const downloadReport = (payload) => api.get
(`/feedback-report/send-report/download?cycleId=${payload?.cycleId}&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&companyId=${payload?.companyId}&reportId=${payload?.reportId}&managerId=${payload?.managerId}`);
// /feedback-report/send-report/download?companyId=6f904470-c87f-4370-b8b1-9d62a7dce79e&queryId=75b59bb0-e3e6-4d42-bc83-b72415171685&cycleId=0cd20c86-f44f-4a38-abcc-90e897fdf04b&reportId=f85c05eb-39f7-4823-b2b3-c431e37d9e99&managerId=3778e430-74ff-4a40-8bb4-f9225d64dbdb
const workFlowDefinitionEmployeeCount = (payload) => axiosInstanceWorkFlow.get(`/work/flow/definition/employee/count/${payload.employeeDetailsId}/${payload.pmsCycleId}/pms_cycle`);

const workFlow_definition_verified_all_FEEDBACK = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/verified/all/FEEDBACK`, payload);

const assessmentResponseFeedbackRequestInitiateManagerCount = (payload) => api.get(`/assessment-response/feedback-request/initiateManager/count/${payload}`);

//     * Aprooved count*
// http://localhost:9095/api/work/flow/definition/employee/count/d340850a-39fc-44e7-8fa8-2b0a7a69889a




// *Intial Count*
// http://localhost:9093/api/assessment-response/feedback-request/initiateManager/count/0e4fe8f3-642e-470f-8f65-44d8dc662d2f

const getAllFeedbackFoeEmployee = (payload) => api.get(`/assessment-response/feedback-request/byStatus/ALL/${payload?.empId}`);

const initiateByManager = (payload) => api.post(`/assessment-response/add-feedback-request/by-manager/`, payload);

const getNotifications =(payload)=>axiosInstanceNotification.get(`/notification/${payload.employeeId}?readStatus=${payload.type}`);

const getNotificationCount =(payload)=>axiosInstanceNotification.get(`/notification/count/${payload.employeeId}`);

const updateNotifications =(payload)=>axiosInstanceNotification.put(`/notification` , payload);

// Functional Leader
const getSessionApprovalList = (payload) => api.post(`/session/received-for-approval` , payload);
// const getSessionApprovalListCount = (payload) => axiosInstanceWorkFlow.get(`/work/flow/definition/employee/count/${payload?.employeeId}/${payload?.entityId}/${payload?.entityName}`);
const getSessionApprovalListCount = (payload) => api.post(`/session/counts/by-funtional-leader`, payload);
const approveSession = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/verified/all/CALIBRATION` , payload);
const approvePromotionsByFunctionalLeader = (payload) => axiosInstanceWorkFlow.post(`/work/flow/definition/multi-verified` , payload);
const approvePromotionsByFunctionalLeaderCounts = (payload) => api.post(`/session/promotion-approver-count` , payload);
const approveSessionFromReport = (payload) => api.post(`/session/update-and-approve-cohort-report/${payload?.employeeId}/${payload?.sessionId}` , payload?.payload);
const deleteApproveRequest = (payload) => api.delete(`/session/${payload?.sessionId}`);
const checkBeforeApprove = (payload) => api.get(`/session/ratings-qualification-for-approval/${payload?.sessionId}/${payload?.companyId}`);
const getPromotionAppeovalData = (payload) => api.post(`/session/promotion-approver?page=${payload.pageNo}&size=${payload.pageSize}` , payload?.payload);

// compensation
const getCompensationData = (payload) => api.post(`/compensation-calculation/employees/filter?page=${payload?.page}&size=${payload?.size}`, payload?.payload);
const saveCompensation = (payload) => api.post(`/compensation-calculation/save/employee`, payload);
const getCompAlerts = (payload) => api.post(`/alert/evaluate/compensation/${payload?.companyId}`, payload?.payload);
const getIsElligibleToApprove = (payload) => api.get(`/compensation-calculation/checkApprover/${payload?.employeeId}/${payload?.companyId}`);
const getCompDropdownData = (payload) => api.get(`/company/compensation-employees/dropdowns/${payload?.companyId}`);
const approveAllCompPlan = (payload) => api.post(`/compensation-calculation/save/salary`, payload);
const compHistoryLetterViewForManager = (payload) => api.post(`/letter/employee`, payload);
const getApprovedCount = (payload) => api.get(`/compensation-calculation/check/budget/${payload?.employeeId}/${payload?.compannyId}`);

//comp analytics
const getCompChartData = (payload) => api.get(`/dashboard/budget-graph/by-employeeId-and-companyId/${payload?.employeeId}/${payload?.compannyId}?range=${payload?.range}`);
const getDropdownDataForCompAnalytics = (payload) => api.get(`/company/dropdowns-analytics/${payload?.companyId}`);

// close loop
const performanceHistoryData =  (payload) => api.get(`/assessment-response-new/performance-history/${payload?.employeeId}`);
const performanceHistoryPdf =  (payload) => api.get(`/assessment-response/feedback-pdf/${payload?.employeeId}/${payload?.annualCycleId}/${payload?.formFor}`);
const getCloseLoopAssesmentData = (payload) => api.get(`/assessment-response/performance-history/${payload?.employeeId}`);
const isCloseLoopOn = (payload) => api.get(`/pms-closedloop/check-ratings-published/company/${payload?.companyId}`);
const isCloseLoopOnForManFeedback = (payload) => api.get(`/pms-closedloop/check-manager-review-active/company/${payload?.companyId}`);
const compMyTeam = (payload) => api.post(`/compensation-close-loop-cycle/employees/filter?page=${payload?.page}&size=${payload?.size}`, payload?.payload);
const compHistory = (payload) => api.get(`/letter/compensation-history/${payload?.employeeId}`);
const sendLetter = (payload) => api.post(`/compensation-close-loop-cycle/release-letters/${payload?.companyId}/${payload?.employeeId}`, payload?.payload);
const downloadLetterPDF = (payload) => api.post(`/letter/generate-pdf`, payload);
const checkIfPdfIsGenarated = (payload) => api.get(`/assessment-response/check-feedback-pdf-status/${payload?.employeeId}/${payload?.annualCycleId}/${payload?.formFor}`);

// goal form
const goalEmployee_employee_form = (payload) => api.post(`goal-employee/employee-form`, payload);
const post_goalEmployee_employee_form = (payload) => api.post(`goal-employee/save`, payload);
const goalEmployeeFormType = (payload) => api.get(`goal-employee/form-type/${payload.companyId}/${payload.employeeId}`);
const goalEmployee_all_companyId_employeeId = (payload) => api.get(`goal-employee/all/${payload.companyId}/${payload.employeeId}/${payload.status}/${payload.goalCycleId}`);
const myGoalsCounts = (payload) => api.get(`goal-employee/goal-count/${payload.companyId}/${payload.employeeId}/${payload.goalCycleId}`);
const goalComments = (payload) => api.post(`goal-comments/get/goal-id/${payload.id}`, payload?.payload);
const addCommentOnGoal = (payload) => api.post(`goal-comments/save`, payload);
const goalEmployee_delete = (payload) => api.delete(`goal-employee/delete/${payload}`);
const submitGoals = (payload) => api.post(`goal-employee/submit-all/${payload.companyId}`, payload?.payload);
const getGoalCycle = (payload) => api.get(`goal-cycle/company/${payload}/ACTIVE`);
const getActiveGoalCycle = (payload) => api.get(`goal-employee/active-goal-cycle/${payload.companyId}/${payload.employeeId}`);
const getGoalsData = (payload) => api.post(`goal-employee/my-team-filter?page=${payload.page}&size=${payload.size}`, payload?.payload);
const getCGoalsDropdownData = (payload) => api.post(`/company/myTeam/goal-employees/filters`, payload);
const getGoalFormDropdowns = (payload) => api.get(`goal-employee/entity-dropdown/${payload.companyId}/${payload.managerId}`);
// const approveGoals = (payload) => axiosInstanceWorkFlow.post(`work/flow/definition/goal-employee-verified/${payload.employeeId}/${payload.action}`, payload?.payload);
const approveGoals = (payload) => api.post(`goal-employee/goal-employee-verified/${payload.employeeId}/${payload.action}`, payload?.payload);
const mayTeamGoalsCount = (payload) => api.post(`goal-employee/my-team/count`, payload);
const goalfileUpload = (payload , headers) => api.post(`file/upload/goals`, payload , {headers});  
const goalDoc_delete = (docId) => api.delete(`goal-employee/delete/file/${docId}`);
const goalAcknowledge = (payload) => api.get(`goal-employee/acknowledged/${payload.goalId}`);
// /file/upload`

const APIList = {
  getEmployeeData, 
  getAccount, 
  getDropdownData, 
  getDynamicSearch, 
  getFilteredData, 
  getLearningPageData,
  getEmployeeSelfApraisalData,
  getMyTeam,
  getMyTeamDropdowns,
  getMyTeamGrouped,
  getNewMyTeamGrouped,
  get_dashboard_graphs,
  getEntireOrgEmpSubTeam,
  authenticateUser,
  logout,
  createSelfApraisal,
  editSelfApraisal,
  getApraisalStatusData,
  getApraisalStatusDataPaginated,
  getEmployeeById,
  getFeedbackById,
  createFeedback,
  editFeedback,
  getFeedbackSelfApraisalData,
  fileUpload,
  changeProfilePhote,
  getQuestions,
  changePassword,
  forgotPassword,
  resetPassword,
  getAssessment,
  getAssessmentResponse,
  postAssessmentResponse,
  postgetFeedbackByRequest,
  putAssessmentResponse,
  getImmediateTeam,
  getImmediateTeamSearch,
  sendFeedbackRequest,
  getAllSentRequest,
  recallFeedbackRequest,
  getFilteredSearchPeers,
  getFilteredSearchPeersfeed,
  getCohostSearchPeers,
  getFeedbackRequests,
  declineFeedbackRequests,
  getSentRequestCount,
  getReceivedRequestCount,
  getDropdownsfForCohort,
  getDropdownsfForAudit,
  createSession,
  editSession,
  getAllSession,
  getAllsessionByParticipant,
  getSessionById,
  getElligibleForPromotion,
  approvePromotions,
  getCountOnlySessionById,
  deleteSession,
  getAllPeer,
  getFeedbackRequstById,
  getPeerfeedbackByRequest,
  getDoneSession,
  getAllCounts,
  getSessionReportData1,
  getSessionReportData,
  saveSessionReportDataForTable,
  getAuditReportData,
  getRatingHistory,
  getAllActivepmsCycle,
  getInfoData,
  getInfoDataByPageNameAndCompanyId,
  getDashboardData,
  getNewDashboardData,
  saveSessionReportAsDraft,
  saveSessionReport,
  rescheduleSessionReport,
  getReportChanges,
  getSessionReportForTable,
  getDraftReportCount,
  getDoneReportCount,
  getAllReports,
  getAnnualCycle,
  downloadReport,
  workFlowDefinitionEmployee,
  workFlowDefinitionEmployeeByPmsCycle,
  workFlowDefinitionEmployeeBy,
  feedbackRequestInitiateManager,
  postWorkFlowDefinitionVerifiedPeerFeedback,
  postWorkFlowDefinitionrecall,
  postEmployeesSearch,
  workFlowDefinitionEmployeeCount,
  assessmentResponseFeedbackRequestInitiateManagerCount,
  getAllActiveannualReviewCycle,
  getAllFeedbackFoeEmployee,
  initiateByManager,
  getNotifications,
  getNotificationCount,
  updateNotifications,
  workFlow_definition_verified_all_FEEDBACK,
  getSessionApprovalList,
  approveSession,
  approvePromotionsByFunctionalLeader,
  approvePromotionsByFunctionalLeaderCounts,
  getSessionApprovalListCount,
  deleteApproveRequest,
  checkBeforeApprove,
  getPromotionAppeovalData,
  approveSessionFromReport,
  getCompensationData,
  saveCompensation,
  getCompAlerts,
  getIsElligibleToApprove,
  getCompDropdownData,
  approveAllCompPlan,
  compHistoryLetterViewForManager,
  getCompChartData,
  getApprovedCount,
  getDropdownDataForCompAnalytics,
  performanceHistoryData,
  performanceHistoryPdf,
  getCloseLoopAssesmentData,
  isCloseLoopOn,
  isCloseLoopOnForManFeedback,
  compMyTeam,
  compHistory,
  sendLetter,
  downloadLetterPDF,
  checkIfPdfIsGenarated,
  goalEmployee_employee_form,
  goalEmployeeFormType,
  post_goalEmployee_employee_form,
  goalEmployee_all_companyId_employeeId,
  myGoalsCounts,
  goalComments,
  addCommentOnGoal,
  goalEmployee_delete,
  submitGoals,
  getGoalCycle,
  getActiveGoalCycle,
  getGoalsData,
  getCGoalsDropdownData,
  getGoalFormDropdowns,
  approveGoals,
  mayTeamGoalsCount,
  goalfileUpload,
  goalDoc_delete,
  goalAcknowledge,
};

export default APIList;
