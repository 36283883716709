import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import badgeIcon from "../../../../assets/images/badge.png";

const Competencies = ({ }) => {
  const dispatch = useDispatch();

  const competenciesList = [
    {
      primary: "Primary",
      heading: "Skills and Knowledge Base",
      subHeading: "Self Declaration: NA",
    },
    {
      primary: "Primary",
      heading: "Technical Systems",
      subHeading: "Self Declaration: NA",
    },
    {
      primary: "Primary",
      heading: "Managerial Systems",
      subHeading: "Self Declaration: NA",
    },
    {
      primary: "Primary",
      heading: "Values and Norms",
      subHeading: "Self Declaration: NA",
    },
  ]

  return (
    <div className="first-row">
      <div className="header-section">
        <Typography className="aboutheading competencies-mb-12">Top Competencies </Typography>
      </div>
      <div className="main-b eeppage">
        {/*----- Desktop View -----*/}
        <div className="row desktop-company-list">
          {/*----- Top Competencies List -----*/}
          {competenciesList?.map((company, index) => {
            return (
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="blueCard">
                  <div className="content-section">
                    <div className="header-section">
                      <Typography className='primary-txt'>{company.primary}</Typography>
                      <img src={badgeIcon} alt="MediBuddy" />
                    </div>
                    <Typography className="competencies-heading">{company.heading}</Typography>
                  </div>
                  <div className="bottom-section">
                    <Typography className="competencies-sub-heading">{company.subHeading}</Typography>
                  </div>
                </div>
              </div>
            )
          })}
        </div>


        {/*----- Mobile View -----*/}
        <div className="row mobile-company-list">
          <div className="horizontal-scroll-container">
            {/*----- Top Competencies List -----*/}
            {competenciesList?.map((company, index) => {
              return (
                <div class="blueCard" key={index}>
                  <div className="content-section">
                    <div className="header-section">
                      <Typography className='primary-txt'>{company.primary}</Typography>
                      <img src={badgeIcon} alt="MediBuddy" />
                    </div>
                    <Typography className="competencies-heading">{company.heading}</Typography>
                  </div>
                  <div className="bottom-section">
                    <Typography className="competencies-sub-heading">{company.subHeading}</Typography>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Competencies);
