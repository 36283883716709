import React, { useState } from "react";
import { Grid, Button, Typography, MenuItem, Select } from "@mui/material";
import EditUserProfile from "../../../../../assets/images/successionPlan/editUserProfile.png";
import DownloadIcon from "../../../../../assets/images/CandidateAssessment/downloadIcon.svg";
import StronglyInclinedIcon from "../../../../../assets/images/CandidateAssessment/stronglyInclined.svg";
import NotInclinedIcon from "../../../../../assets/images/CandidateAssessment/notInclined.svg";

const MyInterviewsViewFeedbackFor = () => {

  const myInterViewFeedbackLists = [
    {
      assignedCompetencies: [
        { title: "Customer Obsession" },
        { title: "Ownership" },
        { title: "Invent And Simplify" },
        { title: "Invent And Simplify" },
        { title: "Hire and Develop the Best" },
        { title: "Ownership" },
      ],
      feedback: "HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating. HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating.",
      decision: "Strongly not inclined to hire",
    }
  ];

  return (
    <Grid container className="user-form-card">
      {/*----- User Details -----*/}
      <Grid item xs={12} sm={12} md={12} className="user-card">
        <Grid container spacing={2}>
          <Grid item xs={12} md={2.2} sm={2.2} className="profile-grid">
            <img
              src={EditUserProfile}
              alt="EditUserProfile"
              height={50}
              width={50}
            />
            <Grid>
              <Typography className="profile-name">{"Ashish Reji"}</Typography>
              <Typography className="profile-id">{"ID: 26k7"}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9.8} sm={9.8} className="right-profile-field">
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} sm={2}>
                <Typography className="profile-field-label">{"Applied Job Code"}</Typography>
                <Typography className="profile-field-value field-black-value">{"10614"}</Typography>
              </Grid>
              <Grid item xs={12} md={2} sm={2}>
                <Typography className="profile-field-label">{"Job designation"}</Typography>
                <Typography className="profile-field-value field-black-value">{"Developer"}</Typography>
              </Grid>
              <Grid item xs={12} md={2} sm={2}>
                <Typography className="profile-field-label">{"Job grade"}</Typography>
                <Typography className="profile-field-value field-black-value">{"Lorem Ipsum"}</Typography>
              </Grid>
              <Grid item xs={12} md={2} sm={2}>
                <Typography className="profile-field-label">{"Hiring manager"}</Typography>
                <Typography className="profile-field-value field-black-value">{"Ravi Shankar"}</Typography>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Typography className="profile-field-label">{"Documents Attached"}</Typography>
                <Grid className="my-interview-download-field">
                  <Typography className="profile-field-value">{"Resume"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                  <Typography className="profile-field-value">{"Job Description"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*----- Schedule Time Section -----*/}
      <Grid className="myinterview-viewfeedback-for-grid">
        {myInterViewFeedbackLists.map((item, index) => (
          <Grid>
            {/*----- Assigned Competencies -----*/}
            <Grid>
              <Typography className="myinterview-sub-title">{"Assigned Competencies"}</Typography>
              <div className="assigned-competencies-container">
                {item.assignedCompetencies.map((competency, compIndex) => (
                  <div className="assigned-comp-border-div">
                    <Typography className="assigned-type">{competency.title}</Typography>
                  </div>
                ))}
              </div>
            </Grid>

            {/*----- Feedback -----*/}
            <Grid>
              <Typography className="myinterview-sub-title myinterview-mt-12">{"Feedback"}</Typography>
              <Typography className="feedback-description">{item.feedback}</Typography>
            </Grid>

            {/*----- Decision -----*/}
            <Grid>
              <Typography className="myinterview-sub-title myinterview-mt-12">{"Decision"}</Typography>
              <Grid className="decision-grid">
                {item.decision === "Strongly inclined to hire" ? <img src={StronglyInclinedIcon} alt="Decision Icon" className="decision-icon" /> : <img src={NotInclinedIcon} alt="Decision Icon" className="decision-icon" />}
                <Typography className="decision" style={{ color: item.decision === "Strongly inclined to hire" ? "#00AA5A" : "#DB3535" }}>{item.decision}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default MyInterviewsViewFeedbackFor;
