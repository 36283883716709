import React, { useState } from 'react';
import { Dialog, Button, Grid, Typography, TextField, InputAdornment, IconButton, Select, MenuItem } from '@mui/material';
import DownArrowIcon from "../../../../../assets/images/Leaves/downArrow.svg";
import DateIcon from "../../../../../assets/images/Leaves/dateIcon.svg";
import UserProfileIcon from "../../../../../assets/images/Leaves/userProfile.png";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ApplyingForLeaveModal = ({ showApplyingForLeaveModal, handleApplyingForLeaveModalNo }) => {

    const [leaveType, setLeaveType] = useState("Annual");

    const handleChange = (event) => {
        setLeaveType(event.target.value);
    };

    const DownArrowIconComponent = () => (
        <img src={DownArrowIcon} alt="Down Arrow" style={{ width: "1em", height: "1em", marginRight: "15px" }} />
    );

    const handleChangeDate = () => {

    }

    return (
        <Dialog
            open={showApplyingForLeaveModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                    width: "600px",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="applying-for-leave-modal">
                {/*----- Modal Title -----*/}
                <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "10px" }}>
                    <Typography className="modal-heading">{"Applying for Leave"}</Typography>

                    <Grid className="profile-grid">
                        <img src={UserProfileIcon} alt="UserProfileIcon" className="user-profile-icon" />
                        <Grid>
                            <Typography className="user-name">{"Ashish Reji"}</Typography>
                            <Typography className="user-id">{"ID: 26k7"}</Typography>
                        </Grid>
                    </Grid>

                    {/*----- Horizontal border -----*/}
                    <Grid className="modal-border-horizontal" />
                </Grid>

                {/*----- Leave Type -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="field-label">{"Leave Type"}</Typography>
                    <Select
                        value={leaveType}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                        IconComponent={DownArrowIconComponent}
                        sx={{
                            backgroundColor: "#FFFFFF",
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: "#FFFFFF",
                                width: "100%",
                            },
                        }}
                    >
                        <MenuItem value="Annual">Annual</MenuItem>
                        <MenuItem value="Sick">Sick</MenuItem>
                        <MenuItem value="Casual">Casual</MenuItem>
                        <MenuItem value="Maternity">Maternity</MenuItem>
                    </Select>
                </Grid>

                {/*----- 3 Taken out of 14 -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="leave-days">{"3 Taken out of 14"}</Typography>
                </Grid>

                <Grid container spacing={2} className="mt-10">
                    {/*----- Start Date -----*/}
                    <Grid item xs={12} md={6} sm={6}>
                        <Typography className="field-label">{"Start Date"}</Typography>
                        {/* <TextField
                            name="startDate"
                            placeholder="Start Date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={"04 Dec 2024"}
                            sx={{
                                backgroundColor: "#FFFFFF",
                                '& .MuiOutlinedInput-root': {
                                    paddingRight: "6px",
                                    backgroundColor: "#FFFFFF",
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <img src={DateIcon} alt="Slider" height={20} width={20} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        /> */}

                         <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                            inputFormat="dd MMM yyyy"
                            value="2024-12-04"
                            onChange={handleChangeDate}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    width: "100%",
                                    borderRadius: "8px",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                    },
                                }}
                                name="feedbackExpectedBy"
                                renderInput={(params) => (
                                    <TextField
                                        size={"small"}
                                        variant="outlined"
                                        sx={{
                                            pointerEvents: "none",
                                            "& .MuiOutlinedInput-root": {
                                                button: {
                                                    pointerEvents: "all",
                                                },
                                            },
                                            mt: "6px",
                                            width: "100%",
                                            backgroundColor: "#ffffff",
                                            borderRadius: "5px",
                                            "& fieldset": {
                                                borderRadius: "8px",
                                                border: "1px solid gray",
                                            },
                                            "& .css-k4qjio-MuiFormHelperText-root":
                                            {
                                                backgroundColor: "#ffffff",
                                                margin: "0px",
                                                paddingLeft: "0.5rem",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                // border: "none",
                                                // borderColor: "rgba(0, 0, 0, 0.23)",
                                                borderRadius: "8px",
                                                button: {
                                                    pointerEvents: "all",
                                                },
                                            },
                                            "& .MuiInputBase-input::placeholder": {
                                                color: "red",
                                                // opacity: 1,
                                            },
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        
                    </Grid>

                    {/*----- End Date -----*/}
                    <Grid item xs={12} md={6} sm={6}>
                        <Typography className="field-label">{"End Date"}</Typography>
                        {/* <TextField
                            name="endDate"
                            placeholder="End Date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={"06 Dec 2024"}
                            sx={{
                                backgroundColor: "#FFFFFF",
                                '& .MuiOutlinedInput-root': {
                                    paddingRight: "6px",
                                    backgroundColor: "#FFFFFF",
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <img src={DateIcon} alt="Slider" height={20} width={20} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        /> */}

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                            inputFormat="dd MMM yyyy"
                            value="2024-12-06"
                            onChange={handleChangeDate}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    width: "100%",
                                    borderRadius: "8px",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                    },
                                }}
                                name="feedbackExpectedBy"
                                renderInput={(params) => (
                                    <TextField
                                        size={"small"}
                                        variant="outlined"
                                        sx={{
                                            pointerEvents: "none",
                                            "& .MuiOutlinedInput-root": {
                                                button: {
                                                    pointerEvents: "all",
                                                },
                                            },
                                            mt: "6px",
                                            width: "100%",
                                            backgroundColor: "#ffffff",
                                            borderRadius: "5px",
                                            "& fieldset": {
                                                borderRadius: "8px",
                                                border: "1px solid gray",
                                            },
                                            "& .css-k4qjio-MuiFormHelperText-root":
                                            {
                                                backgroundColor: "#ffffff",
                                                margin: "0px",
                                                paddingLeft: "0.5rem",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                // border: "none",
                                                // borderColor: "rgba(0, 0, 0, 0.23)",
                                                borderRadius: "8px",
                                                button: {
                                                    pointerEvents: "all",
                                                },
                                            },
                                            "& .MuiInputBase-input::placeholder": {
                                                color: "red",
                                                // opacity: 1,
                                            },
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>

                    </Grid>
                </Grid>

                {/*----- Leave Days -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="leave-days">{"Leave Days: 2"}</Typography>
                </Grid>

                {/*----- Reason for leave -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Typography className="field-label mt-15">{"Reason for leave"}</Typography>
                    <TextField
                        name="reasonForLeave"
                        placeholder="Message"
                        variant="outlined"
                        size="small"
                        sx={{
                            width: '100%',
                            backgroundColor: "#FFFFFF",
                            "& fieldset": {
                                borderRadius: '8px',
                            },
                        }}
                        multiline
                        minRows={3}
                    />
                </Grid>

                <Grid item xs={12} md={12} sm={12} className="action-grid">
                    <Button className="cancel-btn" onClick={handleApplyingForLeaveModalNo}>{"Cancel"}</Button>
                    <Button className="submit-btn">{"Submit"}</Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ApplyingForLeaveModal;