import React from 'react';
import {
    Dialog,
    Button,
    Grid,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
} from '@mui/material';


const LeaveAtAGlanceModal = ({ showLeaveAtAGlanceModal, handleLeaveAtAGlanceModalNo }) => {

    const leaveGlances = [
        {
            id: 1,
            leaveType: "Public Holiday",
            entitlement: "10 days public holiday",
            paidUnpaid: "Paid",
            eligibility: "All Employees",
            leaveBalance: "5",
        },
        {
            id: 2,
            leaveType: "Privilege Leave",
            entitlement: "21 working days",
            paidUnpaid: "Paid",
            eligibility: "All Regular full time employees",
            leaveBalance: "5",
        },
        {
            id: 3,
            leaveType: "Annual Leaves",
            entitlement: "21 working days",
            paidUnpaid: "Paid",
            eligibility: "All Regular full time employees",
            leaveBalance: "NA",
        },
        {
            id: 4,
            leaveType: "Sick Leaves",
            entitlement: "21 working days",
            paidUnpaid: "Paid",
            eligibility: "All Regular full time employees",
            leaveBalance: "5",
        },
        {
            id: 5,
            leaveType: "Maternity Leave",
            entitlement: "21 working days",
            paidUnpaid: "Paid",
            eligibility: "All Regular full time employees",
            leaveBalance: "5",
        },
        {
            id: 6,
            leaveType: "Bereavement Leave",
            entitlement: "21 working days",
            paidUnpaid: "Paid",
            eligibility: "All Regular full time employees",
            leaveBalance: "5",
        },
    ]

    return (
        <Dialog
            open={showLeaveAtAGlanceModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: "1rem",
                    backgroundColor: "#F9F9F9",
                }
            }}
            fullWidth
            maxWidth="md">
            <Grid container className="leave-glance-modal">
                {/*----- Modal Title -----*/}
                <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "10px" }}>
                    <Typography className="modal-heading">{"Leave at a glance"}</Typography>

                    {/*----- Horizontal border -----*/}
                    <Grid className="heading-border-horizontal" />
                </Grid>

                {/*----- Family Care Leave -----*/}
                <Grid item xs={12} md={12} sm={12}>
                    <Table className="leave-table-border">
                        <TableHead>
                            <TableRow className="table-head">
                                <TableCell className="table-cell head-title">Leave Type</TableCell>
                                <TableCell className="table-cell head-title">Entitlement</TableCell>
                                <TableCell className="table-cell head-title">Paid/Unpaid</TableCell>
                                <TableCell className="table-cell head-title">Eligibility</TableCell>
                                <TableCell className="head-title">Leave Balance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaveGlances.map((item, index) => (
                                <TableRow>
                                    <TableCell className="table-cell table-value leave-type">{item.leaveType}</TableCell>
                                    <TableCell className="table-cell table-value">{item.entitlement}</TableCell>
                                    <TableCell className="table-cell table-value">{item.paidUnpaid}</TableCell>
                                    <TableCell className="table-cell table-value">{item.eligibility}</TableCell>
                                    <TableCell className="table-value">{item.leaveBalance}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} md={12} sm={12} className="action-leave-glance-grid">
                    <Button className="close-btn" onClick={handleLeaveAtAGlanceModalNo}>{"Close"}</Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default LeaveAtAGlanceModal;