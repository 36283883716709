import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';


const AdditionalLeaves = ({ }) => {
    const dispatch = useDispatch();

    return (
        <Grid container className="table-card">
            <Grid item xs={12} sm={12} md={12}>
                <Typography>{"Additional Leaves"}</Typography>
            </Grid>

        </Grid>
    );
};

export default React.memo(AdditionalLeaves);
