import React, { useState } from 'react';
import { Grid, Typography, Button, TextField, InputAdornment, IconButton, Popover } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { HiOutlineChevronDown } from "react-icons/hi2";
import { IoMdSearch } from "react-icons/io";
import LeftArrow from "../../../../../assets/images/leftArrow.png";
import RightArrow from "../../../../../assets/images/rightArrow.png";
import DownArrow from "../../../../../assets/images/CandidateAssessment/downArrow.svg";
import MenuIcon from "../../../../../assets/images/Leaves/menuIcon.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const RequisitionsTable = ({ }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const [popOver, setPopOver] = useState(null);

    const handlePopOverClick = (event) => {
        setPopOver(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setPopOver(null);
    };

    const open = Boolean(popOver);
    const id = open ? 'simple-popover' : undefined;

    const [showAssigningInterviewersForModal, setShowAssigningInterviewersForModal] = useState(false);

    const handleAssigningInterviewersForModal = () => {
        setShowAssigningInterviewersForModal(true);
    };

    const handleAssigningInterviewersForModalNo = () => {
        setShowAssigningInterviewersForModal(false);
    };

    const candidateList = [
        {
            id: 1,
            candidateName: "Ashish Reji",
            appliedJobCode: "10614",
            jobDesignation: "Developer",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            location: "Lorem Ipsum",
            hiringManager: "Ravi Shankar",
            action: "View Feedback",
        },
        {
            id: 2,
            candidateName: "Akshay C",
            appliedJobCode: "10680",
            jobDesignation: "Designer",
            jobGrade: "Lorem Ipsum",
            department: "Design",
            location: "Lorem Ipsum",
            hiringManager: "Ravi Shankar",
            action: "Give Feedback",
        },
        {
            id: 3,
            candidateName: "Govind H",
            appliedJobCode: "10681",
            jobDesignation: "Architect",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            location: "Lorem Ipsum",
            hiringManager: "Ravi Shankar",
            action: "View Feedback",
        },
        {
            id: 4,
            candidateName: "Umashankar Aravallil",
            appliedJobCode: "10692",
            jobDesignation: "Assistant Manager",
            jobGrade: "Lorem Ipsum",
            department: "Management",
            location: "Lorem Ipsum",
            hiringManager: "Mohan Sharma",
            action: "View Feedback",
        },
        {
            id: 5,
            candidateName: "Akshay C",
            appliedJobCode: "10691",
            jobDesignation: "Developer",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            location: "Lorem Ipsum",
            hiringManager: "Mohan Sharma",
            action: "Give Feedback",
        },
        {
            id: 6,
            candidateName: "Ashish Reji",
            appliedJobCode: "10660",
            jobDesignation: "Designer",
            jobGrade: "Lorem Ipsum",
            department: "Design",
            location: "Lorem Ipsum",
            hiringManager: "Mohan Sharma",
            action: "View Feedback",
        },
        {
            id: 7,
            candidateName: "Govind H",
            appliedJobCode: "10665",
            jobDesignation: "Architect",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            location: "Lorem Ipsum",
            hiringManager: "Mohan Sharma",
            action: "Give Feedback",
        },
        {
            id: 8,
            candidateName: "Umashankar Aravallil",
            appliedJobCode: "10666",
            jobDesignation: "Developer",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            location: "Lorem Ipsum",
            hiringManager: "Ravi Shankar",
            action: "View Feedback",
        },
        {
            id: 9,
            candidateName: "Govind H",
            appliedJobCode: "10667",
            jobDesignation: "Assistant Manager",
            jobGrade: "Lorem Ipsum",
            department: "Management",
            location: "Lorem Ipsum",
            hiringManager: "Ravi Shankar",
            action: "Give Feedback",
        },
        {
            id: 10,
            candidateName: "Umashankar Aravallil",
            appliedJobCode: "10668",
            jobDesignation: "Designer",
            jobGrade: "Lorem Ipsum",
            department: "Design",
            location: "Lorem Ipsum",
            hiringManager: "Ravi Shankar",
            action: "Give Feedback",
        },
    ];

    const handleFeedback = (action) => {

        if(action === "View Feedback"){
            history.push("/my-requisitions-view-feedback");
        } else {
            history.push("/my-requisitions-give-feedback");
        }

    }

    return (
        <Grid container className="candidate-table">
            <Grid item xs={12} sm={12} md={12} className="table-card">
                <Grid container spacing={2} className="table-top-section">
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={search}
                            onChange={handleSearchChange}
                            sx={{
                                border: "none",
                                borderRadius: "10px",
                                backgroundColor: "#F9F9F9",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton edge="start">
                                            <IoMdSearch size={20} color="#979797" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <Button className="filter-btn">
                            <PiSlidersHorizontalLight className="filter-icon" />
                            {"Filter"}
                            <HiOutlineChevronDown className="filter-down-arrow" />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="dropdown-grid">
                        <Button className="drop-down-btn">
                            {"Config. Columns"}
                            <HiOutlineChevronDown className="down-arrow" />
                        </Button>
                    </Grid>
                </Grid>

                <div className="candidate-table-div">
                    <div className="table-container">
                        {/*----- First Columns -----*/}
                        <table className="candidate-list-table left-drop-shadow">
                            <tr className="table-head-candidate-list">
                                <th className="w-180 pl-20">Candidate Name &nbsp;<FaSort className="c-pointer" /></th>
                            </tr>

                            {candidateList.map((item, index) => (
                                <tr>
                                    <td className="pl-20">
                                        <div className="w-180">
                                            <Typography className="candidate-table-value">{item.candidateName}</Typography>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>

                        {/*----- Middle Columns -----*/}
                        <div className="middle-columns-scroll">
                            <table className="candidate-list-table">
                                <tr className="table-head-candidate-list">
                                    <th className="w-500">Applied Job Code &nbsp;<FaSort className="c-pointer" /></th>
                                    <th className="w-180">Job designation</th>
                                    <th className="w-180">Job grade</th>
                                    <th className="w-180">Department</th>
                                    <th className="w-180">Location</th>
                                    <th className="w-180">HR Recruiter</th>
                                </tr>

                                {candidateList.map((item, index) => (
                                    <tr>
                                        <td className="pl-20">
                                            <div className="w-180">
                                                <Typography className="candidate-table-value">{item.appliedJobCode} </Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="candidate-table-value">{item.jobDesignation}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="candidate-table-value">{item.jobGrade}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="candidate-table-value">{item.department}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="candidate-table-value">{item.location}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="candidate-table-value">{item.hiringManager}</Typography>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>

                        {/*----- Last Columns -----*/}
                        <table className="candidate-list-table right-drop-shadow">
                            <tr className="table-head-candidate-list">
                                <th>Action &nbsp;<FaSort className="c-pointer" /></th>
                            </tr>
                            {candidateList.map((item, index) => (
                                <tr>
                                    <td>
                                        <div className='status-div'>
                                            <Typography className="candidate-status-table-value" onClick={() => handleFeedback(item.action)}>{item.action}</Typography>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </Grid>

            {/*----- Pagination -----*/}
            <div className="table-pagination">
                <div className="d-flex">
                    <Typography className="per-page-items">{"Per page items"}</Typography>
                    <div className="page-count-div"><Typography className="page-count">{"10"}<img src={DownArrow} alt="DownArrow" className="down-arrow ml-10" /></Typography></div>
                </div>

                <div className="d-flex">
                    <div className="first-pagination-div"><Typography className="first-last-pagination">{"First"}</Typography></div>
                    <img src={LeftArrow} alt="LeftArrow" className="left-right-arrow" />
                    <div className="pagination-border"><Typography className="pagination-count">{"1"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"2"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"3"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"4"}</Typography></div>
                    <div className="pagination-border"><Typography className="pagination-count">{"5"}</Typography></div>
                    <img src={RightArrow} alt="RightArrow" className="left-right-arrow ml-10" />
                    <div className="last-pagination-div"><Typography className="first-last-pagination">{"Last"}</Typography></div>
                </div>
            </div>
        </Grid>
    );
};

export default React.memo(RequisitionsTable);
