import React, { useState, useMemo } from "react";
import { Grid, Button, Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import JoditEditor from "jodit-react";
import EditUserProfile from "../../../../assets/images/successionPlan/editUserProfile.png";
import DownloadIcon from "../../../../assets/images/CandidateAssessment/downloadIcon.svg";
import IButton from "../../../../assets/images/Leaves/iButton.svg";

const GiveFeedbackFor = () => {
  const [content, setContent] = useState("HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating. HTML stands for HyperText Markup Language and is the language of the internet. It is the standard text formatting language used for creating.");
  const [selectedValue, setSelectedValue] = React.useState('');

  const assignedCompetencies = [
    { title: "Customer Obsession" },
    { title: "Ownership" },
    { title: "Invent And Simplify" },
    { title: "Invent And Simplify" },
    { title: "Hire and Develop the Best" },
    { title: "Ownership" },
  ]

  // Configuration for JoditEditor
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "Start typing here..."
    }),
    []
  );

  return (
    <div className="give-feedback-for">
      <Grid container spacing={2}>
        {/*----- Give Feedback for -----*/}
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Give Feedback for"}<img src={IButton} alt="IButton" className="i-button" /></Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Grid container className="user-form-card">
            {/*----- User Details -----*/}
            <Grid item xs={12} sm={12} md={12} className="user-card">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2.5} sm={2.5} className="profile-grid">
                  <img
                    src={EditUserProfile}
                    alt="EditUserProfile"
                    height={50}
                    width={50}
                  />
                  <Grid>
                    <Typography className="profile-name">{"Ashish Reji"}</Typography>
                    <Typography className="profile-id">{"ID: 26k7"}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={9.5} sm={9.5} className="right-profile-field">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={2.2} sm={2.2}>
                      <Typography className="profile-field-label">{"Applied Job Code"}</Typography>
                      <Typography className="profile-field-value">{"10614"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className="profile-field-label">{"Job designation"}</Typography>
                      <Typography className="profile-field-value">{"Developer"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className="profile-field-label">{"Job grade"}</Typography>
                      <Typography className="profile-field-value">{"Lorem Ipsum"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={2}>
                      <Typography className="profile-field-label">{"Hiring manager"}</Typography>
                      <Typography className="profile-field-value">{"Ravi Shankar"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3.8} sm={3.8}>
                      <Typography className="profile-field-label">{"Documents Attached"}</Typography>
                      <Grid className="download-field">
                        <Typography className="profile-field-value">{"Resume"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                        <Typography className="profile-field-value">{"Job Description"}<img src={DownloadIcon} alt="Download Icon" className="download-icon" /></Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className="givefeedback-schedule-grid">
              {/*----- Assigned Competencies -----*/}
              <Grid>
                <Typography className="sub-title">{"Assigned Competencies"}</Typography>
                <div className="assigned-competencies-container">
                  {assignedCompetencies.map((competency, compIndex) => (
                    <div className="assigned-comp-border-div">
                      <Typography className="assigned-type">{competency.title}</Typography>
                    </div>
                  ))}
                </div>
              </Grid>

              {/*----- Select Decision -----*/}
              <Grid className="mt-30">
                <JoditEditor
                  value={content}
                  config={config}
                  tabIndex={1}
                  onBlur={(newContent) => setContent(newContent)} // Update content onBlur
                />
              </Grid>

              {/*----- Select Decision -----*/}
              <Grid className="mt-30">
                <Typography className="sub-title">{"Select Decision"}</Typography>

                <RadioGroup
                  name="responseBoolean"
                  value={selectedValue}
                  onChange={(event) => setSelectedValue(event.target.value)}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                >
                  <FormControlLabel
                    value="strongly_not_inclined"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#004259', // Checked color
                          },
                        }}
                      />
                    }
                    label={"Strongly not inclined to hire"}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        color: "#7E7E7E", // Default label color
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                      '& .Mui-checked + .MuiFormControlLabel-label': {
                        color: "#00425A", // Label color when checked
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                    }}
                  />
                  <FormControlLabel
                    value="not_inclined"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#004259', // Checked color
                          },
                        }}
                      />
                    }
                    label={"Not inclined to hire"}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        color: "#7E7E7E", // Default label color
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                      '& .Mui-checked + .MuiFormControlLabel-label': {
                        color: "#00425A", // Label color when checked
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                    }}
                  />
                  <FormControlLabel
                    value="inclined"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#004259', // Checked color
                          },
                        }}
                      />
                    }
                    label={"Inclined to hire"}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        color: "#7E7E7E", // Default label color
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                      '& .Mui-checked + .MuiFormControlLabel-label': {
                        color: "#00425A", // Label color when checked
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                    }}
                  />
                  <FormControlLabel
                    value="strongly_inclined"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: '#004259', // Checked color
                          },
                        }}
                      />
                    }
                    label={"Strongly inclined to hire"}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        color: "#7E7E7E", // Default label color
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                      '& .Mui-checked + .MuiFormControlLabel-label': {
                        color: "#00425A", // Label color when checked
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontFamily: "poppins"
                      },
                    }}
                  />
                </RadioGroup>
              </Grid>

              {/*----- Action Button -----*/}
              <Grid container className="action-grid">
                <Button className="cancel-btn">{"Cancel"}</Button>
                <Button className="submit-btn">{"Submit"}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default GiveFeedbackFor;
