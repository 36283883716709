import React, { useState } from 'react';
import { Grid, Typography, Button, TextField, InputAdornment, IconButton, Popover } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FaSort } from "react-icons/fa6";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { HiOutlineChevronDown } from "react-icons/hi2";
import { IoMdSearch } from "react-icons/io";
import MenuIcon from "../../../../../assets/images/Leaves/menuIcon.svg";


const MyInterviewsTable = ({ }) => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const [popOver, setPopOver] = useState(null);

    const handlePopOverClick = (event) => {
        setPopOver(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setPopOver(null);
    };

    const open = Boolean(popOver);
    const id = open ? 'simple-popover' : undefined;


    const hrmyInterviewList = [
        {
            id: 1,
            candidateName: "Ashish Reji",
            jobDesignation: "Developer",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            hiringManager: "Ravi Shankar",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Strongly inc,to hire",
        },
        {
            id: 2,
            candidateName: "Akshay C",
            jobDesignation: "Designer",
            jobGrade: "Lorem Ipsum",
            department: "Design",
            hiringManager: "Ravi Shankar",
            interviewDateTime: "24 Dec 2024, 05:00PM - 06:00PM",
            status: "Pending",
        },
        {
            id: 3,
            candidateName: "Govind H",
            jobDesignation: "Architect",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            hiringManager: "Ravi Shankar",
            interviewDateTime: "25 Dec 2024, 04:00PM - 05:00PM",
            status: "Strongly inc,to hire",
        },
        {
            id: 4,
            candidateName: "Umashankar Aravallil",
            jobDesignation: "Assistant Manager",
            jobGrade: "Lorem Ipsum",
            department: "Management",
            hiringManager: "Mohan Sharma",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Provided",
        },
        {
            id: 5,
            candidateName: "Akshay C",
            jobDesignation: "Developer",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            hiringManager: "Mohan Sharma",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Pending",
        },
        {
            id: 6,
            candidateName: "Ashish Reji",
            jobDesignation: "Designer",
            jobGrade: "Lorem Ipsum",
            department: "Design",
            hiringManager: "Mohan Sharma",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Not inc,to hire",
        },
        {
            id: 7,
            candidateName: "Govind H",
            jobDesignation: "Architect",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            hiringManager: "Mohan Sharma",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Inc,to hire",
        },
        {
            id: 8,
            candidateName: "Umashankar Aravallil",
            jobDesignation: "Developer",
            jobGrade: "Lorem Ipsum",
            department: "Development",
            hiringManager: "Ravi Shankar",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Inc,to hire",
        },
        {
            id: 9,
            candidateName: "Govind H",
            jobDesignation: "Assistant Manager",
            jobGrade: "Lorem Ipsum",
            department: "Management",
            hiringManager: "Ravi Shankar",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Pending",
        },
        {
            id: 10,
            candidateName: "Umashankar Aravallil",
            jobDesignation: "Designer",
            jobGrade: "Lorem Ipsum",
            department: "Design",
            hiringManager: "Ravi Shankar",
            interviewDateTime: "23 Dec 2024, 05:00PM - 06:00PM",
            status: "Pending",
        },
    ];

    return (
        <Grid container className="my-interviews-table">
            <Grid item xs={12} sm={12} md={12} className="table-card">
                <Grid container spacing={2} className="table-top-section">
                    <Grid item xs={12} sm={10} md={10}>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={search}
                            onChange={handleSearchChange}
                            sx={{
                                border: "none",
                                borderRadius: "10px",
                                backgroundColor: "#F9F9F9",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton edge="start">
                                            <IoMdSearch size={20} color="#979797" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <Button className="filter-btn">
                            <PiSlidersHorizontalLight className="filter-icon" />
                            {"Filter"}
                            <HiOutlineChevronDown className="filter-down-arrow" />
                        </Button>
                    </Grid>
                </Grid>

                <div className="hr-my-interviews-table-div">
                    <div className="table-container">
                        {/*----- First Columns -----*/}
                        <table className="my-interviews-list-table left-drop-shadow">
                            <tr className="table-head-hr-interview-list">
                                <th className="w-180 pl-20">Candidate Name &nbsp;<FaSort className="c-pointer" /></th>
                            </tr>

                            {hrmyInterviewList.map((item, index) => (
                                <tr>
                                    <td className="pl-20">
                                        <div className="w-180">
                                            <Typography className="hrmy-interview-table-value">{item.candidateName}</Typography>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>

                        {/*----- Middle Columns -----*/}
                        <div className="middle-columns-scroll">
                            <table className="my-interviews-list-table">
                                <tr className="table-head-hr-interview-list">
                                    <th className="w-180">Job designation</th>
                                    <th className="w-180">Job grade</th>
                                    <th className="w-180">Department</th>
                                    <th className="w-180">Hiring manager</th>
                                    <th className="w-280">Interview Date,Time</th>
                                </tr>

                                {hrmyInterviewList.map((item, index) => (
                                    <tr>
                                        <td className="pl-20">
                                            <div className="w-180">
                                                <Typography className="hrmy-interview-table-value">{item.jobDesignation}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="hrmy-interview-table-value">{item.jobGrade}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="hrmy-interview-table-value">{item.department}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-180">
                                                <Typography className="hrmy-interview-table-value">{item.hiringManager}</Typography>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="w-280">
                                                <Typography className="hrmy-interview-table-value">{item.interviewDateTime}</Typography>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>

                        {/*----- Last Columns -----*/}
                        <table className="my-interviews-list-table right-drop-shadow">
                            <tr className="table-head-hr-interview-list">
                                <th>Feedback Status &nbsp;<FaSort className="c-pointer" /></th>
                            </tr>
                            {hrmyInterviewList.map((item, index) => (
                                <tr>
                                    <td>
                                        <div className='status-div'>
                                            <Typography className="hrmy-interview-status-table-value" style={{color: item.status === "Pending" ? "#FF9900" : "#7E7E7E"}}>{item.status}</Typography>
  
                                            <Button className="action-menu-btn" onClick={handlePopOverClick}>
                                                <img src={MenuIcon} alt="MenuIcon" className="menu-icon" />
                                            </Button>
                                        </div>

                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={popOver}
                                            onClose={handlePopOverClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    boxShadow: '0px 0px 10px 6px #0000000D',
                                                },
                                            }}
                                        >
                                            <Typography sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, cursor: "pointer", p: 2 }}>{"Give Feedback"}</Typography>
                                            {/* <Typography sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, cursor: "pointer", p: 2 }}>{"View Details"}</Typography> */}
                                        </Popover>  
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default React.memo(MyInterviewsTable);
