import React, { useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, IconButton, useMediaQuery, Popover } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import TopBottomArrow from "../../../../../assets/images/MyCareer/top-bottom-arrow.svg";
import RightTopArrow from "../../../../../assets/images/MyCareer/right-top-arrow.svg";
import VerticalArrow from "../../../../../assets/images/MyCareer/verticalArrow.svg";
import RotationArrow from "../../../../../assets/images/MyCareer/rotationArrow.svg";


const JuniorToSeniorLevel = ({ }) => {
    const dispatch = useDispatch();

    return (
        <Grid className="junior-to-senior-level-card">
            <Grid className="junior-to-senior-level-grid">
                <Grid>
                    <Typography className="top-txt">{"Junior to Senior Level"}</Typography>
                    <img src={TopBottomArrow} alt="MediBuddy" className="top-bottom-arrow" />
                    <Grid className="inner-junior-to-senior-level">

                        {/*----- Row 1 -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Chief technology officer (CTO)"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid className="ta-center"><img src={VerticalArrow} alt="VerticalArrow" /></Grid>
                        </Grid>

                        {/*----- Row 2 -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Distinguished Engineer - ML"}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"VP - Engineering"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><img src={RotationArrow} alt="RotationArrow" className="arrow-ml-75" /></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 3 -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Distinguished Engineer"}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Director - ML"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Distinguished Engineer"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><img src={RotationArrow} alt="RotationArrow" className="arrow-ml-75" /></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 4 -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Director - App Design"}</Typography></Grid>
                            <Grid></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Director - Software Development"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Sr Principal Application - ML"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Director - Application Security Engineer"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><img src={RotationArrow} alt="RotationArrow" className="arrow-ml-75" /></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 5 -----*/}
                        <Grid className="card-level-grid">
                            <Grid><Typography className="card-txt solid-border-bg">{"Director - Quality Assurance"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager III - Dev Ops"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Director - Systems Engineering"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - III App Dev"}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Manager - III System Design"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Sr. Principal - Software Development"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Principal - ML"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Sr. Principal - Application Security Engineer"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><img src={RotationArrow} alt="RotationArrow" className="arrow-ml-75" /></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 6 -----*/}
                        <Grid className="card-level-grid">
                            <Grid><Typography className="card-txt solid-border-bg">{"Principal - Quality Assurance"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager II - Dev Ops"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - II Systems Engineering"}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Manager - II App Dev"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - II System Design"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Principal - Software Development"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - II ML"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Principal - Application Security Engineer"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid><img src={RotationArrow} alt="RotationArrow" className="arrow-ml-75" /></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 7 -----*/}
                        <Grid className="card-level-grid">
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - Quality Assurance"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager I - Dev Ops"}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Manager - I Systems Engineering"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - I App Dev"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - I System Design"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - Software Development"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - I ML"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Manager - Application Security Engineer"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid><img src={RotationArrow} alt="RotationArrow" className="arrow-ml-75" /></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 8 -----*/}
                        <Grid className="card-level-grid">
                            <Grid><Typography className="card-txt solid-border-bg">{"Quality Assurance - II "}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Dev Ops - II "}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"System Design - II "}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"App Developers - II"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"System Design - II"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Software Development engineer - II"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Machine Learning Engineering - II"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Application Security Engineer - II"}</Typography></Grid>
                        </Grid>

                        {/*----- Arrow Row -----*/}
                        <Grid className="card-level-grid">
                            <Grid></Grid>
                            <Grid className="ta-center"><img src={VerticalArrow} alt="VerticalArrow" /></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                            <Grid></Grid>
                        </Grid>

                        {/*----- Row 9 -----*/}
                        <Grid className="card-level-grid">
                            <Grid><Typography className="card-txt solid-border-bg">{"Quality Assurance - I "}</Typography></Grid>
                            <Grid><Typography className="card-txt dashed-border-bg">{"Dev Ops - I "}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"System Design - I "}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"App Developers - I"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"System Design - I"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Software Development engineer - I"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Machine Learning Engineering - I"}</Typography></Grid>
                            <Grid><Typography className="card-txt solid-border-bg">{"Application Security Engineer - I"}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <img src={TopBottomArrow} alt="MediBuddy" className="top-bottom-arrow" />

                    <Grid className="footer-level-grid">
                        <Grid><Typography className="footer-txt">{"Quality Assurance"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"Dev Ops"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"Systems Engineering"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"App Development"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"System Design"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"Software Development"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"Machine Learning"}</Typography></Grid>
                        <Grid><Typography className="footer-txt">{"Application Security"}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid className="d-flex">
                    <img src={RightTopArrow} alt="MediBuddy" className="right-top-arrow" />
                    <Typography className="top-txt-vertical">{"Junior to Senior Level"}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(JuniorToSeniorLevel);
