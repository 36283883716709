import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import CandidateTable from './components/CandidateTable';
import ProcessBg from "../../../../assets/images/CandidateAssessment/processBg.svg";
import CountBg from "../../../../assets/images/CandidateAssessment/countBg.svg";
import { HiOutlineChevronDown } from "react-icons/hi2";


const CandidateAssessment = () => {

  return (
    <div className="candidate-assessment">
      <Grid container>
        {/*----- Heading Title -----*/}
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Candidate Assessment"}</Typography>
        </Grid>

        {/*----- Process for Candidate Assessment Card -----*/}
        <Grid item xs={12} sm={12} md={12} className="process-candidate-card">
          <Typography className="assessment-title">{"Process for Candidate Assessment"}</Typography>

          <Grid container>
            {/*----- Assign competency -----*/}
            <Grid item xs={12} sm={3} md={3} className="process-bg-grid">
              <img src={ProcessBg} alt="Process Bg" className="process-bg" />
              <Grid className="overlay-text">
                <Typography className="process-title">{"Assign competency"}</Typography>
                <Typography className="process-sub-title">{"(Assigned)"}</Typography>
              </Grid>
              <img src={CountBg} alt="Count Bg" className="overlay-img" />
              <Grid className="count-overlay">
                <Typography className="count-txt">{"1"}</Typography>
              </Grid>
            </Grid>

            {/*----- Schedule Interview -----*/}
            <Grid item xs={12} sm={3} md={3} className="process-bg-grid">
              <img src={ProcessBg} alt="Process Bg" className="process-bg" />
              <Grid className="overlay-text">
                <Typography className="process-title">{"Schedule Interview"}</Typography>
                <Typography className="process-sub-title">{"(Scheduled)"}</Typography>
              </Grid>
              <img src={CountBg} alt="Count Bg" className="overlay-img" />
              <Grid className="count-overlay">
                <Typography className="count-txt">{"2"}</Typography>
              </Grid>
            </Grid>

            {/*----- View  Interview Feedback -----*/}
            <Grid item xs={12} sm={3} md={3} className="process-bg-grid">
              <img src={ProcessBg} alt="Process Bg" className="process-bg" />
              <Grid className="overlay-text">
                <Typography className="process-title">{"View  Interview Feedback"}</Typography>
                <Typography className="process-sub-title">{"(Feedback)"}</Typography>
              </Grid>
              <img src={CountBg} alt="Count Bg" className="overlay-img" />
              <Grid className="count-overlay">
                <Typography className="count-txt">{"3"}</Typography>
              </Grid>
            </Grid>

            {/*----- Schedule Interview debrief -----*/}
            <Grid item xs={12} sm={3} md={3} className="process-bg-grid">
              <img src={ProcessBg} alt="Process Bg" className="process-bg" />
              <Grid className="overlay-text">
                <Typography className="process-title">{"Schedule Interview debrief"}</Typography>
                <Typography className="process-sub-title">{"(----------)"}</Typography>
              </Grid>
              <img src={CountBg} alt="Count Bg" className="overlay-img" />
              <Grid className="count-overlay">
                <Typography className="count-txt">{"4"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*----- Candidate Assessment Table -----*/}
        <Grid item xs={12} sm={12} md={12}>
          <CandidateTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default CandidateAssessment;
