import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import pencile from "../../../../assets/images/pencile.png";
import plusIcon from "../../../../assets/images/plusIcon.png";
import bluedot from "../../../../assets/images/blueDot.png";
import downIcon from "../../../../assets/images/downArrow.png";

const WorkAndEducation = ({ }) => {
  const dispatch = useDispatch();

  return (
    <div>
      {/*----- Work Experience 7 Years 3 Months -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading">Work Experience <span> | 7 Years 3 Months</span></Typography>
          <div className="d-flex">
            <img src={plusIcon} alt="MediBuddy" />
            <img className="padding-left" src={pencile} alt="MediBuddy" />
          </div>
        </div>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">Manager</Typography>
                  <Typography className="certification-description">Bridgestone Corporation, Las Vegas, USA</Typography>
                  <Typography className="certification-date-level">Mar 2023 - Present  <span>|</span>  Full Time</Typography>
                </div>
              </div>

              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">Associate Manager</Typography>
                  <Typography className="certification-description">Capegemini India Pvt. Ltd, Pune, India</Typography>
                  <Typography className="certification-date-level">Mar 2021 - Feb 2023 <span>|</span> Full Time  <span>|</span>  Remote</Typography>
                </div>
              </div>

              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">Junior Manager</Typography>
                  <Typography className="certification-description">Bridgestone Corporation, Las Vegas, USA</Typography>
                  <Typography className="certification-date-level">Mar 2020 - Feb 2021  <span>|</span>  Full Time</Typography>
                  <Typography className="certification-date-level">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demo the on meaningful without relying on meaningful without without relying on meaningful...<a href="#">View More</a></Typography>
                </div>
              </div>
              <div class="wrap">
                <div class="dropdown readMore">
                  <button class="dropbtn">See More &nbsp;&nbsp;<img src={downIcon} alt="MediBuddy" /></button>
                  <div class="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                    <a href="#">Link 3</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*----- Education -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading">Education </Typography>
          <div>
            <img src={plusIcon} alt="MediBuddy" />
            <img className="padding-left" src={pencile} alt="MediBuddy" />
          </div>
        </div>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">Bachelor of Engineering</Typography>
                  <Typography className="certification-description">Stanford University, California</Typography>
                  <Typography className="certification-date-level">Jul 2011 - Jul 2015</Typography>
                </div>
              </div>

              <div class="wrap">
                <div class="ico-wrap">
                  <img src={bluedot} alt="MediBuddy" />
                </div>
                <div class="text-wrap vcenter">
                  <Typography className="certification-title">Master of Business Administration</Typography>
                  <Typography className="certification-description">Stanford University, California</Typography>
                  <Typography className="certification-date-level">Jul 2015 - Jul 2017</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*----- Skills -----*/}
      <div className="first-row">
        <div className="header-section">
          <Typography className="aboutheading">Skills </Typography>
          <div>
            <img src={plusIcon} alt="MediBuddy" />
            <img className="padding-left" src={pencile} alt="MediBuddy" />
          </div>
        </div>
        <Typography className='skillDesc'>Add any tools, skills and technologies that you have</Typography>
        <div className="main-b eeppage">
          <div className="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="btnList">
                <a href="#" class="btn skillsbtn px-3" role="button">Personal Information</a>
                <a href="#" class="btn skillsbtn px-3" role="button">Work & Education</a>
                <a href="#" class="btn skillsbtn px-3" role="button">Competencies</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WorkAndEducation);
