import Dashboard from "../../../../assets/images/dynamicSidebar/home";
import Profile from "../../../../assets/images/dynamicSidebar/profile";
import SelfAssessment from "../../../../assets/images/dynamicSidebar/performance";
import Givefeedback from "../../../../assets/images/dynamicSidebar/giveFeedback";
import RatingCollab from "../../../../assets/images/dynamicSidebar/msbr";
import MyTeam from "../../../../assets/images/dynamicSidebar/myteam";
import Feedback from "../../../../assets/images/dynamicSidebar/feedback";
import FunctionalLeader from "../../../../assets/images/dynamicSidebar/functionalLeader";
import Comp from "../../../../assets/images/dynamicSidebar/compIcon";
import Goals from "../../../../assets/images/dynamicSidebar/goals";
import InductionIcon from "../../../../assets/images/dynamicSidebar/induction";
import CompIcon from "../../../../assets/images/dynamicSidebar/compensation";
import LeaveIcon from "../../../../assets/images/dynamicSidebar/leave";
import CareerIcon from "../../../../assets/images/dynamicSidebar/career";
import BenefitsIcon from "../../../../assets/images/dynamicSidebar/benefits";
import CommunityIcon from "../../../../assets/images/dynamicSidebar/community";
import RewardIcon from "../../../../assets/images/dynamicSidebar/reward";
import PayIcon from "../../../../assets/images/dynamicSidebar/pay";
import TeamEngagementIcon from "../../../../assets/images/dynamicSidebar/teamEngagement";
import HRIcon from "../../../../assets/images/dynamicSidebar/hr";
import AboutMeIcon from "../../../../assets/images/dynamicSidebar/myCareer";

export const allRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: <Dashboard />,
    permission: "home"
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: <Profile />,
    permission: "view-profile"
  },
  // {
  //   path: "cat-head-aboutMe",
  //   name: "About Me",
  //   icon: <AboutMeIcon />,
  //   permission: "for-demo-purpose",
  //   subcategory: [          
  //     {
  //       path: "/about-me/my-career",
  //       name: "My Career",
  //       icon: <SelfAssessment />,
  //       permission: "for-demo-purpose",
  //     },
  //   ]
  // },
  {
    path: "cat-head-induction",
    name: "Induction",
    icon: <InductionIcon />,
    permission: "induction",
    subcategory: [          
        {
          path: "/Induction/My-Induction",
          name: "My Induction",
          icon: <SelfAssessment />,
        },
        {
          path: "/Induction/My-Team-Induction",
          name: "My Team Induction",
          icon: <SelfAssessment />,
        },
      ]
  },
  {
    path: "/cat-head-goals",
    name: "Goals",
    icon: <Goals />,
    permission: "Goals",
    subcategory: [          
      {
        path: "/employee-goals",
        name: "My Goals",
        icon: <Goals />,
        permission: "my goals"
      },
      {
        path: "/ViewGoalsRequest",
        name: "Team Goals",
        icon: <Goals />,
        permission: "team goals"
      },
      // {
      //   path: "/employee-goals-form/newform",
      //   name: "Goals Form",
      //   icon: <Goals />,
      // },
    ]
  }, 
  {
    path: "/cat-head-Performance-Management",
    name: "Performance Management",
    icon: <SelfAssessment />,
    permission: "performance management",
    subcategory: [   
          {
            path: "/self-assesment/allinone",
            name: "My Assessment",
            icon: <SelfAssessment />,
            permission: "self-assessment"
          },       
          {
            path: "/self-assesment/request-feedback",
            name: "Request Feedback",
            icon: <SelfAssessment />,
            permission: "request-peer-feedback"
          },
          {
            path: "/give-peer-feedback",
            name: "Provide Feedback",
            icon: <SelfAssessment />,
            permission: "give-peer-feedback"
          },
          {
            path: "/self-assesment/aprove",
            name: "Approve 360",
            icon: <SelfAssessment />,
            permission: "approve-page"
          },
          {
            path: "/my-team",
            name: "My Team Performance",
            icon: <SelfAssessment />,
            permission: "my-team"
          },
          {
            path: "/360-report",
            name: "360 Report",
            icon: <FunctionalLeader />,
            permission: "360 Report"
          },
          {
            path: "/my-team-promotion",
            name: "My Team Promotion",
            icon: <SelfAssessment />,
            permission: "my-team-promotion"
          },
          {
            path: "/self-assessment/improvement-plans",
            name: "Improvement Plans",
            icon: <SelfAssessment />,
          },
          {
            path: "/functional-leader",
            name: "Functional Leader",
            icon: <FunctionalLeader />,
            permission: "functional-leader"
          },
          {
            path: "/manager-calibration",
            name: "Manager Calibration",
            icon: <FunctionalLeader />,
            permission: "manager calibration"
          },
    ]
  }, 
  {
    path: "cat-head-comp",
    name: "Compensation",
    icon: <CompIcon />,
    permission: "compenssation",
    subcategory: [          
      {
        path: "/my-compensation",
        name: "My Compensation",
        icon: <Comp />,
        // permission: "compensation-planning"
      },
      {
        path: "/compensation",
        name: "Compensation Planning",
        icon: <Comp />,
        permission: "compensation-planning"
      },
      {
        path: "/compensation-team",
        name: "My Team Compensation",
        icon: <Comp />,
        permission: "compensation-my-team"
      },
    ]
  },   
  {
    path: "cat-head-leave",
    name: "Leave",
    icon: <LeaveIcon />,
    permission: "leave",
    subcategory: [          
      {
        path: "/my-leave",
        name: "My Leave",
        permission: "my-leave",
      },
      {
        path: "/my-team-leave",
        name: "My Team Leave",
        permission: "my-team-leave",
      },
    ]
  },   
  // {
  //   path: "cat-head-community",
  //   name: "My Community",
  //   icon: <CommunityIcon />,
  //   permission: "Community",
  //   subcategory: [          
  //     {
  //       path: "/employee-engagement",
  //       name: "Employee Engagement",
  //       icon: <FunctionalLeader />,
  //       permission: "Employee Engagement",
  //     },    
  //   ]
  // },   
  {
    path: "cat-head-Career",
    name: "Career",
    icon: <CareerIcon />,
    permission: "career",
    subcategory: [          
      {
        path: "/individual-development-plan",
        name: "My IDP",
        icon: <Comp />,
        permission: "my idp"
      },
      {
        path: "/my-team-idp",
        name: "Team IDP",
        icon: <Comp />,
        permission: "team idp"
      },
      {
        // path: "/employee-profile",
        path: "/employee-engagement-program",
        name: "EEP",
        icon: <FunctionalLeader />,
        permission: "EEP"
      },  
      {
        path: "/projrct-market",
        name: "Project Market",
        icon: <Comp />,
      },
      {
        path: "/internal-jobs",
        name: "Internal Jobs",
        icon: <Comp />,
      },
      {
        path: "/about-me/my-career",
        name: "My Career Path",
        icon: <SelfAssessment />,
        permission: "for-demo-purpose",
      },
    ]
  },  
  {
    path: "/My-Benefits",
    name: "My Benefits",
    icon: <BenefitsIcon />,
  },
  {
    path: "/employee-engagement",
    name: "My Community",
    icon: <CommunityIcon />,
    permission: "Community",
  },
  {
    path: "/My-Rewards",
    name: "My Rewards",
    icon: <RewardIcon />,
  },
  {
    path: "/my-team-page",
    name: "My Team",
    icon: <MyTeam />,
    permission: "outer-my-team-page"
  },
  {
    path: "/My-Pay",
    name: "My Pay",
    icon: <PayIcon />,    
  },
  {
    path: "/My-Team-Engagement",
    name: "My Team Engagement",
    icon: <TeamEngagementIcon />,
  },
  {
    path: "/succession-plan",
    name: "My Team Succession",
    icon: <TeamEngagementIcon />,
    permission: "team succession"
  },
  {
    path: "cat-head-Recruitment",
    name: "My Recruitment",
    icon: <HRIcon />,
    permission: "HR",
    subcategory: [          
     
      {
        path: "/my-requisitions",
        name: "My Requisitions (For Hiring Manager)",
        icon: <Givefeedback />,
        permission: "HR" 
      } ,
      {
        path: "/hr-my-interviews",
        name: "My Interviews (For Interviewers)",
        icon: <Givefeedback />,
        permission: "HR" 
      } ,
    ]
  },
  {
    path: "cat-head-HR",
    name: "HR",
    icon: <HRIcon />,
    permission: "HR",
    subcategory: [          
     
      {
        path: "/hrb",
        name: "Calibration",
        icon: <Givefeedback />,
        permission: "rating-calibration" 
      } ,  
      {
        path: "/Compensation-analytics",
        name: "Compensation Analytics",
        icon: <Givefeedback />,
        permission: "compensation-analytics" 
      } ,
      {
        path: "/Talent-analytics",
        name: "Talent Analytics",
        icon: <Givefeedback />,
      } ,
      {
        path: "/Transfers",
        name: "Transfers",
        icon: <Givefeedback />,
      } ,
      {
        path: "/Engagement-Survey",
        name: "Engagement Survey",
        icon: <Givefeedback />,
      } ,
      {
        path: "/Case-Management",
        name: "Case Management",
        icon: <Givefeedback />,
      } ,
      {
        path: "/Onboarding",
        name: "Onboarding",
        icon: <Givefeedback />,
      } ,
      {
        path: "/Goals-review",
        name: "Goals review",
        icon: <Givefeedback />,
      } ,
      {
        path: "/hr-succession-plan",
        name: "Succession Plans",
        icon: <Givefeedback />,
        permission: "succession plan"
      } ,
      {
        path: "/candidate-assessment",
        name: "Candidate Assessment",
        icon: <Givefeedback />,
        permission: "HR"
      } ,
    ]
  } ,
  // {
  //   path: "/",
  //   name: "Admin",
  //   icon: <TeamEngagementIcon />,
  //   permission: "outer-my-team-page"
  // },





    // {
    //     path: "/dashboard",
    //     name: "Home",
    //     icon: <Dashboard />,
    //   },
    //   {
    //     path: "/profile",
    //     name: "My Profile",
    //     icon: <Profile />,
    //   },
    //   {
    //     path: "/self-assesment/allinone",
    //     name: "My Assessment",
    //     icon: <SelfAssessment />,
    //   },
    //   {
    //     path: "cat-head",
    //     name: "Feedback",
    //     icon: <Feedback />,
    //     subcategory: [          
    //       {
    //         path: "/self-assesment/request-feedback",
    //         name: "Request Feedback",
    //         icon: <SelfAssessment />,
    //       },
    //       {
    //         path: "/self-assesment/aprove",
    //         name: "Approve",
    //         icon: <SelfAssessment />,
    //       },
    //       {
    //         path: "/give-peer-feedback",
    //         name: "Give Feedback",
    //         icon: <Givefeedback />,
    //       } ,
    //     ]
    //   } ,     
      
      
    //   // {
    //   //   path: "/feedback",
    //   //   name: "My Team",
    //   //   icon: <MyTeam />,
    //   // },

    //   {
    //     path: "/my-team",
    //     name: "My Team",
    //     icon: <MyTeam />,
    //   },
      
    //   {
    //     path: "/hrb",
    //     name: "Rating Calibration",
    //     icon: <RatingCollab />,
    //   },
    //   {
    //     path: "/manager-calibration",
    //     name: "Manager Calibration",
    //     icon: <FunctionalLeader />,
    //   },
    //   {
    //     path: "/functional-leader",
    //     name: "Functional Leader",
    //     icon: <FunctionalLeader />,
    //   },
    //   {
    //     // path: "/employee-profile",
    //     path: "/employee-engagement-program",
    //     name: "EEP",
    //     icon: <FunctionalLeader />,
    //   },  
    //   {
    //     path: "/individual-development-plan",
    //     name: "IDP",
    //     icon: <FunctionalLeader />,
    //   },    
    //   {
    //     path: "/succession-plan",
    //     name: "Succession Plan",
    //     icon: <FunctionalLeader />,
    //   },
    //   {
    //     path: "/cat-head-goals",
    //     name: "Goals",
    //     icon: <Goals />,
    //     subcategory: [          
    //       {
    //         path: "/employee-goals",
    //         name: "My Goals",
    //         icon: <Goals />,
    //       },
    //       {
    //         path: "/ViewGoalsRequest",
    //         name: "Team Goals",
    //         icon: <Goals />,
    //       },
    //       {
    //         path: "/employee-goals-form/newform",
    //         name: "Goals Form",
    //         icon: <Goals />,
    //       },
    //     ]
    //   },   
    //   {
    //     path: "cat-head-comp",
    //     name: "Compensation",
    //     icon: <Comp />,
    //     subcategory: [          
    //       {
    //         path: "/compensation",
    //         name: "Planning",
    //         icon: <Comp />,
    //       },
    //       {
    //         path: "/compensation-team",
    //         name: "My Team",
    //         icon: <Comp />,
    //       },
    
    //       {
    //         path: "/Compensation-analytics",
    //         name: "Analytics",
    //         icon: <Comp />,
    //       },
    //     ]
    //   },   
     
];

export const prodRoutes = [   
     {
        path: "/profile",
        name: "My Profile",
        icon: <Profile />,
      },  
      {
        path: "/feedback",
        name: "My Team",
        icon: <MyTeam />,
      },
     
]

