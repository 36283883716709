import { alpha, Checkbox, CircularProgress, FormControl, FormHelperText, InputBase, Skeleton, styled, TextField } from "@mui/material";
import UserProfile1Icon from "../../../assets/images/goalView/userProfile1.svg";
import UserProfile2Icon from "../../../assets/images/goalView/userProfile2.svg";
import CommentSendBtnIcon from "../../../assets/images/goalView/commentSendBtn.svg";
import ExpandAnyGoalBgIcon from "../../../assets/images/goalView/expandAnyGoalBg.svg";
import ExpandAnyGoalIcon from "../../../assets/images/goalView/expandAnyGoal.svg";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import APIList from "../../../api";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { isEmptyNullUndefined} from "../../utils/utils";
import moment from "moment";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import filterIcon from "../../../assets/images/goalView/commentFilter.png";
import SideDrawer from "../sideDrawer";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    // border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.4rem !important",
    width: '100%',
    backgroundColor: "var(--graybg)",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 


const Comments = ({data, addComment, id, addCommentLoader, comment, setComment, setGetCommentPayload}) => {   

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    
    const lastCommentRef = useRef(null); // Create a ref for the last comment

    const [recentComment, setRecentComment] = useState(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [showAllSearches, setShowAllSearches] = useState(false);
    const [searchedList, setSearchedList] = useState([]);
    const [totalSearchPages, setTotalSearchPages] = useState(1);
    const [selectedSearches, setSelectedSearches] = useState([]);

    const [fromDate, setFromDate] = useState(null);
    const [fromDateErr, setFromDateErr] = useState(false);
    const [toDate, setToDate] = useState(null);
    const [toDateErr, setToDateErr] = useState(false);

    useEffect(() => {
        setRecentComment(null);
        if (lastCommentRef.current) {
            lastCommentRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to the last comment
        }
    }, [data]); // Run effect when data changes

    useEffect(() => {
        if (lastCommentRef.current) {
            lastCommentRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to the last comment
        }
    }, [recentComment]); // Run effect when data changes

    useEffect(() => {
        setSearchLoader(true);
    const getSearch = setTimeout(() => {
            if(!isEmptyNullUndefined(search) && search?.length > 2){                
            APIList.getCohostSearchPeers({
                payload : {
                    employeeEligibilityDTO : {},
                    keyword : search,
                    employeeId: employeeDetails?.id,
                    managerId: employeeDetails?.manager?.id,
                    companyId : employeeDetails?.company?.id
                },
                page: 0,
                size: showAllSearches ? 10000 : 3
            })  
            .then((res) => {
                setSearchedList(res?.data?.data);
                setTotalSearchPages(res?.data?.totalPages);
                setSearchLoader(false);
            })
            .catch((err) => {
                setSearchLoader(false);
            }
            )
        // }         
        } else {
            setSearchedList(null);
            setSearchLoader(false);
        }
          
    }, 500);    

    return () => clearTimeout(getSearch)
  }, [search, showAllSearches])

  const autoSearchText = (e) => {
    setShowAllSearches(false);
    setSearch(e.target.value);
  }

    const handleRecentComment = (comment) => {
        let newCommentData = {
            comment: comment,
            commentByEmployeeId: employeeDetails?.id,
            commentTimeStamp: Date.now(),
            employeeDesignation: employeeDetails?.employeeDesignation,
            employeeName: employeeDetails?.name,
            goalEmployeeId: "xyz",
            id: "uuidv4()",
            profilePicPath: employeeDetails?.profilePhoto?.path,
            read: null
        }
        setRecentComment(newCommentData);
        addComment(comment);
    }
// console.log("selectedSearches", fromDate, toDate);
    const handleSelectEmpForFilter = (emp) => {
        let temp = structuredClone(selectedSearches);
        if(temp?.some(selectedEmp => selectedEmp?.employeeId === emp?.employeeId)){
            temp = temp?.filter(selectedEmp => selectedEmp?.employeeId !== emp?.employeeId);
        } else {
            temp.push(emp);
        }
        setSelectedSearches(temp);
    }

    const handleApplyFilter = (action) => {
       
        if(action === "apply"){
            if(isEmptyNullUndefined(fromDate)){
                setFromDateErr(true);
                return;
            } else {
                setFromDateErr(false);
            }
            if(isEmptyNullUndefined(toDate)){
                setToDateErr(true);
                return;
            } else {
                setToDateErr(false);
            }
            setGetCommentPayload({
                employeeIds : selectedSearches?.length > 0 ? [employeeDetails?.id, ...selectedSearches?.map(x => x?.employeeId)] : [], 
                fromDate : fromDate, 
                toDate : toDate, 
                sortByDate : "asc"});
            setIsFilterOpen(false);
        } else {
            setGetCommentPayload({employeeIds : [], fromDate : null, toDate : null, sortByDate : "asc"});
            setIsFilterOpen(false);
            setFromDate(null);
            setToDate(null);
            setSelectedSearches([]);
        }
    }

    return(    
     <div className="comments-card-layout">
         <div className="comment-header-outer">
            <div className="comment-header-inner">
                <p className="comments-label">Comments</p>
                <p className="comment-count">{data?.length}</p>
             </div>
             <img className="filter-icon" src={filterIcon} onClick={() => setIsFilterOpen(true)} alt="filterIcon" />
         </div>

         {
            !isEmptyNullUndefined(id) && isEmptyNullUndefined(data) && 
            <div className="no-comment"> No comments found </div>
         }

         {/*----- Comment Card 1 -----*/}
         <div className="all-comments-wrapper">
         { 
            
            !isEmptyNullUndefined(id) && !isEmptyNullUndefined(data) &&  
                    
                    data?.map((comment, index) => {
                        return(
                            <div className={`comment-user-card`}
                             key={comment?.id}
                             ref={isEmptyNullUndefined(recentComment) && index === data.length - 1 ? lastCommentRef : null} // Set ref on the last comment
                            >
                                 <div className="d-flex">
                                     <div className="user-profile">
                                         <div className="empPic">
                                             {comment?.profilePicPath ? 
                                                 <img src={comment?.profilePicPath} className="userImg" alt="User" />
                                                 :
                                                 <Avatar 
                                                     name={comment?.employeeName} 
                                                     size="38" 
                                                     className="userImg"
                                                     color={"#00425A"}    
                                                 />
                                             }
                                         </div>
                                     </div>
                                     <div className="user-div">
                                         <p className="userName">{comment?.employeeName}</p>
                                         <p className="userPost">{comment?.employeeDesignation}</p>
                                     </div>
                                 </div>
                 
                                 <p className="user-comment">{comment?.comment}</p>
                 
                                 <p className="comment-date">{moment(comment?.commentTimeStamp).format('DD MMM YYYY, hh:mmA')}</p>
                                                 
                             </div>
                        )
                     })
         }
         {
            recentComment &&
            <div className={`comment-user-card recently-added-comment`}
            // key={comment?.id}
            ref={lastCommentRef} // Set ref on the last comment
           >
                <div className="d-flex">
                    <div className="user-profile">
                        <div className="empPic">
                            {recentComment?.profilePicPath ? 
                                <img src={recentComment?.profilePicPath} className="userImg" alt="User" />
                                :
                                <Avatar 
                                    name={recentComment?.employeeName} 
                                    size="38" 
                                    className="userImg"
                                    color={"#00425A"}    
                                />
                            }
                        </div>
                    </div>
                    <div className="user-div">
                        <p className="userName">{recentComment?.employeeName}</p>
                        <p className="userPost">{recentComment?.employeeDesignation}</p>
                    </div>
                </div>

                <p className="user-comment">{recentComment?.comment}</p>

                <p className="comment-date">{moment(recentComment?.commentTimeStamp).format('DD MMM YYYY, hh:mmA')}</p>

                   <CircularProgress sx={{position: "absolute", top: "43%", left: "43%", transform: "translate(-50%, -50%)"}} />
                
            </div>
         }
        
         </div>

         {/*----- Expand any goal to view its comments. Card -----*/}
         {
            isEmptyNullUndefined(id) && 
            <div className="comment-user-card">
             <div className="expandAnyGoal-Icon-div">
                 <img
                     src={ExpandAnyGoalBgIcon}
                     alt="ExpandAnyGoalBgIcon"
                     height={90}
                     width={90}
                     className="background-Icon"
                 />
                 <img
                     src={ExpandAnyGoalIcon}
                     alt="ExpandAnyGoalIcon"
                     height={73}
                     width={80}
                     className="expandAnyGoal-Icon"
                 />
             </div>
             <p className="expandAnyGoal">Expand any goal to view its comments.</p>
            </div>
         }
         

         {/*----- Border -----*/}
         <div className="comment-border" />

         {/*----- Comment TextField -----*/}
         {
            !isEmptyNullUndefined(id) && 
            <div className="comment-field-div">
             <TextField
                 placeholder="Enter your comment..."
                 variant="outlined"
                 size="small"
                 fullWidth
                 multiline
                 maxRows={2}
                 value={comment}
                 onChange={(e) => setComment(e.target.value)}                 
                 onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        addComment(comment);
                    }
                }}
                 sx={{
                     "& .MuiOutlinedInput-root": {
                         "& fieldset": {
                             border: "none",
                         },
                     },
                 }}
                 InputProps={{
                     endAdornment: (
                         <InputAdornment position="end">
                             <IconButton
                                 edge="end"
                                 color="primary"
                                 onClick={() => handleRecentComment(comment)}
                                 disabled={addCommentLoader}
                             >
                                 <img
                                     src={CommentSendBtnIcon}
                                     alt="CommentSendBtnIcon"
                                 />
                             </IconButton>
                         </InputAdornment>
                     ),
                 }}
             />
            </div>
         }

         {
            isFilterOpen &&
            <div className="comment-filter-sidedrawer-outer">
                <SideDrawer isOpen={isFilterOpen} setIsOpen={setIsFilterOpen} >
                    <div className="filter-sidedrawer-inner">
                        <div className="filter-header">
                        Comments Filter
                        </div>
                        <div className="filter-body">
                            {
                                !isEmptyNullUndefined(search) && search?.length < 3 &&
                                <div className="search-char-msg mt-4">
                                    <p>Please enter at least 3 characters to search.</p>
                                </div>
                            }
                                    <Search>
                                        <SearchIconWrapper>
                                            <IoMdSearch />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search people"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={autoSearchText}
                                            value={search}
                                        />
                                        {
                                            !isEmptyNullUndefined(search) &&
                                            <IoCloseSharp onClick={() => { setSearch(""); setShowAllSearches(false) }} style={{ position: "absolute", right: "1rem", top: "34%", color: "gray", cursor: "pointer" }} />
                                        }

                                        {

                                            !isEmptyNullUndefined(search) && search?.length > 2 &&
                                            <div className="search-popup-req-feed">
                                                {
                                                    searchLoader ?
                                                        <>
                                                            <div className="search-emp-outer">
                                                                <div className="empDetaila">
                                                                    <div className="empPic">
                                                                        <Skeleton variant="circular" width={40} height={40} />
                                                                    </div>
                                                                    <div className="userInfo">
                                                                        <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width: "7rem" }} /></p>
                                                                        <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width: "3rem" }} /></p>
                                                                    </div>
                                                                </div>
                                                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width: "2rem" }} /></p>
                                                            </div>
                                                            <div className="search-emp-outer">
                                                                <div className="empDetaila">
                                                                    <div className="empPic">
                                                                        <Skeleton variant="circular" width={40} height={40} />
                                                                    </div>
                                                                    <div className="userInfo">
                                                                        <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width: "7rem" }} /></p>
                                                                        <p className="designation"><Skeleton variant="text" sx={{ fontSize: '0.5rem', width: "3rem" }} /></p>
                                                                    </div>
                                                                </div>
                                                                <p className="name"><Skeleton variant="text" sx={{ fontSize: '1.5rem', width: "2rem" }} /></p>
                                                            </div>
                                                        </>
                                                        :
                                                        searchedList?.length > 0 ?
                                                            <div>
                                                                {searchedList?.map((emp, index) => {
                                                                    return (
                                                                        // activeTab === "send" &&

                                                                        <div key={index} className="search-emp-outer">
                                                                            <div className="empDetaila">
                                                                                <div className="empPic">
                                                                                    {emp?.profilePhotoPath ?
                                                                                        <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                                                        :
                                                                                        <Avatar
                                                                                            name={emp?.employeeName}
                                                                                            size="40"
                                                                                            className="userImg"
                                                                                            color={"#00425A"}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                                <div className="userInfo">
                                                                                    <p className="name">{emp?.employeeName}</p>
                                                                                    <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                                                                </div>
                                                                            </div>
                                                                            {

                                                                                <Checkbox
                                                                                    checked={selectedSearches?.some(selectedEmp => selectedEmp?.employeeId === emp?.employeeId)}
                                                                                    onChange={() => handleSelectEmpForFilter(emp)}
                                                                                />

                                                                                // <button className="search-add-emp-btn" 
                                                                                // // onClick={() => handleChangeSessionInfo(emp, "addHost")}
                                                                                // >
                                                                                //     Add
                                                                                // </button>
                                                                            }

                                                                        </div>

                                                                    )
                                                                })
                                                                }
                                                                {
                                                                    !showAllSearches && (totalSearchPages > 1) && <div className="view-all-search" onClick={() => setShowAllSearches(true)}>View all</div>
                                                                }
                                                            </div>
                                                            :
                                                            !isEmptyNullUndefined(search) && search?.length > 2 && !searchLoader &&
                                                            <div className="no-result">No result</div>
                                                }
                                            </div>
                                        }
                                    </Search>
                                    {/* {sessionPayloadErr?.coHost?.isError && (
                                        <FormHelperText sx={{ color: "#d32f2f" }}>
                                            {sessionPayloadErr?.coHost?.errMsg}
                                        </FormHelperText>
                                    )} */}

                                    <div className="searched-selected-emp-outer">
                                        {selectedSearches?.map((emp, index) => {
                                            return <div key={index} className="searched-selected-emp-inner">{emp?.employeeName} <IoCloseSharp onClick={() => handleSelectEmpForFilter(emp)} /></div>
                                        })}
                                    </div>

                                    <div className="date-filter-outer">
                                    <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                        label="From Date"
                                        value={fromDate}
                                        maxDate={new Date()}
                                        onChange={(event) => setFromDate(event)}
                                            sx={{
                                            backgroundColor: "var(--graybg)",
                                            width: "100%",
                                            borderRadius: "8px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px",
                                                border: "none",
                                            },
                                            }}
                                            name="fromDate"
                                            renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                variant="outlined"
                                                sx={{
                                                pointerEvents: "none",
                                                "& .MuiOutlinedInput-root": {
                                                    button: {
                                                    pointerEvents: "all",
                                                    },
                                                },
                                                mt: "6px",
                                                width: "100%",
                                                backgroundColor: "#F9F9F9",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderRadius: "8px",
                                                    border: "none",
                                                },
                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                    backgroundColor: "#ffffff",
                                                    margin: "0px",
                                                    paddingLeft: "0.5rem",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    border: "none",
                                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                                    borderRadius: "8px",
                                                    button: {
                                                    pointerEvents: "all",
                                                    }
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "gray",
                                                    opacity: 1,
                                                },
                                                }}
                                                {...params}
                                            />
                                            )}
                                        />
                                        </LocalizationProvider>
                                        {
                                            fromDateErr &&
                                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                                Please select a From Date                                            
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                    <div className="date-filter-to-text">
                                       <span className="horizontal-line-text"></span>
                                       To
                                       <span className="horizontal-line-text"></span>
                                    </div>
                                    <FormControl sx={{ width: { md: "100%", sm: "100%" } }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                        label="To Date"
                                        value={toDate}
                                        minDate={fromDate || new Date()}
                                        maxDate={new Date()}
                                        onChange={(event) => setToDate(event)}
                                            sx={{
                                            backgroundColor: "var(--graybg)",
                                            width: "100%",
                                            borderRadius: "8px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "8px",
                                                border: "none",
                                            },
                                            }}
                                            name="fromDate"
                                            renderInput={(params) => (
                                            <TextField
                                                size={"small"}
                                                variant="outlined"
                                                sx={{
                                                pointerEvents: "none",
                                                "& .MuiOutlinedInput-root": {
                                                    button: {
                                                    pointerEvents: "all",
                                                    },
                                                },
                                                mt: "6px",
                                                width: "100%",
                                                backgroundColor: "#F9F9F9",
                                                borderRadius: "8px",
                                                "& fieldset": {
                                                    borderRadius: "8px",
                                                    border: "none",
                                                },
                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                    backgroundColor: "#ffffff",
                                                    margin: "0px",
                                                    paddingLeft: "0.5rem",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    border: "none",
                                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                                    borderRadius: "8px",
                                                    button: {
                                                    pointerEvents: "all",
                                                    }
                                                },
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "gray",
                                                    opacity: 1,
                                                },
                                                }}
                                                {...params}
                                            />
                                            )}
                                        />
                                        </LocalizationProvider>
                                        {
                                            toDateErr &&
                                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                                Please select a To Date                                            
                                            </FormHelperText>
                                        }
                                        
                                    </FormControl>
                                    </div>

                                    <div className="date-filter-btn-outer">

                                        <button className="reset-btn" onClick={() => { handleApplyFilter("reset") }}>Reset</button>
                                        <button className="apply-btn" onClick={() => { handleApplyFilter("apply") }}>Apply</button>
                                        
                                    </div>
                        </div>
                    </div>
                </SideDrawer>
            </div>
         }
         

     </div>
    )
}

export default Comments;