import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

    LineController, // Add LineController import
    LineElement, // Add LineElement import
  } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { isEmptyNullUndefined } from '../../utils/utils';
import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

        LineController, // Register LineController
        LineElement, // Register LineElement
  );
  
  export const options = {
    responsive: true,
    tension: 0.34,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
        y: {
          beginAtZero: true,
          suggestedMax: 100,
        //   ticks: {
        //     stepSize: 10,
        //   },
          title: {
            display: true,
            text: 'Percentage ( % )',
            font: {
                size: 16,
                family:'poppins'
            }
          },
          ticks: {
            stepSize: 10,
            font: {
                size: 16,
                family:'poppins'
            }
        }
        },
        x: {
            ticks: {
                font: {
                    size: 16,
                    family:'poppins'
                }
            }
        },
        // rightYAxis: {
        //   position: 'right',
        //   beginAtZero: true,
        //   suggestedMax: 100,
        //   ticks: {
        //     stepSize: 10,
        //   },
        // },
      },

  };



const LineGraph = ({data, preData, ratingType}) => {


    const tempgraphdata = {
        labels: [],
        datasets: []
    }

    const getTotalEmpForRating = (rating, rdata) => {
        let tot = 0;
        if(ratingType === "mbb"){
        rdata?.forEach((x) => {
            if (x?.mbbRating === rating) {
                tot = tot + x?.employees?.length;
            }
        })
        } else {
            rdata?.forEach((x) => {
                if (x?.preformanceRating === rating) {
                    tot = tot + x?.employees?.length;
                }
            })
        }

        return tot;
    }

    useEffect(() => {
        
        let tempResData = []
        let tempPreResData = []

        let graphLine = {
            type: 'line',
            label: ratingType === "mbb" ? 'MBB rating curve' : 'OKR rating curve',
            data: [],
            // "yAxisID": "rightYAxis"
        }
        let graphLineForData = {
            type: 'line',
            label: ratingType === "mbb" ? 'MBB employee curve post' : 'OKR employee curve post',
            data: [],
            // "yAxisID": "rightYAxis",
            backgroundColor: "green",
            borderColor: "green",
        }
        let preGraphLineForData = {
            type: 'line',
            label: ratingType === "mbb" ? 'MBB employee curve pre' : 'OKR employee curve pre',
            data: [],
            // "yAxisID": "rightYAxis",
            backgroundColor: "#ffe200",
            borderColor: "#ffe200",
        }

        if(!isEmptyNullUndefined(data)) {

        // } else {
            tempResData = JSON.parse(JSON.stringify(data))
        }
        if(!isEmptyNullUndefined(preData)) {

        // } else {
            tempPreResData = JSON.parse(JSON.stringify(preData))
        }
        
        const totalEmployees = tempResData?.reduce((total, item) => total + item?.employees?.length, 0)

        if(ratingType === "mbb"){

            const mbbRankingSortedData = tempResData?.sort((a, b) => {
                return a?.mbbRanking - b?.mbbRanking;
            });

            const Tempmbb =[];
            mbbRankingSortedData?.forEach((item) => {
                if(!Tempmbb.includes(item?.mbbRating)){
                    Tempmbb.push(item?.mbbRating);
                }
            });

            // console.log('data', data)
            Tempmbb?.forEach((y) => {
                const nu = Number(totalEmployees) === 0 ? 0 : (Number(Number(getTotalEmpForRating(y, tempResData))/Number(totalEmployees)) * 100).toFixed(2); // in percent
                // const nu = getTotalEmpForRating(y, tempResData); //in numbers
                graphLineForData.data.push(nu);

                const preNu = Number(totalEmployees) === 0 ? 0 : (Number(Number(getTotalEmpForRating(y, tempPreResData))/Number(totalEmployees)) * 100).toFixed(2); // in percent
                preGraphLineForData.data.push(preNu);

                tempgraphdata.labels.push(`${y}(${getTotalEmpForRating(y, tempResData)})`)
            })
        
            Tempmbb?.forEach((y) => {
                if(!isEmptyNullUndefined(tempPreResData?.filter(x => x?.mbbRating === y)[0]?.mbbRatingCurve)){
                graphLine.data.push(tempPreResData?.filter(x => x?.mbbRating === y)[0]?.mbbRatingCurve);      
                } else {
                    graphLine.data.push(0);
                }          
            })

        } else {
            
            const performanceRankingSortedData = tempResData?.sort((a, b) => {
                return a?.preformanceRanking - b?.preformanceRanking;
            });

            const Tempperformance =[];
            performanceRankingSortedData?.forEach((item) => {
                if(!Tempperformance.includes(item?.preformanceRating)){
                    Tempperformance.push(item?.preformanceRating);
                }
            });

            // console.log('data', data)
            Tempperformance?.forEach((y) => {
                const nu = Number(totalEmployees) === 0 ? 0 : (Number(Number(getTotalEmpForRating(y, tempResData))/Number(totalEmployees)) * 100).toFixed(2); // in percent
                // const nu = getTotalEmpForRating(y, tempResData); //in numbers
                graphLineForData.data.push(nu);

                const preNu = Number(totalEmployees) === 0 ? 0 : (Number(Number(getTotalEmpForRating(y, tempPreResData))/Number(totalEmployees)) * 100).toFixed(2); // in percent
                preGraphLineForData.data.push(preNu);

                tempgraphdata.labels.push(`${y}(${getTotalEmpForRating(y, tempResData)})`)
            })
        
            Tempperformance?.forEach((y) => {
                if(!isEmptyNullUndefined(tempPreResData?.filter(x => x?.preformanceRating === y)[0]?.preformanceRatingCurve)){
                graphLine.data.push(tempPreResData?.filter(x => x?.preformanceRating === y)[0]?.preformanceRatingCurve); 
                } else {
                    graphLine.data.push(0);
                }               
            })

        }

        tempgraphdata.datasets.push(graphLine)
        tempgraphdata.datasets.push(graphLineForData)
        tempgraphdata.datasets.push(preGraphLineForData)


        setGraphdata(() => tempgraphdata)

    },[])

    const [graphdata, setGraphdata] = useState({
        labels: [],
        datasets: []
    })

    const [showGraphTab, setShowGraphTab] = useState("Hide Graph");  

    return(
        <div>
            <div style={{justifyContent: 'flex-end'}} className="tabs-outer">
                <div className="tabs">
                    <button className={`tab ${showGraphTab === "Show Graph" ? "active" : ""}`} onClick={() => setShowGraphTab("Show Graph")}>Show Graph</button>
                    <button className={`tab ${showGraphTab === "Hide Graph" ? "active" : ""}`} onClick={() => setShowGraphTab("Hide Graph")}>Hide Graph</button>
                </div>
            </div> 
            <div>
                <Collapse in={showGraphTab === "Show Graph"}>
                    <Bar options={options} data={graphdata} />
                </Collapse>
            </div> 
            {/* <div><Bar options={options} data={graphdata} /></div>  */}
        </div>
    )
}

LineGraph.propTypes = {
    data: PropTypes.object,
    preData: PropTypes.object,
    ratingType: PropTypes.string,
  };

export default LineGraph;