import { IoIosArrowDown, IoMdSearch } from "react-icons/io";
import { Search, SearchIconWrapper, StyledInputBase, getFormattedDate, getFormattedTime, isEmptyNullUndefined } from "../../utils/utils";
import { useEffect, useState } from "react";
import FilterSelect from "../../common/filterSelect/index.js";
import SideFilters from "../../common/sideFilters/index.js";
import { Button, IconButton, Popover, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { dummyApproveData } from "./dummyData/index.js";
import Avatar from "react-avatar";
import SideDrawer from "../../common/sideDrawer/index.js";
import InfoContent from "../../common/InfoContent/InfoContent.js";
import APIList from "../../../api/index.js";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import TableSkel from "../../common/tableSkeleton/index.js";
import checkIcon from "../../../assets/images/popup-check.png";
import PaginationMedi from "../../common/pagination/index.js";
import EmpProfile from "../../common/employeeProfile";
import { BsFillCheckCircleFill } from "react-icons/bs";
import moment from "moment";
import { BiDotsVerticalRounded } from "react-icons/bi";

const ApproveRequest = () => {
    const currentDate = moment().startOf('day');


    const drawerObj = {
        profile: false,
        info: false,
        peer: false,
        data: null,
      }

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    

    const pmsCycleData = useSelector((state) => state?.pmscycleReducer?.pmscycle[0]);
    const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);

    const [activeTab, setActiveTab] = useState("Approve");
    const [selectedPeer, setSelectedPeer] = useState(null);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [filterPayload, setFilterPayload] = useState({}); 
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);
    const [empListLoader, setEmpListLoader] = useState(false);
    const [empList, setEmpList] = useState(null);
    const [sortEmpList, setSortEmpList] = useState(null);
    const [requestCounts, setRequestCounts] = useState({
        ApproveRequestCount: 0,
        suggestRequestCount: 0,
    });
    const [viewProfileDrawerData, setViewProfileDrawerData] = useState(drawerObj); 

    const [drawerData, setDrawerData] = useState(null);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const [selectedEmployee, setSelectedEmployee] = useState([])
    
    const [anchorEl, setAnchorEl] = useState(null);

    const [status, setStatus] = useState('ACTIVE');

    useEffect(() => {
        if(annualcycleData?.pmsCycle?.peerAppraisalEndDate) {
            isActive()
        }
    }, [annualcycleData])

    useEffect(() => {
        if(!isEmptyNullUndefined(pmsCycleData?.id)) {
            getCount()      
            getPageData(null, search, currentPage);  
        }
    }, [pmsCycleData, currentPage])

    useEffect(() => {
        setSortEmpList(empList);
        // getCount() 
    }, [empList])
   
    useEffect(() => {
        if(!isEmptyNullUndefined(pmsCycleData?.id)) {
            if(activeTab === "Approve") {
                setEmpListLoader(true);
                const getSearch = setTimeout(() => {
                    if(currentPage !== 0 && currentPage !== null) {
                        setCurrentPage(0);
                    } else {
                        if(search !== null) {
                            getPageData(filterPayload, search, 0);
                        } else {
                            setEmpListLoader(false);
                        }                 
                    }
                    
                }, 1000);    
            
                return () => clearTimeout(getSearch);
            }
        }
    
    }, [search]);

    const handleSelectEmployee = (e, employee) => {
        // setIsAllEmployeeSelected(() => false)
        const tempEmployee = structuredClone(employee)
        const tempSelectedEmployee = structuredClone(selectedEmployee)
        const employeeIndex = tempSelectedEmployee.findIndex(e => e.id === employee.id)

        // console.log('employeeIndex', employeeIndex)
        


        if(e.target.checked) {
            // console.log('checked', tempEmployee)
            if(employeeIndex === -1) {

            // } else {
                tempSelectedEmployee.push(employee)
            }
        } else {
            // console.log('not checked', tempEmployee)
            if(employeeIndex !== -1) {
                tempSelectedEmployee.splice(employeeIndex, 1)

            } 
            // else {
            // }
        }

        // console.log('tempSelectedEmployee', tempSelectedEmployee)
        setSelectedEmployee(() => tempSelectedEmployee)
    }

    const approveAllSelectedEmployee = (isApproved) => {
        const tempSelectedEmployee = structuredClone(selectedEmployee)
        let payload = []
        tempSelectedEmployee.map(e => (
            // e.isApproved = true,
            e.isApproved = isApproved,
            e.requestItemId = e.requestItem.id,
            e.managerId = e.requestItem.requestedBy.manager.id

        ) )
        tempSelectedEmployee.forEach(e => {
            payload.push({
                // employeeId: e.employeeId,
                employeeId: employeeDetails.id, // current employee id (current login user)
                isApproved: e.isApproved, // if reject then set false (for approval set true and for decline set false)
                requestItemId: e.requestItemId, // feedback request id (id come from list of user )
                managerId: e.managerId // managerId (id come from e.requestItem.requestedBy.manager.id )
            })
        })

        // tempSelectedEmployee.map(e => e.requestItemId = e.requestItem.id)
        // console.log('tempSelectedEmployee', tempSelectedEmployee)

        const approveRequest = async() => {
            setEmpListLoader(true);
            try {
                let res = await APIList.workFlow_definition_verified_all_FEEDBACK(
                    payload
                )
                if(res.status === 200) {
                    // setIsAllEmployeeSelected(false)
                    setSelectedEmployee([])
                    getPageData()
                    toast.success(
                        <div className="flex flex-row">
                          <BsFillCheckCircleFill
                            style={{ width: "2rem", height: "2rem" }}
                          />
                          &nbsp;&nbsp; {((isApproved === 'true') || isApproved === true) ? 'approve successfully' : 'decline successfully'} 
                        </div>
                      );
                }
            } catch (error) {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                    </div>
                );
                setEmpListLoader(false);
            }
            
        }
        approveRequest()
    }

    const getCount = async () => {
        let temprequestCounts =  JSON.parse(JSON.stringify(requestCounts)) 


        const getApproveCount = async () => {
            try {
                let res = await APIList.workFlowDefinitionEmployeeCount(
                    {
                        employeeDetailsId: employeeDetails?.id,
                        pmsCycleId: pmsCycleData?.id
                    }
                )
                temprequestCounts.ApproveRequestCount = res?.data
            } catch {

            }
          }
    
        const getSuggestCount = async () => {
            try {
                let res = await  APIList.assessmentResponseFeedbackRequestInitiateManagerCount(employeeDetails?.id)
                temprequestCounts.suggestRequestCount = res?.data
            } catch {

            }
            // await APIList.assessmentResponseFeedbackRequestInitiateManagerCount(employeeDetails?.id)
            //     .then((res) => {
            //         temprequestCounts.suggestRequestCount = res?.data
            //     })
            //     .catch((err) => {
            //         // console.log(err);
            //     })
            }

            await getApproveCount()
            await getSuggestCount()
            setRequestCounts(() => temprequestCounts);
    }

    const handleClickAction = (event, emp) => {
        setSelectedPeer(emp);
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

//     http://localhost:9095/api/work/flow/definition/employee      POST

// {
//     "searchValue" : null,
//     "entityId":"d764a67a-5f55-4aba-b0c0-b5af0ac4baff",
//     "entityName":"pms_cycle",
//     "employeeId":"1ed6609f-cf6f-4698-aa16-21c113d6be3d",
//     "pageNo":0,
//     "pageSize":5
// }

    const getPageData = async (filters, key, pagenumber) => {
        // let totalPages = 0
        setEmpListLoader(true);
        // await APIList.workFlowDefinitionEmployeeByPmsCycle({
        //     employeeId: employeeDetails.id,
        //     pmsCycle:  pmsCycleData?.id
        // })   
        await APIList.workFlowDefinitionEmployeeBy({
            searchValue : isEmptyNullUndefined(key) ? null : key , // null,
            entityId: pmsCycleData.id,  //"d764a67a-5f55-4aba-b0c0-b5af0ac4baff",
            entityName:"pms_cycle",
            employeeId: employeeDetails.id, //"1ed6609f-cf6f-4698-aa16-21c113d6be3d",
            pageNo : currentPage, //0,
            pageSize : 10
        })
        .then((res) => {
            setEmpList(res?.data[0].entityList);
            let numberNeedToAdd = (Math.ceil(res?.data[1].totalSize / 10))
            // setTotalPages(res?.data[1].totalSize)
            setTotalPages(numberNeedToAdd)
            // console.log('=-res?.data-=', res?.data)
            setEmpListLoader(false);

            // setIsAllEmployeeSelected(false)
            // setSelectedEmployee([])
        })
        .catch((err) => {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                &nbsp;&nbsp;{err?.title}
                </div>
            );
            setEmpListLoader(false);
        })
    }

    const handleResetFilters = () => {
    
      };

    const handlePeerList = (value) => {
        handleClose()
        setSearch("");
        setSelectedPeer(null);
        setActiveTab(value);
    }

    const autoSearchText = (e) => {
        // setIsAllEmployeeSelected(false)
            setSelectedEmployee([])
        handleClose()
        setCurrentPage(0)
        setSearch(e.target.value);
    }

    const handleSubmitFilter = () => {
        handleClose()
        setCurrentPage(0);
        getPageData();
    }

    const handleApproveOrRejectRequest = (emp, isApproved, index) => {
        setEmpListLoader(true);
        
        // console.log("____emp======",emp )
        // console.log("____emp index",index )
        handleClose()
        setEmpListLoader(true);

        if(isApproved === 'Recall') {
            APIList.postWorkFlowDefinitionrecall(
                {
                    employeeId : employeeDetails.id, // current employee id (current login user)
                    requestItemId : emp.requestItem.id, // feedback request id (id come from list of user )
    
                }
            )        
            .then((res) => {
                // setIsAllEmployeeSelected(false)
                setSelectedEmployee([])
    
                getPageData()
                getCount()
                toast.success(
                    <div className="flex flex-row">
                      <BsFillCheckCircleFill
                        style={{ width: "2rem", height: "2rem" }}
                      />
                      {`Recall successfully`}
                    </div>
                  );
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
            })
        } else {
            APIList.postWorkFlowDefinitionVerifiedPeerFeedback(
                {
                    employeeId : employeeDetails.id, // current employee id (current login user)
                    isApproved : isApproved, // if reject then set false (approval ke liye true and decline ke liye false)
                    requestItemId : emp.requestItem.id, // feedback request id (id come from list of user )
    
                }
            )        
            .then((res) => {
                // setIsAllEmployeeSelected(false)
                setSelectedEmployee([])
    
                getPageData()
                getCount()
                toast.success(
                    <div className="flex flex-row">
                      <BsFillCheckCircleFill
                        style={{ width: "2rem", height: "2rem" }}
                      />
                      {(isApproved === 'true' || isApproved === true) ? ` Approve successfully` : ` Decline successfully`}
                    </div>
                  );
            })
            .catch((err) => {
                toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setEmpListLoader(false);
            })

        }
    }

    const handleOpenviewProfileDrawer = (data, toShow) => {
        setDrawerData(() => data);
              
        if (toShow === "profile") {
            let temp = {
              ...drawerObj, 
              profile: true, 
              data: data && data,
              peer: false,
              info: false
            };
            setViewProfileDrawerData(temp);
        }
        if (toShow === "peer") {
            let temp = {
              ...drawerObj, 
              peer: true, 
              data: data && data,
              profile: false, 
              info: false
            };
            setViewProfileDrawerData(temp);
        }
        if (toShow === "info") {
            let temp = {
              ...drawerObj, 
              info: true, 
              data: data && data,
              profile: false, 
              peers: false, 
            };
            setViewProfileDrawerData(temp);
        }
    
        setIsOpenDrawer(true);
    };

    // const handleSelectAllEmployee = (event) => {
    //     // const tempSelectedEmployee = structuredClone(selectedEmployee)
    //     const tempSelectedEmployee = []
    //     // allEmployeeSelected
    //     if(event.target.checked) {
    //         // dummyApproveData[0].entityList.forEach((e, index) => {
    //             sortEmpList.forEach((e, index) => {
    //             if(!e.isApproved && !e.isReject) {
    //                 tempSelectedEmployee.push(e)
    //             }
    //         })
    //         setIsAllEmployeeSelected(true)
    //     } else {
    //         tempSelectedEmployee.length = 0;
    //         setIsAllEmployeeSelected(false)
    //     }
    //     console.log('tempSelectedEmployee', tempSelectedEmployee)
    //     setSelectedEmployee(() => tempSelectedEmployee)
    // }

    const handleSelectAllEmployee = (event) => {
        const tempSelectedEmployee = structuredClone(selectedEmployee)
        if(event.target.checked) {
            // dummyApproveData[0].entityList.forEach((e, index) => {
                sortEmpList.forEach((e, index) => {
                if(!e.isApproved && !e.isReject) {
                    let isEmployeePresent = tempSelectedEmployee.some(selectedE => selectedE.id === e.id)

                    if(!isEmployeePresent) {
                        tempSelectedEmployee.push(e)
                    }
                }
            })
            // setIsAllEmployeeSelected(true)
        } else {
            tempSelectedEmployee.length = 0;
            // setIsAllEmployeeSelected(false)
        }
        // console.log('tempSelectedEmployee', tempSelectedEmployee)
        setSelectedEmployee(() => tempSelectedEmployee)
    }

    const checkIsAllEmployeeSelected = () => {
        
        let tempsortEmpList = structuredClone(sortEmpList)
        let tempSelectedEmployee = structuredClone(selectedEmployee);
        let isAllEmployeeSelected = true
        
        if(tempsortEmpList?.length > 0) {
            tempsortEmpList.forEach((sortE) => {
                let isMatch = tempSelectedEmployee.some((selectedE) => {
                    if(!sortE.isApproved && !sortE.isReject) {
                        return selectedE.id === sortE.id;
                    } else return true
                    
                })
                if(isMatch === false) {
                    isAllEmployeeSelected = false
                }
                // console.log('isMatch', isMatch)
            })
            
        }

        return isAllEmployeeSelected
    }

    const isSellectAllButtonShow = () => {
        let tempsortEmpList = structuredClone(sortEmpList)
        let isButtonShow = true
        if (empListLoader) {
            isButtonShow = false
        } else {
            if(tempsortEmpList?.length > 0) {
                isButtonShow = tempsortEmpList.some((e) => !e.isApproved && !e.isReject);
            } else {
                isButtonShow = false
            }
        }
        return isButtonShow
    }

    const isActive = () => {
        if (currentDate.isBefore(annualcycleData.pmsCycle.peerAppraisalEndDate)) {
            // console.log("Current date is before self appraisal end date");
            setStatus('ACTIVE')
            return false
          } else if (currentDate.isAfter(annualcycleData.pmsCycle.peerAppraisalEndDate)) {
            // console.log("Current date is after self appraisal end date");
        
            setStatus('INACTIVE')
            return true

            
          } else {
            // console.log("Current date is equal to self appraisal end date");
            setStatus('ACTIVE')
            return false
          }
      }

    return(
        <div className="approve-request-main">
          <div className="search-wrapper">
            
            <div className="r-f-tabs">

                <button className={`tab ${activeTab === "Approve" && "active"}`} onClick={() => handlePeerList("Approve")}>Approve 
                    {/* <span>{(requestCounts?.ApproveRequestCount > 0 && requestCounts?.ApproveRequestCount < 10 )? `0${requestCounts?.ApproveRequestCount}` : requestCounts?.ApproveRequestCount}</span> */}
                    <span>{requestCounts?.ApproveRequestCount}</span>
                </button>

                {/* <div className={`tab ${activeTab === "Suggest" && "active"}`} onClick={() => handlePeerList("Suggest")}>Suggest
                    <span>{(requestCounts?.suggestRequestCount > 0 && requestCounts?.suggestRequestCount < 10) ? `0${requestCounts?.suggestRequestCount}` : requestCounts?.suggestRequestCount}</span>
                    <span>{requestCounts?.suggestRequestCount}</span>
                </div> */}
           
            </div>
            


            <div className="second-div">
                {(selectedEmployee.length > 0) && (<>
                
                    {employeeDetails?.permissions?.includes("request-decline-action") && (status === 'ACTIVE') && (
                        <Button 
                            variant="contained"
                            sx={{
                                lineHeight: '24px',
                                width: '163px',
                                height: '50px',
                                borderRadius: '10px',
                                background: 'var(--primary)',
                                fontSize: 'var(--subHeaderFontSize)',
                                margin: '0px',
                                padding: '0px',
                                border: 'none',
                                color: '#fff',
                                backgroundColor: 'var(--primary)',
                                textTransform: 'inherit',
                                marginRight: '1rem',
                            }}
                            disabled={empListLoader}
                            onClick={() => approveAllSelectedEmployee(false)}
                        >
                            Decline                                     
                        </Button>

                        )
                    
                    }
                    {
                         employeeDetails?.permissions?.includes("request-approve-action") && (status === 'ACTIVE') && (

                            <Button 
                                variant="contained"
                                sx={{
                                    lineHeight: '24px',
                                    width: '163px',
                                    height: '50px',
                                    borderRadius: '10px',
                                    background: 'var(--primary)',
                                    fontSize: 'var(--subHeaderFontSize)',
                                    margin: '0px',
                                    padding: '0px',
                                    border: 'none',
                                    color: '#fff',
                                    backgroundColor: 'var(--primary)',
                                    textTransform: 'inherit',
                                    marginRight: '1rem',
                                }}
                                disabled={empListLoader}
                                onClick={() => approveAllSelectedEmployee(true)}
                            >
                                Approve                                     
                            </Button>
                         )
                    }
                </>)}
                {/* <div> 
                    {(selectedEmployee.length > 0) && (
                        <button style={{
                            backgroundColor:"var(--primary)",
                                        borderColor:"var(--primary)",
                                        "&:hover":{
                                            backgroundColor:"#035c7c",
                                            borderColor:"#035c7c",
                                        },
                                        // width:"48.5%"
                        }} 
                        className="approve-button" onClick={() => approveAllSelectedEmployee(false)}>
                            Decline
                        </button>

                    )}
                </div>
                <div> 
                    {(selectedEmployee.length > 0) && (
                        <button style={{
                            backgroundColor:"var(--primary)",
                                        borderColor:"var(--primary)",
                                        "&:hover":{
                                            backgroundColor:"#035c7c",
                                            borderColor:"#035c7c",
                                        },
                                        // width:"48.5%"
                        }}  className="approve-button" onClick={() => approveAllSelectedEmployee(true)}>
                            Approve
                        </button>

                    )}
                </div> */}
          <Search>
            <SearchIconWrapper>
              <IoMdSearch />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by username or email"
              inputProps={{ 'aria-label': 'search' }}
              onChange={autoSearchText}
              value={search}
            />
          </Search>
          {/* <div className="filter-tab" onClick={() => setIsOpenFilter(true)}> 
            <img src={filterIcon} alt="" />
            Filter
            {
                Object.keys(filterPayload).length > 0 && 
                <div className="red-dot"></div>
            }
          </div> */}
          <button className="page-i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>
            i
          </button>
          </div>
          </div>

          <div className="row">
            <div className="col-12">
            <div className="suggest-approve-one">

                {
                    activeTab === "Approve" && 
                    <div className="tablee">
                    <table className="feedback-emp-list-table"> 
                        
                        <tr className="table-head-emp-list"> 
                            <th>
                                <div style={{
                                    display: 'flex',
                                }}>

                                
                                    
                                    <div className="form-check d-flex align-items-center" >
                                        {
                                            isSellectAllButtonShow() && (
                                                <input style={{
                                                    alignSelf: 'stretch',
                                                    }} 
                                                    // onChange={(e) => console.log('onchange e', e)} 
                                                    onClick={(e) => handleSelectAllEmployee(e)} 
                                                    className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={checkIsAllEmployeeSelected()} 
                                                />

                                            )
                                        }
                                    </div>
                                   
                                    <div>
                                        Request from 

                                    </div>
                                
                                </div>
                                {/* &nbsp;<BiSort onClick={() => handleSort("employeeName")} style={{cursor:"pointer"}} /> */}
                            </th> 
                            <th className="mid-cols">
                             Request to
                                {/* &nbsp;<BiSort onClick={() => handleSort("appraisalStatus")} style={{cursor:"pointer"}} /> */}
                                </th> 
                            <th className="mid-cols">Request on</th> 
                            <th className="last-col head">Status</th> 
                            {/* <th></th>  */}
                        </tr>
        
                        {
                            empListLoader ? 
                            // false ? 
                            
                            <TableSkel />
                            :
                            sortEmpList?.map((emp,index) => {
                                // dummyApproveData[0].entityList.map((emp,index) => {
                                    // console.log('dummyApproveData', dummyApproveData)
                                // console.log('emp map--', emp)
                                return(   
                                    // activeTab === "Pending" ?
                                        <tr key={emp?.requestItem?.requestedBy?.name}> 
                                            <td>
                                                <div>


                                                </div>
                                                <div className="empDetaila">
                                                    {
                                                        (!emp.isApproved && !emp.isReject) ? (
                                                                <div className="form-check d-flex align-items-center" >
                                                                    <input onChange={(e) => console.log('onchange e', e)} onClick={(e) => handleSelectEmployee(e, emp)} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked ={selectedEmployee.some(e => e.id === emp.id)} />
                                                                </div>
                                                        ) : (
                                                            <div style={{
                                                                visibility: 'hidden'
                                                            }} className="form-check d-flex align-items-center" >
                                                                <input onClick={(e) => handleSelectEmployee(e, emp)} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked ={selectedEmployee.some(e => e.id === emp.id)} />
                                                            </div>
                                                        )
                                                    }
                                                    <div className="empPic">
                                                        {emp?.requestItem?.requestedBy?.profilePhoto ? 
                                                            <img src={emp?.requestItem?.requestedBy?.profilePhoto?.path} className="userImg" alt="User" />
                                                            :
                                                            <Avatar 
                                                                name={emp?.requestItem?.requestedBy?.name} 
                                                                size="45" 
                                                                className="userImg"
                                                                color={"#00425A"}    
                                                            />
                                                        }
                                                    </div>
                                                    <div className="userInfo">
                                                        {/* <p className="name">{emp?.requestedBy?.name}</p> */}
                                                        <p className="name">{emp?.requestItem?.requestedBy?.name}</p>
                                                        {/* <p className="designation">{emp?.requestedBy?.employeeDesignation ? emp?.requestedBy?.employeeDesignation : "Designation"}</p> */}
                                                        {/* <p className="designation">{emp?.requestItem?.requestedBy?.employeeDesignation ? emp?.requestItem?.requestedBy?.employeeDesignation : "Designation"}</p> */}
                                                        <p className="designation">{emp?.requestItem?.requestedBy?.employeeDesignation ? emp?.requestItem?.requestedBy?.employeeDesignation : ""}</p>
                                                    </div>
                                                </div>
                                            </td> 
                                            <td>
                                                <div className="empDetaila">

                                                    <div className="empPic">
                                                        {emp?.requestItem?.requestedTo?.profilePhoto ? 
                                                            <img src={emp?.requestItem?.requestedTo?.profilePhoto?.path} className="userImg" alt="User" />
                                                            :
                                                            <Avatar 
                                                                name={emp?.requestItem?.requestedTo?.name} 
                                                                size="45" 
                                                                className="userImg"
                                                                color={"#00425A"}    
                                                            />
                                                        }
                                                    </div>
                                                    <div className="userInfo">
                                                         <p style={{
                                                                cursor: "pointer",
                                                            }} 
                                                            className="name"
                                                            onClick={() => handleOpenviewProfileDrawer(emp?.requestItem?.requestedTo, "profile")}
                                                        >{emp?.requestItem?.requestedTo?.name}
                                                        </p>
                                                        <p className="designation">{emp?.requestItem?.requestedTo?.employeeDesignation ? emp?.requestItem?.requestedTo?.employeeDesignation : ""}</p>

                                                
                                                            {/* <div>
                                                                <p style={{
                                                                    cursor: "pointer",
                                                                    marginBottom: 0
                                                                    }} 
                                                                    onClick={() => handleOpenviewProfileDrawer(emp?.requestItem?.requestedTo, "profile")}  className="name">{emp?.requestItem?.requestedTo?.name}
                                                                </p>
                                                            </div>
                                                            <p style={{
                                                                fontSize: 'var(--smallTextFontSize)',
                                                                color: 'var(--tertiary)',
                                                            }}>{emp?.requestItem?.requestedTo?.employeeDesignation ? emp?.requestItem?.requestedTo?.employeeDesignation : ""}</p> */}
                                                    </div>
                                                </div>
                                            </td> 
                                            <td>
                                                <div className= "btns">
                                                    <div>{getFormattedDate(emp?.requestItem?.requestDate, dateFormat)}</div>
                                                    {/* <div>{getFormattedDate(emp?.requestDate, "hh:mm")}</div> */}
                                                    <div>{getFormattedTime(emp?.requestItem?.requestDate, "hh:mm A")}</div>
                                                </div>
                                            </td> 
                                            
                                            <td className="last-col">          
                                                {
                                                    ((emp.isReject !== true) && (emp.isApproved !== true)) && (status === 'ACTIVE') &&
                                                
                                                        <div className= "btnss" 
                                                        onClick={(event) => handleClickAction(event, emp)}
                                                        >
                                                            {
                                                                // (status === 'ACTIVE') && (
                                                                    <button className="send-btn">                                        
                                                                        Action <IoIosArrowDown />
                                                                    </button>                                    

                                                                // )
                                                            }
                                                        </div>   

                                                        
                                                        
                                                }   
                                                                   
                                             
                                            
                                                
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                        }}
                                                    sx={{
                                                        "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                                                            boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                            border:"1px solid  #e9e9e9",
                                                            // padding:"0.25rem",
                                                            fontFamily:"poppins",
                                                            fontSize:"0.75rem",
                                                            borderRadius: "10px",
                                                            padding: "5px 0px",
                                                            cursor:"pointer",
                                                            marginLeft:"-1rem"                                                        
                                                        },
                                                        "& .MuiPopover-paper" : {
                                                            boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                                                            border:"1px solid  #e9e9e9",
                                                            // padding:"0.25rem",
                                                            fontFamily:"poppins",
                                                            fontSize:"0.75rem",
                                                            borderRadius: "10px",
                                                            padding: "5px 0px",
                                                            cursor:"pointer",
                                                            marginLeft:"-1rem"                                                        
                                                        }
                                                    }}
                                                    >
                                                    {
                                                        !((selectedPeer?.isReject === true) || (selectedPeer?.isApproved === true) ) && employeeDetails?.permissions?.includes("request-approve-action") && (status === 'ACTIVE') && 
                                                        <div                                                 
                                                        onClick={() => handleApproveOrRejectRequest(structuredClone(selectedPeer), true, index)}
                                                        className="popover-btns" >Approve</div>
                                                    }
                                                    
                                                    {/* {
                                                        // ((annualcycleData?.pmsCycle?.peerReviewInitiator === "Manager") || (annualcycleData?.pmsCycle?.peerReviewInitiator === "Both")) &&
                                                        (<div className="popover-btns" 
                                                        onClick={() => handleSuggestRequest()}
                                                        >
                                                            Suggest
                                                        </div>)
                                                        } */}
                                                    {
                                                        !((selectedPeer?.isReject === true) || (selectedPeer?.isApproved === true) ) && employeeDetails?.permissions?.includes("request-decline-action") && (status === 'ACTIVE') &&
                                                        <div className="popover-btns red" 
                                                        onClick={() => handleApproveOrRejectRequest(structuredClone(selectedPeer), false, index)}
                                                        // onClick={() => handleDecline()}
                                                        >Decline</div>
                                                    }
                                                    {
                                                        (selectedPeer?.isReject === true ) && employeeDetails?.permissions?.includes("request-decline-action") && (status === 'ACTIVE') &&
                                                        <div className="popover-btns" 
                                                        onClick={() => handleApproveOrRejectRequest(structuredClone(selectedPeer), 'Recall', index)}
                                                        >Recall</div>
                                                    }
                                                
                                                </Popover>
                                                {
                                                    (emp.isApproved === true) && (
                                                        // <p style={{color: '#FF0000'}} className="name">Declined</p>
                                                        // <div className="popover-btns red">Decline</div>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }} className="status-contain">

                                                        {/* <span> */}
                                                            <img style={{
                                                                width: '1.25rem',
                                                                marginRight: '0.5rem',
                                                            }} src={checkIcon} className="checkIcon"></img>
                                                            <div className="feedback-table-status">
                                                            Approved
                                                            </div>

                                                        {/* </span> */}
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (emp.isReject === true) && (
                                                        // <p style={{color: '#FF0000'}} className="name">Declined</p>
                                                        <div style={{
                                                            color: 'red !important',
                                                            fontSize: 'var(--smallTextFontSize)',
                                                            marginRight: '1rem',
                                                        }} className="popover-btns red">Decline</div>
                                                    )
                                                }
                                            </td> 
                                            <td>
                                                <div className="action">
                                                      {
                                                        emp.isReject === true && (
                                                            <IconButton variant="outlined" 
                                                                onClick={(event) => handleClickAction(event, emp)}
                                                            >
                                                                <BiDotsVerticalRounded />
                                                            </IconButton>
                                                            
                                                        )
                                                      }  
                                                </div>
                                            </td>
                                  
                                        </tr>
                                  
                                            
                                    
                                )
                            }
                           
                        // }
                        )
                        }
        
                    </table>
                    {
                    isEmptyNullUndefined(sortEmpList) && <div className="no-result">No Result Found</div>
                    
                } 

                {
                    !isEmptyNullUndefined(sortEmpList) && (totalPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                }
                    </div> 
                }
            
            </div>
            </div>
            </div>

            {
            isOpenDrawerForIbtn && 
            <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                {/* <div className="i-btn-cintent">
                    Please provide content
                </div> */}
                <InfoContent pageName='aprove'/>
            </SideDrawer>
            } 

            {
                isOpenFilter &&
                <SideFilters isOpen={isOpenFilter} setIsOpen={setIsOpenFilter} >
                    <div className="filter-wrapper">

                        <div className="filters-heading">Filters</div>

                        <Stack>
                            {
                                !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                    return (
                                        <FilterSelect title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                    )
                                })
                            }
                        </Stack>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "60%",
                            float: "right"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    // marginRight:"1rem",
                                    width: "48.5%"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "48.5%"
                                }}
                                // disabled={searchLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </div>
                </SideFilters>
            }  

            {isOpenDrawer && (
        // <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
        //   <div className="info">
        //     {drawerData && (
        //       <div dangerouslySetInnerHTML={{ __html: drawerData }} />
        //     )}
        //   </div>
        // </SideDrawer>
        <SideDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
          
            {
              viewProfileDrawerData?.profile &&
              // <EmpProfile empInfo={viewProfileDrawerData?.data} />
              <EmpProfile empInfo={drawerData} />
            }
            {viewProfileDrawerData?.info && (
              <div className="info">

              <div dangerouslySetInnerHTML={{ __html: drawerData }} />
              </div>
            )}
            </SideDrawer>
      )}    

        </div>
    )
}

export default ApproveRequest;