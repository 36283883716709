import { IoMdSearch } from "react-icons/io";
import { Search, SearchIconWrapper, StyledInputBase, isEmptyNullUndefined } from "../../utils/utils";
import { IoCloseSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import APIList from "../../../api";
import { useSelector } from "react-redux";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import { Backdrop, Checkbox, CircularProgress, Skeleton } from "@mui/material";
import PaginationMedi from "../../common/pagination";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SideDrawer from "../../common/sideDrawer";
import MyTeamCompensationLetter from "../MyTeamCompensation";
import ViewPDF from "../profile/componets/compHistory/component/viewPDF";

const parse = require("html-react-parser");

const CompMyTeam = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [activeTab, setActiveTab] = useState("My Team");
    const [activeTab1, setActiveTab1] = useState("All");
    const [search, setSearch] = useState("");
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);

    const [filterPayload, setFilterPayload] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [dataLoader, setDataLoader] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [firstTimeLoad, setFirstTimeLoad] = useState(true);
    const [isReleasedManual, setIsReleasedManual] = useState(false);

    const [totalCompData, setTotalCompData] = useState([]);
    const [selectedEmps, setSelectedEmps] = useState([]);

    const [sendLoader, setSendLoader] = useState(false);

    const [isViewLetter, setisViewLetter] = useState(false);
    const [letterData, setletterData] = useState(null);
    const [viewLetterFor, setViewLetterFor] = useState([]);
    const [viewLetterLoader, setViewLetterLoader] = useState(false);

    useEffect(() => {

        if (!isEmptyNullUndefined(employeeDetails?.id)) {
            getData(0, {});
        }

    }, [employeeDetails])

    useEffect(() => {

        const getdd = setTimeout(() => {
            if (!firstTimeLoad) {
                getData(currentPage, filterPayload);
            }
        }, 1000);

        return () => clearTimeout(getdd);

    }, [currentPage])

    useEffect(() => {
        const getSearch = setTimeout(() => {
            if (!firstTimeLoad) {
                if (currentPage !== 0) {
                    setCurrentPage(0);
                } else {
                    getData(0, filterPayload);
                }
            }
        }, 1000);

        return () => clearTimeout(getSearch);

    }, [search])

    useEffect(() => {
        if (!firstTimeLoad) {
            if (currentPage !== 0) {
                setCurrentPage(0);
            } else {
                getData(0, filterPayload);
            }
        }
    }, [itemsPerPage])

    useEffect(() => {

        if (!isEmptyNullUndefined(employeeDetails?.id)) {

            if (!firstTimeLoad) {
                if (currentPage !== 0) {
                    setCurrentPage(0);
                } else {
                    getData(0, filterPayload);
                }
            }

        }

    }, [activeTab])


    const getData = (cp, filters) => {
        if(!employeeDetails?.permissions?.includes("for-demo-purpose")){
        setDataLoader(true);
        APIList.compMyTeam({
            payload: {
                employeeEligibilityDTO: filters,
                filterData: Object.keys(filters).length === 0 ? false : true, // Default is false. Set to true if filtering is required.
                keyword: search,
                managerId: employeeDetails?.id,
                companyId: employeeDetails?.company?.id,
                page: activeTab,
            },
            page: cp,
            size: itemsPerPage
        })
            .then((res) => {
                setTotalCompData(res?.data?.data);
                setIsReleasedManual(res?.data?.isReleasMannual);
                setTotalPages(res?.data?.totalPages);
                setFirstTimeLoad(false);
                setDataLoader(false);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setDataLoader(false);
            })
        }
    }

    const autoSearchText = (e) => {
        // setCurrentPage(0);
        // setShowAllSearches(false);
        setSearch(e.target.value);
    };

    const sendSingleLetter = (emp) => {

        handleSendLetter([emp?.employeeId]);

    }

    const handleSendLetter = (single) => {
        setSendLoader(true);
        APIList.sendLetter({
            payload: !isEmptyNullUndefined(single) ? single : selectedEmps?.map(x => x?.employeeId),
            companyId: employeeDetails?.company?.id,
            employeeId: employeeDetails?.id
        })
            .then((res) => {
                toast.success(
                    <div className="flex flex-row">
                        <BsFillCheckCircleFill
                            style={{ width: "2rem", height: "2rem" }}
                        />
                        &nbsp;&nbsp;Sent successfully
                    </div>
                );
                setSendLoader(false);
                setActiveTab1("All");
                setSelectedEmps([]);
                getData(currentPage, filterPayload);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setSendLoader(false);
            })
    };
    const [viewFileId, setviewFileId] = useState(null);

    const handleViewLetter = (emp) => {
        setviewFileId(emp?.compensationLetterFileId)
        setisViewLetter(true);  
        
        setViewLetterFor(emp);

        setViewLetterLoader(true);
        APIList.compHistoryLetterViewForManager({
            companyId: employeeDetails?.company?.id,
            employeeId: emp?.employeeId,
            isManagerView: true
        })
        .then((res) => {
            // setTotalCompData(res?.data?.data);
            setletterData(res?.data[0]);
            setViewLetterLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            )
            setViewLetterLoader(false);
        })
    };

    const handleSelectEmployee = (emp) => {

        let selected = structuredClone(selectedEmps?.map(x => x?.employeeId));
        let temp = structuredClone(selectedEmps);

        if (selected?.includes(emp?.employeeId)) {
            temp = temp.filter((x) => x?.employeeId !== emp?.employeeId);
        } else {
            temp.push(emp);
        }

        setSelectedEmps(temp);
    }

    const handleSelectAllEmployee = () => {

        if (activeTab1 === "Selected") {
            setSelectedEmps([]);
            setActiveTab1("All");
        } else {
            let temp = structuredClone(selectedEmps);
            let selected = structuredClone(selectedEmps?.map(x => x?.employeeId));

            totalCompData?.forEach((emp) => {
                if (isAllSected()) {
                    temp = temp.filter((x) => x?.employeeId !== emp?.employeeId);
                } else {
                    if (!emp?.isCompensationLetterRelesed) {
                        if (!selected?.includes(emp?.employeeId)) {
                            temp.push(emp);
                        }
                    }
                }

            })

            setSelectedEmps(temp);
        }


    }

    const isAllSected = () => {

        let isall = true;
        let temp = structuredClone(selectedEmps?.map(x => x?.employeeId));


        totalCompData?.forEach((emp) => {
            if (!emp?.isCompensationLetterRelesed) {
                if (!temp?.includes(emp?.employeeId)) {
                    isall = false;
                }
            }
        });

        // console.log("asasasa=====>>>>", totalCompData?.length, selectedEmps?.length);

        // let isall = false;



        // if(Number(totalCompData?.length) === (Number(released?.length) + Number(selectedEmps?.length))){
        //    return true;
        // } else {
        //     return false;
        // }

        return isall;

    }

    const downloadSinleLetter = (emp) => {
        setSendLoader(true);
        APIList.downloadLetterPDF({
            companyId: employeeDetails?.company?.id,
            employeeId: emp?.employeeId,
            isManagerView: true
        })
            .then((res) => {
                toast.success(
                    <div className="flex flex-row">
                        <BsFillCheckCircleFill
                            style={{ width: "2rem", height: "2rem" }}
                        />
                        &nbsp;&nbsp;Sent successfully
                    </div>
                );
                setSendLoader(false);
            }).catch((err) => {
                toast.error(
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                        &nbsp;&nbsp;{err?.title}
                    </div>
                )
                setSendLoader(false);
            })
    }

    return (
        <div className="comp-my-team">

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {
                employeeDetails?.permissions?.includes("for-demo-purpose") ?
                // true ?
                    <MyTeamCompensationLetter viewType="team" /> :
                    <>
                        <div className="upper-header">
                            <div
                                // style={{
                                //     pointerEvents: true ? 'none' : 'auto',
                                //     opacity: true ? '60%' : '100%'
                                // }}
                                className="search-wrapper">
                                <div className="r-f-tabs">

                                    <div className={`tab ${activeTab === "My Team" ? "active" : ""}`} onClick={() => setActiveTab("My Team")} >My Team</div>

                                    <div className={`tab ${activeTab === "My Org" ? "active" : ""}`} onClick={() => setActiveTab("My Org")}>
                                        My Org
                                        {/* <span>{sentCount < 10 ? `0${sentCount}` : sentCount}</span> */}
                                    </div>

                                    {
                                        employeeDetails?.permissions?.includes("compensation-my-team-entire-org") &&
                                        <div className={`tab ${activeTab === "Entire Org" ? "active" : ""}`} onClick={() => { !dataLoader && setActiveTab("Entire Org") }}>Entire Org</div>
                                    }

                                </div>


                                <div className="second-div">
                                    <Search
                                    >
                                        <SearchIconWrapper>
                                            <IoMdSearch />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search by username or email"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={autoSearchText}
                                            value={search}
                                        />
                                        {
                                            !isEmptyNullUndefined(search) &&
                                            <IoCloseSharp onClick={() => { setSearch("") }} style={{ position: "absolute", right: "10px", top: "34%", color: "gray", cursor: "pointer" }} />
                                        }

                                    </Search>

                                    {
                                        isReleasedManual &&
                                        <button onClick={() => handleSendLetter()} className={`send-btn ${selectedEmps?.length < 1 ? "disable" : ""}`} disabled={selectedEmps?.length < 1}>Send</button>
                                    }

                                    {/* <button className="download-btn">Download</button> */}

                                    <div className="page-i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>
                                        i
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tabs-outer">

                            {
                                selectedEmps?.length > 0 &&
                                <div className="tabs">
                                    <div className={`tab ${activeTab1 === "All" ? "active" : ""}`} onClick={() => setActiveTab1("All")}>All</div>
                                    <div className={`tab ${activeTab1 === "Selected" ? "active" : ""}`} onClick={() => setActiveTab1("Selected")}>Selected <span>{selectedEmps?.length}</span></div>
                                </div>
                            }

                        </div>

                        <div className="tablee">
                            <table class="feedback-emp-list-table">
                                <tr className="table-head-emp-list">
                                    <th className="team-member-name" onClick={handleSelectAllEmployee}>
                                        {
                                            isReleasedManual &&
                                            <Checkbox checked={isAllSected()} />
                                        }
                                        Team member name
                                    </th>
                                    {
                                        isReleasedManual &&
                                        <th className="mid-cols">
                                            Send
                                        </th>
                                    }

                                    <th className="mid-cols">View Letter</th>
                                    {/* <th className="mid-cols">Download</th>  */}
                                </tr>

                                {
                                    (dataLoader
                                        //  && !saveLoader
                                    )
                                        ?

                                        <tbody>
                                            {
                                                [...Array(10).keys()].map((y) => {
                                                    return (
                                                        <tr>

                                                            <td style={{ padding: "0.5rem" }}>
                                                                <Skeleton width={100} />
                                                            </td>
                                                            <td style={{ padding: "0.5rem" }}>
                                                                <Skeleton width={100} />
                                                            </td>
                                                            <td style={{ padding: "0.5rem" }}>
                                                                <Skeleton width={100} />
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        :

                                        isEmptyNullUndefined(totalCompData) ?
                                            <div className="no-data">No data found</div>

                                            :

                                            <tbody>

                                                {

                                                    activeTab1 === "Selected" ?

                                                        selectedEmps?.map((emp) => {

                                                            return (
                                                                <tr>

                                                                    <td>
                                                                        <div onClick={() => !emp?.isCompensationLetterRelesed && handleSelectEmployee(emp)} style={{ display: 'flex', cursor: "pointer" }}>

                                                                            <div class="d-flex align-items-center" >
                                                                                {
                                                                                    isReleasedManual &&
                                                                                    <Checkbox checked={emp?.isCompensationLetterRelesed ? emp?.isCompensationLetterRelesed : selectedEmps?.map(x => x?.employeeId)?.includes(emp?.employeeId)} disabled={emp?.isCompensationLetterRelesed} />
                                                                                }
                                                                            </div>
                                                                            <div className="empDetaila">
                                                                                <div className="empPic">
                                                                                    {
                                                                                        emp?.profilePhotoPath ?
                                                                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                                                            :
                                                                                            <Avatar
                                                                                                name={emp?.employeeName}
                                                                                                size="45"
                                                                                                className="userImg"
                                                                                                color={"#00425A"}
                                                                                            />
                                                                                    }
                                                                                </div>
                                                                                <div className="userInfo">
                                                                                    <p className="name">{emp?.employeeName}</p>
                                                                                    {/* <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p> */}
                                                                                    <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {

                                                                        isReleasedManual &&

                                                                        <td className="mid-cols">
                                                                            <button className={`send btn ${selectedEmps?.length > 1 || emp?.isCompensationLetterRelesed ? "disable" : ""}`} onClick={() => sendSingleLetter(emp)} disabled={selectedEmps?.length > 1 || emp?.isCompensationLetterRelesed} >Send Letter</button>
                                                                        </td>

                                                                    }
                                                                    <td className="mid-cols">
                                                                        <button className="download btn" onClick={() => handleViewLetter(emp)}>View Letter</button>
                                                                    </td>
                                                                    {/* <td className="mid-cols">
                                        <button className="download btn" onClick={() => downloadSinleLetter(emp)}>Download Letter</button>
                                    </td> */}

                                                                </tr>
                                                            )
                                                        })

                                                        :

                                                        totalCompData?.map((emp) => {

                                                            // if(activeTab1 === "Selected" && !selectedEmps?.includes(emp?.employeeId)){
                                                            //    return null;
                                                            // }

                                                            return (
                                                                <tr>

                                                                    <td>
                                                                        <div onClick={() => !emp?.isCompensationLetterRelesed && handleSelectEmployee(emp)} style={{ display: 'flex', cursor: "pointer" }}>

                                                                            <div class="d-flex align-items-center" >
                                                                                {
                                                                                    isReleasedManual &&
                                                                                    <Checkbox checked={emp?.isCompensationLetterRelesed ? emp?.isCompensationLetterRelesed : selectedEmps?.map(x => x?.employeeId)?.includes(emp?.employeeId)} disabled={emp?.isCompensationLetterRelesed} />
                                                                                }
                                                                            </div>
                                                                            <div className="empDetaila">
                                                                                <div className="empPic">
                                                                                    {
                                                                                        emp?.profilePhotoPath ?
                                                                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                                                                            :
                                                                                            <Avatar
                                                                                                name={emp?.employeeName}
                                                                                                size="45"
                                                                                                className="userImg"
                                                                                                color={"#00425A"}
                                                                                            />
                                                                                    }
                                                                                </div>
                                                                                <div className="userInfo">
                                                                                    <p className="name">{emp?.employeeName}</p>
                                                                                    {/* <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p> */}
                                                                                    <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : ""}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {

                                                                        isReleasedManual &&

                                                                        <td className="mid-cols">
                                                                            <button className={`send btn ${selectedEmps?.length > 1 || emp?.isCompensationLetterRelesed ? "disable" : ""}`} onClick={() => sendSingleLetter(emp)} disabled={selectedEmps?.length > 1 || emp?.isCompensationLetterRelesed} >Send Letter</button>
                                                                        </td>

                                                                    }
                                                                    <td className="mid-cols">
                                                                        <button className="download btn" onClick={() => handleViewLetter(emp)}>View Letter</button>
                                                                    </td>
                                                                    {/* <td className="mid-cols">
                                        <button className="download btn" onClick={() => downloadSinleLetter(emp)}>Download Letter</button>
                                    </td> */}

                                                                </tr>
                                                            )
                                                        })
                                                }

                                            </tbody>
                                }


                            </table>


                            {
                                activeTab1 !== "Selected" ?
                                    !isEmptyNullUndefined(totalCompData) && (totalPages > 1) &&
                                    <PaginationMedi currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
                                    :
                                    <></>
                            }

                            {
                                isViewLetter &&
                                <SideDrawer isOpen={isViewLetter} setIsOpen={setisViewLetter} >
                                    {
                                    // viewFileId ?
                                    // <div className="comp-history-pdf-view">
                                    //     <ViewPDF fileId={viewFileId} />
                                    // </div>
                                    // :
                                    <div className="compenssation-history">   
                                    <div className="letter-block">
                                        {

                                            viewLetterLoader ? 
                                            <div style={{height:"500px", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <CircularProgress />
                                            </div>
                                            :
                                            !isEmptyNullUndefined(letterData) && 
                                            parse(letterData?.body)

                                        }
                                    </div>
                                    </div>
                                    }

                                </SideDrawer>
                            }

                            {
                                isOpenDrawerForIbtn &&
                                <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
                                    <div>Please provide the content</div>
                                </SideDrawer>
                            }

                        </div>
                    </>
            }



        </div>
    )
}

export default CompMyTeam;