import React, { useState } from "react";
import { Grid, Button, Typography, Popover } from "@mui/material";
import DecliningLeaveForModal from './Modal/DecliningLeaveForModal';
import { FaSort } from "react-icons/fa6";
import { BsChevronDown } from "react-icons/bs";
import MenuIcon from "../../../../assets/images/Leaves/menuIcon.svg";
import LeftArrow from "../../../../assets/images/leftArrow.png";
import RightArrow from "../../../../assets/images/rightArrow.png";


const TeamLeaveHistory = () => {
  const [showDecliningLeaveForModal, setShowDecliningLeaveForModal] = useState(false);

  const handleApplyingForLeaveModal = () => {
    setShowDecliningLeaveForModal(true);
  };

  const handleDecliningLeaveForModalNo = () => {
    setShowDecliningLeaveForModal(false);
  };

  const [popOver, setPopOver] = useState(null);

  const handlePopOverClick = (event) => {
    setPopOver(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setPopOver(null);
  };

  const open = Boolean(popOver);
  const id = open ? 'simple-popover' : undefined;

  const teamLeaveHistory = [
    {
      id: 1,
      requestBy: "Ashish Reji",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "2",
      leaveType: "Family Care Leave",
      status: "Pending",
    },
    {
      id: 2,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Sick Leave",
      status: "Auto Approved",
    },
    {
      id: 3,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Family Care Leave",
      status: "Approved",
    },
    {
      id: 4,
      requestBy: "Ashish Reji",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "2",
      leaveType: "Sick Leave",
      status: "Pending",
    },
    {
      id: 5,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Annual Leave",
      status: "Declined",
    },
    {
      id: 6,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Maternity Leave",
      status: "Approved",
    },
    {
      id: 7,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Bereavement Leave",
      status: "Approved",
    },
    {
      id: 8,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Annual Leave",
      status: "Auto Approved",
    },
    {
      id: 9,
      requestBy: "Akshay C",
      requestDate: "20 Nov. 2024",
      leaveDuration: "18 Dec. 2024 - 20 Dec. 2024",
      noOfDays: "3",
      leaveType: "Annual Leave",
      status: "Auto Approved",
    },
  ];

  return (
    <div className="team-leave-history">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className="title-grid">
          <Typography className="page-title">{"Team Leaves"}</Typography>

          <Grid className="right-top-grid">
            <Button className="filter-btn">
              {"Filter"}
              <BsChevronDown style={{ marginLeft: "0.5rem" }} />
            </Button>
          </Grid>
        </Grid>

        {/*----- Table My Team Leave -----*/}
        <Grid item xs={12} sm={12} md={12} className="team-leave-history-table-card">
          <div className="team-leave-history-table">
            <table className="team-leave-history-list-table">
              <tr className="table-head-team-leave-history-list">
                <th className="pl-20">Request By &nbsp;<FaSort className="c-pointer" /></th>
                <th className="">Request Date &nbsp;<FaSort className="c-pointer" /></th>
                <th className="">Leave Duration &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-center">No. of Days &nbsp;<FaSort className="c-pointer" /></th>
                <th className="">Leave Type &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-center">Status &nbsp;<FaSort className="c-pointer" /></th>
                <th className="ta-end pr-20">Action</th>
              </tr>

              {teamLeaveHistory.map((item, index) => (
                <tr>
                  <td className="pl-20">
                    <div>
                      <Typography className="leave-table-request-value">{item.requestBy}</Typography>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Typography className="leave-table-value">{item.requestDate}</Typography>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Typography className="leave-table-value">{item.leaveDuration}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className="ta-center">
                      <Typography className="leave-table-value">{item.noOfDays} </Typography>
                    </div>
                  </td>
                  <td>
                    <div>
                      <Typography className="leave-table-value">{item.leaveType}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className="ta-center">
                      <Typography
                        className="status-table-value"
                        style={{
                          color: item.status === 'Approved' || item.status === 'Auto Approved'
                            ? '#00AA5A'
                            : item.status === 'Pending'
                              ? '#FF9900'
                              : item.status === 'Declined' ? '#DB3535' : 'inherit'
                        }}
                      >{item.status}</Typography>
                    </div>
                  </td>
                  <td className="ta-end pr-10">
                    <Button className="action-btn" onClick={handlePopOverClick}>
                      <img src={MenuIcon} alt="MenuIcon" className="menu-icon" />
                    </Button>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={popOver}
                      onClose={handlePopOverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      sx={{
                        "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper" : {
                            boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                            border:"1px solid  #e9e9e9",
                            // padding:"0.25rem",
                            fontFamily:"poppins",
                            fontSize:"0.75rem",
                            borderRadius: "10px",
                            padding: "5px 0px",
                            cursor:"pointer",
                            marginLeft:"-1rem"                                                        
                        },
                        "& .MuiPopover-paper" : {
                            boxShadow:"4px 4px 7px 1px rgba(188, 188, 188, 0.05)",
                            border:"1px solid  #e9e9e9",
                            // padding:"0.25rem",
                            fontFamily:"poppins",
                            fontSize:"0.75rem",
                            borderRadius: "10px",
                            padding: "5px 0px",
                            cursor:"pointer",
                            marginLeft:"-1rem"                                                        
                        }
                    }}
                    >
                      <Typography sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, fontFamily: "poppins", cursor: "pointer", p: 2, pb: 0 }}>{"Approve"}</Typography>
                      <Typography onClick={handleApplyingForLeaveModal} sx={{ color: "#00425A", fontSize: "16px", fontWeight: 400, fontFamily: "poppins", cursor: "pointer", p: 2 }}>{"Decline"}</Typography>
                    </Popover>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Grid>

        {/*----- Pagination -----*/}
        <div className="table-pagination">
          <div className="d-flex">
            <img src={LeftArrow} alt="LeftArrow" className="left-right-arrow" />
            <div className="pagination-border"><Typography className="pagination-count">{"1"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"2"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"3"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"4"}</Typography></div>
            <div className="pagination-border"><Typography className="pagination-count">{"5"}</Typography></div>
            <img src={RightArrow} alt="RightArrow" className="left-right-arrow ml-10" />
          </div>
        </div>
      </Grid>

      {/*----- Apply Modal -----*/}
      {showDecliningLeaveForModal && (
        <DecliningLeaveForModal
          showDecliningLeaveForModal={showDecliningLeaveForModal}
          handleDecliningLeaveForModalNo={handleDecliningLeaveForModalNo}
        />
      )}
    </div>
  );
};

export default TeamLeaveHistory;
