import { useEffect, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import filterIcon from "../../../../../../assets/images/filter.svg";
import { Button, Checkbox, FormHelperText, InputBase, Stack, alpha, styled } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { isEmptyNullUndefined } from "../../../../../utils/utils";
import FilterSelect from "../../../../../common/filterSelect";
import { useSelector } from "react-redux";
import APIList from "../../../../../../api";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import FilterSelect2 from "../../../../../common/filterSelect2";
import TableSkel from "../../../../../common/tableSkeleton";
import Avatar from "react-avatar";
import PaginationMedi from "../../../../../common/pagination";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // margin:"1rem 0px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: '0px !important',
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
  }));
  
 const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1 ,
  }));

 const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    border:"1px solid var(--primary)",
    // boxShadow:"0px 0px 6px 1px #e1e1e1",
    height: "3.4rem !important",
    width: '100%',
    backgroundColor: "#ffffff",
    borderRadius:"10px",
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create('width'),
    //   width: '12rem',
      // [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
      // },
    },
  })); 

const Cohort = ({handleChangeSessionInfo, sessionPayload, sessionPayloadErr}) => {
    
    const dropdownData = useSelector((state) => state?.dropdownData?.dropdownData);
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [cohortSearch, setCohortSearch] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [filterPayload, setFilterPayload] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    
    const [searchedList, setsearchedList] = useState(null);
    const [totalSearchPages, setTotalSearchPages] = useState(0);
    const [searchLoader, setSearchLoader] = useState(false);
    const [isFilterOn, setIsFilterOn] = useState(false);
    
    const [removingItem, setremovingItem] = useState(null);
    
    const getData = (filters, search, page, action) => {
        setSearchLoader(true);
            APIList.getFilteredSearchPeers({
                payload: {
                    employeeEligibilityDTO: filters,
                    keyword: search,
                    employeeId: employeeDetails?.id,
                    managerId: employeeDetails?.manager?.id,
                    companyId: employeeDetails?.company?.id
                },
                page: page,
                size: 10
            })
                .then((res) => {
                    setsearchedList(res?.data?.data);
                    setTotalSearchPages(res?.data?.totalPages);
                    setSearchLoader(false);
                    (action === "filter") && setShowFilters(false); 
                })
                .catch((err) => {
                    toast.error(
                        <div style={{display:"flex", flexDirection:"row"}}>
                          <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                          &nbsp;&nbsp;{err?.title} 
                        </div>
                    );
                    setSearchLoader(false);
                }
                )
    }

    // useEffect(() => {
    //     setSearchLoader(true);
    //     const getSearch = setTimeout(() => {
    //         if(currentPage !== 0) {
    //             setCurrentPage(0);
    //         } else {
    //             getData(filterPayload, cohortSearch, 0, "search");
    //         }
            
    //         // getData(filterPayload, cohortSearch, 0, "search");
    //     }, 500);    

    //     return () => clearTimeout(getSearch)
    //   }, [cohortSearch])

    // useEffect(() => {
    //         getData(filterPayload, cohortSearch, currentPage, "search")
    //   }, [currentPage])

    // const autoSearchTextCohort = (e) => {
    //     setCohortSearch(e.target.value);
    //   }

    //   const handleSubmitFilter = () => {
        
    //     if(Object.keys(filterPayload).length === 0){
    //         toast.error(
    //             <div style={{display:"flex", flexDirection:"row"}}>
    //               <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
    //               &nbsp;&nbsp;Please select some filters 
    //             </div>
    //         );
    //     } else {
    //         setIsFilterOn(true);
    //         if(currentPage !== 0) {
    //             setCurrentPage(0);
    //         } else {
    //             getData(filterPayload, cohortSearch, 0, "search");
    //         }
    //             // getData(filterPayload, cohortSearch, 0, "filter");             
    //     }      
    // }  

    // const handleResetFilters = () => {
    //     setFilterPayload({});
    //     setIsFilterOn(false);
    //     if(currentPage !== 0) {
    //         setCurrentPage(0);
    //     } else {
    //         getData({}, cohortSearch, 0, "search");
    //     }
    //     // getData({}, cohortSearch, 0, "filter")
    // }

    const removeCohort = (emp) => {
        setremovingItem(emp?.id); 
        setTimeout(() => {
            setremovingItem(null);
            handleChangeSessionInfo(emp, "removeCohort");
        }, 250);  
    }

    const isAllAdded = () => {
        const allIds = searchedList?.map( x => x.employeeId);
        const AllSelectedIds = sessionPayload?.coHortEmployees?.map( x => x.id);

        const allEntriesPresent = allIds.every(item => AllSelectedIds.includes(item));

        return allEntriesPresent;
    }

    const handleSelectAll = () => {
        if(isAllAdded()) {
            handleChangeSessionInfo(searchedList, "removeAllCohort");
        } else {
            handleChangeSessionInfo(searchedList, "addAllCohort")
        }
        
    }
    
    return (
        <div className="emp-cohort">
            {/* <div className="search-wrapper">

                <Search>
                    <SearchIconWrapper>
                        <IoMdSearch />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Co host"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={autoSearchTextCohort}
                        value={cohortSearch}
                    />
                    {
                        !isEmptyNullUndefined(cohortSearch) &&
                        <IoCloseSharp onClick={() => setCohortSearch("")} style={{ position: "absolute", right: "1rem", top: "34%", color: "gray", cursor: "pointer" }} />
                    }
                </Search>
                <div className={`filter-tab ${showFilters ? "active" : ""}`} onClick={() => setShowFilters(!showFilters)}>
                    <img src={filterIcon} alt="" />
                    Filter
                    <BsChevronDown
                        style={{ transform: !showFilters ? "rotate(0deg)" : "rotate(180deg)" }}
                    />
                </div>
                <div className="page-i-btn"
                // onClick={() => setIsOpenDrawerForIbtn(true)}
                >
                    i
                </div>
            </div>

            {sessionPayloadErr?.coHortEmployees?.isError && (
            <FormHelperText sx={{ color: "#d32f2f" }}>
            {sessionPayloadErr?.coHortEmployees?.errMsg}
            </FormHelperText>
            )} */}

            {/* {
                showFilters && 
                <div className="filters-block">
                    <div className="filters">
                        {
                            !isEmptyNullUndefined(dropdownData) && Object.keys(dropdownData).map((key) => {
                                return (
                                    <FilterSelect2 title={key} filterPayload={filterPayload} setFilterPayload={setFilterPayload} />
                                )
                            })
                        }
                        </div>
                        <Stack sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>

                            <Button
                                variant="outlined"
                                disabled={Object.keys(filterPayload).length === 0}
                                sx={{
                                    color: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    marginRight: "1rem",
                                    width: "9rem"
                                }}
                                onClick={() => { handleResetFilters() }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    // color:"var(--secondary)",
                                    backgroundColor: "var(--primary)",
                                    borderColor: "var(--primary)",
                                    "&:hover": {
                                        backgroundColor: "#035c7c",
                                        borderColor: "#035c7c",
                                    },
                                    width: "9rem"
                                }}
                                disabled={searchLoader}
                                onClick={() => handleSubmitFilter()}
                            >
                                Submit
                            </Button>
                        </Stack>
                        
                </div>
            } */}

{
                !isEmptyNullUndefined(sessionPayload?.coHortEmployees) && 
                <div className="tablee">
                    <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list-send"> 
                <th>Employee Name</th> 
                <th className="last-col">Email</th> 
                </tr> 

                {
                sessionPayload?.coHortEmployees?.map((emp,index) => {
                        return(                               
                                <tr className={`${emp?.id === removingItem ? "selected" : ""}`}> 
                                <td>
                                    <div className="empDetaila">
                                    <MdOutlineCheckBox />
                                        <div className="empPic">
                                        {emp?.profilePhotoPath ? 
                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                            :
                                            <Avatar 
                                                name={emp?.name} 
                                                size="45" 
                                                className="userImg"
                                                color={"#00425A"}    
                                            />
                                        }
                                        </div>
                                        <div className="userInfo">
                                            <p className="name">{emp?.name}</p>
                                            <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                        </div>
                                    </div>
                                </td> 

                                <td>
                                <div className= "btns">
                                {emp?.emailId}
                                </div>
                                </td> 
                               
                            </tr>        
                                    
                            
                        )
                    }
                )
                }

                </table>
                </div>
            }

            {/* {
                ((!isEmptyNullUndefined(cohortSearch) || ((Object.keys(filterPayload)?.length > 0)) && isFilterOn) && 
                !isEmptyNullUndefined(searchedList)) ?

                <div className="tablee">
                    <table class="feedback-emp-list-table"> 
                <tr className="table-head-emp-list-send"> 
                <th className="emp-name" onClick={handleSelectAll}> 
                {
                    isAllAdded() ?
                    <MdOutlineCheckBox />
                    :
                    <MdOutlineCheckBoxOutlineBlank />
                }
                    Employee Name
                </th> 
                <th>Email</th> 
                </tr> 

                {
                     searchLoader ? 
                     <TableSkel />
                     :
                searchedList?.map((emp,index) => {
                        return(                               
                                <tr> 
                                <td>
                                    <div className="empDetaila" onClick={() => handleChangeSessionInfo(emp, "addCohort")}>
                                        {
                                            sessionPayload?.coHortEmployees?.filter((x) => x?.id === emp?.employeeId).length > 0 ?
                                            <MdOutlineCheckBox />
                                            :
                                            <MdOutlineCheckBoxOutlineBlank />
                                        }
                                        <div className="empPic">
                                        {emp?.profilePhotoPath ? 
                                            <img src={emp?.profilePhotoPath} className="userImg" alt="User Image" />
                                            :
                                            <Avatar 
                                                name={emp?.employeeName} 
                                                size="45" 
                                                className="userImg"
                                                color={"#00425A"}    
                                            />
                                        }
                                        </div>
                                        <div className="userInfo">
                                            <p className="name">{emp?.employeeName}</p>
                                            <p className="designation">{emp?.employeeDesignation ? emp?.employeeDesignation : "Designation"}</p>
                                        </div>
                                    </div>
                                </td> 

                                <td>
                                <div className= "btns">
                                {emp?.emailId}
                                </div>
                                </td> 
                               
                            </tr>        
                                    
                            
                        )
                    }
                )
                }

                </table>
                {
                    !isEmptyNullUndefined(searchedList) && (totalSearchPages > 1) &&
                    <PaginationMedi currentPage={currentPage} totalPages={totalSearchPages} onPageChange={setCurrentPage} />
                }
                </div>
                :
                
                    (!isEmptyNullUndefined(cohortSearch) || ((Object.keys(filterPayload)?.length > 0)) && isFilterOn) && 
                    <div className="no-result">
                        No result found
                    </div>
                
                
            } */}

        </div>
    )
}

export default Cohort;